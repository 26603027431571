import React, { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Snackbar,
  Grid,
  CircularProgress,
} from "@mui/material";

const Shop = ({ userInventoryRef, setUserInventory, userId }) => {
  const [animalsForSale, setAnimalsForSale] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [actionStatus, setActionStatus] = useState({
    isLoading: false,
    message: "",
  });

  const fetchAnimalsForSale = async () => {
    setActionStatus({
      isLoading: true,
      message: "Fetching animals for sale...",
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/animalsSale?userId=${userId}`
      );
      const data = await response.json();
      setAnimalsForSale(data);
      setActionStatus({ isLoading: false, message: "" });
    } catch (error) {
      console.error("Error fetching animals for sale:", error);
      setActionStatus({ isLoading: false, message: "" });
      setSnackbar({ open: true, message: "Failed to fetch animals for sale." });
    }
  };

  const handleBuyAnimal = async (animalId, price, coinId) => {
    setActionStatus({ isLoading: true, message: "Purchasing animal..." });

    // Check if the user has enough coins
    const coin = userInventoryRef.current.itemDetails.find(
      (item) => parseInt(item.itemId) === parseInt(coinId)
    );

    if (!coin || coin.quantity < price) {
      setActionStatus({ isLoading: false, message: "" });
      setSnackbar({
        open: true,
        message: "Insufficient coins to buy the animal.",
      });
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/buyAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ animalId, price, coinId }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        // Deduct the coins from the userInventoryRef
        const updatedInventory = userInventoryRef.current.itemDetails.map(
          (item) => {
            if (parseInt(item.itemId) === parseInt(coinId)) {
              return { ...item, quantity: item.quantity - price };
            }
            return item;
          }
        );

        // Check if the animal already exists in the inventory
        const existingAnimal = updatedInventory.find(
          (item) => parseInt(item.itemId) === parseInt(animalId)
        );

        console.log("existingAnimal: ", existingAnimal);

        if (existingAnimal) {
          // Increase the quantity if the animal already exists
          existingAnimal.quantity += 1;
        } else {
          // Add the purchased animal as a new entry if it doesn't exist
          updatedInventory.push(data.animal);
        }

        userInventoryRef.current = {
          ...userInventoryRef.current,
          itemDetails: updatedInventory,
        };

        setUserInventory((prev) => ({
          ...prev,
          itemDetails: updatedInventory,
        }));

        setSnackbar({ open: true, message: "Animal purchased successfully." });
      } else {
        setSnackbar({ open: true, message: data.message });
      }
      setActionStatus({ isLoading: false, message: "" });
    } catch (error) {
      console.error("Error purchasing animal:", error);
      setActionStatus({ isLoading: false, message: "" });
      setSnackbar({ open: true, message: "Failed to purchase animal." });
    }
  };

  const getAnimalInventoryCount = (animalId) => {
    const animal = userInventoryRef.current.itemDetails.find(
      (item) => parseInt(item.itemId) === parseInt(animalId)
    );
    return animal ? animal.quantity : 0;
  };

  useEffect(() => {
    fetchAnimalsForSale();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      {actionStatus.isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : animalsForSale.length === 0 ? (
        <Typography align="center">No Animals for Sale</Typography>
      ) : (
        <Grid container spacing={2} alignItems="stretch">
          {animalsForSale.map((animal) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={animal.animalId}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  mb: 2,
                  opacity: animal.locked ? 0.5 : 1,
                  filter: animal.locked ? "grayscale(100%)" : "none",
                  position: "relative",
                }}
              >
                {animal.locked && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0,0,0,0.95)",
                      zIndex: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Locked
                    </Typography>
                    <Typography variant="body2">
                      Increase your Ranching Skill to Purchase
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      (Level {animal.requiredSkillLevel})
                    </Typography>
                  </Box>
                )}
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 151, sm: 151, md: "auto" },
                    width: { md: 151 },
                    border: "2px solid black",
                    margin: "auto",
                    filter: animal.locked
                      ? "grayscale(100%) blur(2px)"
                      : "none",
                  }}
                  image={animal.imageUrl}
                  alt={animal.name}
                />

                <CardContent
                  sx={{
                    flex: "1 0 auto",
                    filter: animal.locked ? "blur(2px)" : "none",
                  }}
                >
                  <Typography component="div" variant="h5" align="center">
                    {animal.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    align="center"
                  >
                    {animal.description}
                  </Typography>
                  {!animal.locked && (
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleBuyAnimal(
                            animal.animalId,
                            animal.price,
                            animal.coinId
                          )
                        }
                        sx={{ maxWidth: "fit-content", minWidth: "150px" }}
                      >
                        Buy for {animal.price}
                        <img
                          src={animal.coinImg}
                          alt="coin"
                          style={{ width: 20, height: 20, marginLeft: 5 }}
                        />
                      </Button>
                    </Box>
                  )}
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="div"
                    align="right"
                    sx={{ mt: 1 }}
                  >
                    Inventory: {getAnimalInventoryCount(animal.animalId)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default Shop;
