import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Divider } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const NotificationDialog = ({
  open,
  onClose,
  notifications,
  onDismiss,
}) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const handleClose = () => {
    if (doNotShowAgain && notifications.length > 0) {
      const notificationIds = notifications.map((notif) => notif._id);
      onDismiss(notificationIds);
    }
    onClose();
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: "0px",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          border: "2px solid rgba(173, 216, 230)",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
          fontFamily: '"Pixelify Sans", sans-serif',
          fontSize: "36px",
        }}
      >
        Hoarding Life Daily News
      </DialogTitle>
      <Divider />
      <DialogContent style={{ textAlign: "center" }}>
        {notifications.length > 0 ? (
          <Slider {...settings}>
            {notifications.map((notification) => (
              <div key={notification._id} style={{ textAlign: "center" }}>
                <h3>{notification.title}</h3>
                <p>
                  {notification.body.split("\n\n").map((paragraph, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {index > 0 && <br />}
                      {paragraph}
                    </React.Fragment>
                  ))}
                </p>
                {notification.img && (
                  <img
                    src={notification.img}
                    alt="Notification"
                    style={{ maxWidth: "100%", marginBottom: "20px" }}
                  />
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <p style={{ textAlign: "center" }}>No notifications to display</p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={doNotShowAgain}
                onChange={(e) => setDoNotShowAgain(e.target.checked)}
              />
            }
            label="Do not show again"
            style={{ marginRight: 0 }}
          />
          <Button onClick={handleClose}>Close</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
