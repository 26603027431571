import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Pagination from "@mui/material/Pagination";

// Skills Import
import SkillCard from "./SkillCard";
import GatherTab from "./GatherTab/GatherTab";
import ProcessTab from "./ProcessTab/ProcessTab";
import CraftTab from "./CraftTab/CraftTab";
import FishTab from "./FishTab/FishTab";
import ForageTab from "./ForageTab/ForageTab";
import FarmTab from "./FarmTab/FarmTab";
import AnimalTab from "./AnimalTab/AnimalTab";

const SkillsTab = ({
  skills,
  items,
  handleGatherHook,
  handleAutoGatherHook,
  expPercentages,
  userInventory,
  isAutoGather,
  setIsAutoGather,
  handleProcessHook,
  setUserInventory,
  handleCraftHook,
  fishingLocations,
  lockedFishingLocations,
  foragingLocations,
  lockedForagingLocations,
  userInventoryRef,
  userProfileRef,
  fishInventoryRef,
  forageInventoryRef,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [swipeableIndex, setSwipeableIndex] = useState(0);

  const pageSize = 3;

  const skillsChunks = [];
  for (let i = 0; i < skills.length; i += 3) {
    skillsChunks.push(skills.slice(i, i + 3));
  }

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const pageCount = Math.ceil(skills.length / pageSize);

  // Handlers
  const handleChangeIndex = (index) => {
    setSwipeableIndex(index);
    setCurrentPage(index + 1);
  };

  const mobileContainerStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "25px 16px",
    margin: "auto",
  };

  const gridContainerStyle = isMobile
    ? mobileContainerStyle
    : {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        justifyContent: "center",
        gap: "1rem",
        maxWidth: "1000px",
        margin: "auto",
      };

  const [selectedSkill, setSelectedSkill] = useState(null);

  const handleNavigateToSkill = (skillId) => {
    const skill = skills.find((s) => s.skillId === skillId);
    setSelectedSkill(skill);
  };

  const handleBackToSkills = () => {
    setSelectedSkill(null);
  };

  // Ensure filteredItems and lockedItems are available and have default values if not provided
  const allItems = items || [];
  const lockedItems =
    allItems.find((item) => Array.isArray(item.lockedItems))?.lockedItems || [];
  const filteredItems = allItems.filter((item) => !item.lockedItems);

  // Item Sorts
  const gatherItems = filteredItems.filter((item) => item.status === "gather");
  const processItems = filteredItems.filter(
    (item) => item.status === "process"
  );
  const craftItems = filteredItems.filter((item) => item.status === "craft");

  if (selectedSkill) {
    switch (selectedSkill.skillId) {
      case 1: // Gather Skill
        return (
          <GatherTab
            items={gatherItems}
            lockedItems={lockedItems} // Pass lockedItems to the skill
            onGather={handleGatherHook}
            onAutoGather={handleAutoGatherHook}
            expPercentages={expPercentages}
            purchasedItems={userInventory?.purchasedItems}
            isAutoGather={isAutoGather}
            setIsAutoGather={setIsAutoGather}
            onBackToSkills={handleBackToSkills}
            userProfileRef={userProfileRef}
            userInventoryRef={userInventoryRef}
          />
        );
      case 2: // Process Skill
        return (
          <ProcessTab
            items={processItems}
            lockedItems={lockedItems} // Pass lockedItems to the skill
            gatherItems={gatherItems}
            onProcess={handleProcessHook}
            onBackToSkills={handleBackToSkills}
            userInventory={userInventory}
            setUserInventory={setUserInventory}
          />
        );
      case 3: // Craft Skill
        return (
          <CraftTab
            items={craftItems}
            lockedItems={lockedItems} // Pass lockedItems to the skill
            craftItems={craftItems}
            onCraft={handleCraftHook}
            onBackToSkills={handleBackToSkills}
            userInventory={userInventory}
            setUserInventory={setUserInventory}
          />
        );
      case 4: // Fish Skill
        return (
          <FishTab
            fishingLocations={fishingLocations}
            lockedFishingLocations={lockedFishingLocations}
            onFish={{}}
            items={filteredItems}
            lockedItems={lockedItems} // Pass lockedItems to the skill
            userInventory={userInventory}
            setUserInventory={setUserInventory}
            onBackToSkills={handleBackToSkills}
            userInventoryRef={userInventoryRef}
            userProfileRef={userProfileRef}
            fishInventoryRef={fishInventoryRef}
            onGather={handleGatherHook}
          />
        );
      case 5: // Forage Skill
        return (
          <ForageTab
            foragingLocations={foragingLocations}
            lockedForagingLocations={lockedForagingLocations}
            onForage={{}}
            items={filteredItems}
            lockedItems={lockedItems} // Pass lockedItems to the skill
            userInventory={userInventory}
            setUserInventory={setUserInventory}
            onBackToSkills={handleBackToSkills}
            userInventoryRef={userInventoryRef}
            userProfileRef={userProfileRef}
            forageInventoryRef={forageInventoryRef}
            onGather={handleGatherHook}
            onAutoGather={handleAutoGatherHook}
            purchasedItems={userInventory?.purchasedItems}
          />
        );
      case 6: // Farm Skill
        return (
          <FarmTab
            onBackToSkills={handleBackToSkills}
            userInventoryRef={userInventoryRef}
            setUserInventory={setUserInventory}
            lockedItems={lockedItems} // Pass lockedItems to the skill
          />
        );
      case 7: // Ranch Skill
        return (
          <AnimalTab
            onBackToSkills={handleBackToSkills}
            userInventoryRef={userInventoryRef}
            setUserInventory={setUserInventory}
            lockedItems={lockedItems} // Pass lockedItems to the skill
          />
        );
      default:
        return null;
    }
  } else {
    return (
      <div style={gridContainerStyle}>
        {isMobile ? (
          <>
            <SwipeableViews
              index={swipeableIndex}
              onChangeIndex={handleChangeIndex}
              style={{ padding: "0 5px" }}
            >
              {skillsChunks.map((chunk, index) => (
                <div
                  key={index}
                  style={{
                    display: "grid",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                    margin: 0,
                    width: "100%",
                  }}
                >
                  {chunk.map((skill) => (
                    <div
                      key={skill.skillId}
                      style={{
                        flex: "1 0 calc(33.333% - 10px)",
                        margin: "0 5px",
                        boxSizing: "border-box",
                      }}
                    >
                      <SkillCard
                        skill={skill}
                        userInventoryRef={userInventoryRef}
                        onNavigateToSkill={() =>
                          handleNavigateToSkill(skill.skillId)
                        }
                      />
                    </div>
                  ))}
                </div>
              ))}
            </SwipeableViews>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={(event, page) => {
                  setCurrentPage(page);
                  handleChangeIndex(page - 1);
                }}
                color="primary"
              />
            </div>
          </>
        ) : (
          <div style={gridContainerStyle}>
            {skills.map((skill) => (
              <div key={skill.skillId}>
                <SkillCard
                  skill={skill}
                  onNavigateToSkill={() => handleNavigateToSkill(skill.skillId)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
};

export default SkillsTab;
