import React, { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  LinearProgress,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";

// Utilities
import { calculateTimeUntilNextWatering } from "./Utilities/calculateTimeUntilNextWatering";
import { formatCountdown } from "./Utilities/formatCountdown";

const initialPlotCost = 2500;

const FarmTab = ({ onBackToSkills, userInventoryRef, setUserInventory }) => {
  const [farmPlots, setFarmPlots] = useState([]);
  const [plotCost, setPlotCost] = useState(initialPlotCost);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [actionStatus, setActionStatus] = useState({
    isLoading: false,
    message: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [plantDialogOpen, setPlantDialogOpen] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [purchaseConfirmationOpen, setPurchaseConfirmationOpen] =
    useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("quantity-desc");

  const seeds =
    userInventoryRef.current && userInventoryRef.current.itemDetails
      ? userInventoryRef.current.itemDetails.filter(
          (item) => item.itemType === "seeds"
        )
      : [];

  const ls = new SecureLS({ isCompression: false });
  const userDetails = ls.get("userDetails");
  const userId = userDetails ? userDetails.userId : null;

  const fetchFarmData = async () => {
    setIsDataLoading(true);
    if (userId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/${userId}/initialize`
        );
        const data = await response.json();

        // Transform the received plots data to match the component's state structure
        setFarmPlots(
          data.plots.map((plot) => ({
            plotNum: plot.plotNumber,
            seedPlanted: plot.details.seedPlanted,
            datePlanted: plot.details.datePlanted,
            harvestTime: new Date(plot.details.dateToHarvest),
            wiltTime: new Date(plot.details.dateToWilt),
            isWatered: plot.details.isWatered,
            dateWatered: new Date(plot.details.dateWatered),
            waterInterval: plot.details.waterInterval * 60 * 60,
            productStatus: plot.details.productStatus,
            harvestProduct: plot.details.harvestProduct,
            typeOfWater: plot.details.typeOfWater,
            waterAmount: plot.details.waterAmount,
            waterImg: plot.details.waterImg,
          }))
        );
        updatePlotCost(data.plots.length + 1);
        setIsDataLoading(false);
      } catch (error) {
        console.error("Failed to fetch farm data:", error);
        setIsDataLoading(false);
      }
    } else {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchFarmData();
  }, [userId]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortType(event.target.value);
  };

  const getFilteredAndSortedSeeds = () => {
    return seeds
      .filter(
        (seed) =>
          seed.itemName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          seed.quantity.toString().includes(searchQuery)
      )
      .sort((a, b) => {
        switch (sortType) {
          case "alphabetical-asc":
            return a.itemName.localeCompare(b.itemName);
          case "alphabetical-desc":
            return b.itemName.localeCompare(a.itemName);
          case "quantity-asc":
            return a.quantity - b.quantity;
          case "quantity-desc":
            return b.quantity - a.quantity;
          default:
            return 0;
        }
      });
  };

  const updatePlotCost = (newPlotNumber) => {
    let newCost = initialPlotCost;
    for (let i = 2; i <= newPlotNumber; i++) {
      newCost += newCost * 0.5;
    }
    setPlotCost(Math.floor(newCost));
  };

  const handleBuyPlotConfirmation = () => {
    setPurchaseConfirmationOpen(true);
  };

  const handleClosePurchaseConfirmation = () => {
    setPurchaseConfirmationOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const updateInventory = (itemKey, change) => {
    const updatedInventory = userInventoryRef.current.itemDetails.map(
      (item) => {
        if (item.itemId === itemKey) {
          return { ...item, quantity: item.quantity + change };
        }
        return item;
      }
    );

    // Update ref
    userInventoryRef.current = {
      ...userInventoryRef.current,
      itemDetails: updatedInventory,
    };

    // Update state to trigger re-render
    setUserInventory((prev) => ({
      ...prev,
      itemDetails: updatedInventory,
    }));
  };

  const handleBuyPlot = async () => {
    setPurchaseConfirmationOpen(false);
    if (userId) {
      const coinItem = userInventoryRef.current.itemDetails.find(
        (item) => item.itemId === "64"
      );
      const coinBalance = coinItem ? coinItem.quantity : 0;

      if (coinBalance < plotCost) {
        setSnackbar({
          open: true,
          message: "Insufficient coins to purchase a new plot",
        });
        return;
      }

      setActionStatus({ isLoading: true, message: "Purchasing plot..." });

      try {
        const plotCostToSend = plotCost;

        // Make a POST request to buy a plot
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/${userId}/buyPlot`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ plotCost: plotCostToSend }), // Send the plot cost to the backend
          }
        );

        if (!response.ok) {
          throw new Error("Failed to buy a new plot");
        }

        const responseData = await response.json();
        console.log(responseData.message); // Display success message

        // Fetch updated farm data to reflect the new plot in the UI
        await fetchFarmData();

        // Deduct the plot cost from the local bronze coins quantity
        updateInventory("5", -plotCostToSend);

        // Update plot cost for the next plot purchase
        setPlotCost(Math.floor(plotCostToSend * 1.5));
        setSnackbar({ open: true, message: "New farm plot purchased!" });
        setActionStatus({ isLoading: false, message: "" });
      } catch (error) {
        console.error("Error during plot purchase:", error);
        alert("Error purchasing plot."); // Replace with a more user-friendly error handling
        setActionStatus({ isLoading: false, message: "" });
      }
    }
  };

  const handlePlantSeed = async (seed) => {
    if (!userId || !selectedPlot || !seed || seed.quantity === 0) return;

    setActionStatus({ isLoading: true, message: "Planting..." });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/${userId}/plant`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: selectedPlot,
            seedId: parseInt(seed.itemId),
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not plant the seed");
      }

      console.log("Seed planted successfully:", data);
      fetchFarmData();

      // Deduct 1 from the seed quantity in the local state
      updateInventory(seed.itemId, -1);
      console.log("seed.itemId: ", seed.itemId);

      setSnackbar({ open: true, message: "Seed successfully planted" });
      setActionStatus({ isLoading: false, message: "" });
    } catch (error) {
      console.error("Error planting seed:", error);
    }

    setPlantDialogOpen(false); // Close the planting dialog
  };

  // Functions to simulate watering and harvesting
  const handleWater = async (plotNum) => {
    setActionStatus({ isLoading: true, message: "Watering..." });

    // Find the plot to water
    const plotToWater = farmPlots.find((plot) => plot.plotNum === plotNum);
    if (!plotToWater) {
      console.error("Plot not found");
      return;
    }

    const plotWaterId = plotToWater.typeOfWater.toString();
    const plotWaterAmount = plotToWater.waterAmount.toString();

    // Check if the user has enough water
    const waterItem = userInventoryRef.current.itemDetails.find(
      (item) => item.itemId === plotWaterId
    );
    if (!waterItem || waterItem.quantity < plotWaterAmount) {
      setSnackbar({
        open: true,
        message: "Insufficient water to water the plot",
      });
      setActionStatus({ isLoading: false, message: "" });
      return;
    }

    try {
      const plotDetails = {
        plotNumber: plotNum,
        waterAmount: plotToWater.waterAmount,
        typeOfWater: plotToWater.typeOfWater,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/${userId}/waterPlot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(plotDetails),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to water the plot");
      }

      // Successfully watered the plot, now update the local state
      const updatedPlots = farmPlots.map((plot) =>
        plot.plotNum === plotNum
          ? { ...plot, isWatered: true, dateWatered: new Date() }
          : plot
      );
      setFarmPlots(updatedPlots);

      // Deduct the water amount from the user's inventory
      updateInventory(plotWaterId, -plotWaterAmount);

      // Show a success message
      setSnackbar({ open: true, message: "Plot successfully watered" });
      setActionStatus({ isLoading: false, message: "" });
    } catch (error) {
      console.error("Error watering the plot:", error.message);
      setSnackbar({
        open: true,
        message: "Error watering the plot. Please try again.",
      });
      setActionStatus({ isLoading: false, message: "" });
    }
  };

  const handleHarvest = async (plotNum) => {
    const plotToHarvest = farmPlots.find((plot) => plot.plotNum === plotNum);
    if (!plotToHarvest) {
      console.error("Plot not found");
      return;
    }

    setActionStatus({ isLoading: true, message: "Harvesting..." });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/${userId}/harvestPlot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNum,
            productStatus: plotToHarvest.productStatus,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to harvest the plot");
      }

      const responseData = await response.json();

      const {
        harvestedItemId,
        harvestQuantity,
        harvestName,
        harvestImg,
        expGain,
        bonusProducts,
      } = responseData;

      // Prepare dialog content
      let dialogContentItems = [];
      if (harvestQuantity === 20) {
        // When harvest has wilted due to harvestQuantity being exactly 20
        setDialogTitle("Harvest has wilted (Fiber)");
        setDialogContent(
          <>
            <Typography variant="h4" style={{ textTransform: "uppercase" }}>
              {harvestQuantity}
            </Typography>
            <Typography variant="body1">
              Unfortunately, your crop has wilted.
            </Typography>
          </>
        );
      } else if (harvestName && harvestImg) {
        // When harvest is successful and not wilted
        dialogContentItems.push(
          <img
            key="harvestImg"
            src={harvestImg}
            alt={harvestName}
            style={{ width: "100px", height: "100px" }}
          />
        );
        dialogContentItems.push(
          <Typography
            key="harvestQuantity"
            variant="h4"
            style={{ textTransform: "uppercase" }}
          >
            {harvestQuantity}
          </Typography>
        );
        dialogContentItems.push(
          <Typography key="harvestName" variant="body1">
            {harvestName}
          </Typography>
        );
        dialogContentItems.push(
          <Typography key="expGain" variant="h5" color="success">
            EXP Gained:{" "}
            {expGain.toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </Typography>
        );

        // If bonus products are available, add them to the dialog content
        if (bonusProducts && bonusProducts.length > 0) {
          dialogContentItems.push(
            <Typography
              key="bonusTitle"
              variant="h6"
              style={{ textAlign: "center" }}
            >
              Bonus Products:
            </Typography>
          );
          for (const bonusProduct of bonusProducts) {
            dialogContentItems.push(
              <div
                key={bonusProduct.bonusProductId}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Typography variant="body2" style={{ marginRight: 10 }}>
                  {bonusProduct.bonusAmount}x
                </Typography>
                <img
                  src={bonusProduct.bonusProductImg}
                  alt={bonusProduct.bonusProductName}
                  style={{ width: "50px", height: "50px", marginRight: 10 }}
                />
                <Typography variant="body2">
                  {bonusProduct.bonusProductName}
                </Typography>
              </div>
            );
          }
        }

        // Set dialog title and content
        setDialogTitle("Harvest Result");
        setDialogContent(<>{dialogContentItems}</>);
      } else {
        // Fallback or default content
        setDialogTitle("Harvest Result");
        setDialogContent(
          <>
            <Typography
              key="fallback"
              variant="h4"
              style={{ textTransform: "uppercase" }}
            >
              {harvestQuantity} ?
            </Typography>
          </>
        );
      }

      // Open the dialog
      setDialogOpen(true);

      const harvestedItemIdString = harvestedItemId.toString();
      const harvestQuantityString = harvestQuantity.toString();

      // Update the user's inventory ref with the harvested items
      updateInventory(harvestedItemIdString, +harvestQuantityString);

      // Update the user's inventory ref with the bonus products if available
      if (bonusProducts && bonusProducts.length > 0) {
        for (const bonusProduct of bonusProducts) {
          const bonusItemIdString = bonusProduct.bonusProductId.toString();
          updateInventory(bonusItemIdString, bonusProduct.bonusAmount);
        }
      }

      // Show success message and refresh farm data
      setSnackbar({ open: true, message: "Plot successfully harvested" });
      setActionStatus({ isLoading: false, message: "" });
      await fetchFarmData();
    } catch (error) {
      console.error("Error harvesting plot:", error);
      setSnackbar({
        open: true,
        message: "Error harvesting plot. Please try again.",
      });
      setActionStatus({ isLoading: false, message: "" });
    }
  };

  const handleSell = async (plotNum) => {
    const plotToSell = farmPlots.find((plot) => plot.plotNum === plotNum);
    if (!plotToSell) {
      console.error("Plot not found");
      return;
    }

    setActionStatus({ isLoading: true, message: "Selling..." });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/farm/${userId}/sellPlot`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNum,
            productStatus: plotToSell.productStatus,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to sell the crop");
      }

      const responseData = await response.json();

      const { earnedCoins, coinItemId, expGain } = responseData;

      updateInventory(coinItemId, earnedCoins);

      setSnackbar({
        open: true,
        message: `Crop sold successfully! Earned ${earnedCoins.toLocaleString()} coins and ${expGain.toLocaleString(
          "en-US",
          { minimumFractionDigits: 0, maximumFractionDigits: 2 }
        )} EXP.`,
      });
      setActionStatus({ isLoading: false, message: "" });

      await fetchFarmData();
    } catch (error) {
      console.error("Error selling crop:", error);
      setSnackbar({
        open: true,
        message: "Error selling crop. Please try again.",
      });
      setActionStatus({ isLoading: false, message: "" });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFarmPlots((currentPlots) =>
        currentPlots.map((plot) => {
          if (!plot.datePlanted) return { ...plot, productStatus: "none" };

          const currentTime = new Date().getTime();
          const harvestTime =
            new Date(plot.datePlanted).getTime() +
            plot.initialHarvestSeconds * 1000;
          const wiltTime = harvestTime + plot.wiltDurationSeconds * 1000;

          let newStatus = plot.productStatus;

          if (currentTime >= wiltTime) {
            newStatus = "wilt";
          } else if (currentTime >= harvestTime && newStatus !== "wilt") {
            newStatus = "fully-grown";
          } else if (newStatus !== "fully-grown" && newStatus !== "wilt") {
            newStatus = "growing";
          }

          return {
            ...plot,
            productStatus: newStatus,
          };
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      {isDataLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress style={{ color: "#d2b48c" }} />
        </Box>
      ) : (
        <>
          <div style={{ textAlign: "center" }}>
            <h2 style={{ color: "green" }}>Farm</h2>
          </div>
          <IconButton onClick={onBackToSkills}>
            <ArrowBack />
          </IconButton>
          {farmPlots.map((plot, index) => (
            <Card key={index} sx={{ marginBottom: 2 }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6">Farm Plot {plot.plotNum}</Typography>
                <Typography variant="body1">
                  Product:{" "}
                  {plot.harvestProduct
                    ? plot.harvestProduct.productName
                    : "Empty"}
                </Typography>
                {plot.seedPlanted && (
                  <>
                    <img
                      src={
                        plot.productStatus === "growing"
                          ? "/assets/items/farm/sprout.webp"
                          : plot.harvestProduct.productImg
                      }
                      alt={plot.harvestProduct.productName}
                      style={{
                        width: "100px",
                        height: "100px",
                        filter:
                          plot.productStatus === "wilt"
                            ? "sepia(100%) hue-rotate(20deg) brightness(100%)"
                            : !plot.isWatered
                            ? "grayscale(100%)"
                            : "none",
                      }}
                    />
                    <Box sx={{ width: "80%", mt: 1, mx: "auto" }}>
                      {plot.productStatus === "growing" && (
                        <>
                          <Typography variant="body2" align="center">
                            Harvest Progress
                          </Typography>
                          <Tooltip
                            title={
                              new Date() >= plot.harvestTime
                                ? "Harvest Product Now!"
                                : formatCountdown(plot.harvestTime)
                            }
                            placement="top"
                          >
                            <LinearProgress
                              variant="determinate"
                              value={Math.min(
                                100,
                                ((new Date() - new Date(plot.datePlanted)) /
                                  (plot.harvestTime -
                                    new Date(plot.datePlanted))) *
                                  100
                              )}
                              sx={{
                                height: 10,
                                borderRadius: 5,
                                mx: "auto",
                                width: "calc(100% - 100px)",
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: "green",
                                },
                              }}
                            />
                          </Tooltip>
                        </>
                      )}
                    </Box>
                    <Box sx={{ width: "80%", mt: 1, mx: "auto" }}>
                      <Typography variant="body2" align="center">
                        Next Watering
                      </Typography>
                      <Tooltip
                        title={
                          plot.isWatered
                            ? formatCountdown(
                                new Date(
                                  new Date(plot.dateWatered).getTime() +
                                    plot.waterInterval * 1000
                                )
                              )
                            : "Water Now"
                        }
                        placement="top"
                      >
                        <LinearProgress
                          variant="determinate"
                          value={Math.max(
                            0,
                            100 -
                              ((new Date() - new Date(plot.dateWatered)) /
                                (plot.waterInterval * 1000)) *
                                100
                          )}
                          sx={{
                            height: 10,
                            borderRadius: 5,
                            mx: "auto",
                            width: "calc(100% - 100px)",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#1E90FF",
                            },
                          }}
                        />
                      </Tooltip>
                    </Box>
                    {plot.productStatus === "fully-grown" && (
                      <Box sx={{ width: "80%", mt: 1, mx: "auto" }}>
                        <Typography variant="body2" align="center">
                          Wilt Progress
                        </Typography>
                        <Tooltip
                          title={
                            new Date() >= plot.wiltTime
                              ? "Plant already Wilted"
                              : formatCountdown(plot.wiltTime)
                          }
                          placement="top"
                        >
                          <LinearProgress
                            variant="determinate"
                            value={Math.max(
                              0,
                              100 -
                                ((new Date() - plot.harvestTime) /
                                  (plot.wiltTime - plot.harvestTime)) *
                                  100
                            )}
                            sx={{
                              height: 10,
                              borderRadius: 5,
                              mx: "auto",
                              width: "calc(100% - 100px)",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "brown",
                              },
                            }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                    {/* Water Button and Countdown Timer Logic */}
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        plot.isWatered &&
                        new Date() <
                          new Date(plot.dateWatered).getTime() +
                            plot.waterInterval * 60 * 60 * 1000 // Convert hours to milliseconds
                      }
                      onClick={() => {
                        console.log("Plot Details:", plot);
                        handleWater(plot.plotNum);
                      }}
                      sx={{ mt: 0.75 }}
                    >
                      <Tooltip
                        title={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              variant="body2"
                              style={{ marginRight: "5px" }}
                            >
                              {plot.waterAmount}
                            </Typography>
                            {plot.waterImg && (
                              <img
                                src={plot.waterImg}
                                alt="Water"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            )}
                          </div>
                        }
                        placement="top"
                      >
                        <span>Water</span>
                      </Tooltip>
                    </Button>
                  </>
                )}
                {!plot.seedPlanted && (
                  <Button
                    variant="outlined"
                    style={{ backgroundColor: "#d2b48c", color: "white" }}
                    onClick={() => {
                      setSelectedPlot(plot.plotNum);
                      setPlantDialogOpen(true);
                    }}
                  >
                    Plant
                  </Button>
                )}
                {plot.datePlanted &&
                  (plot.productStatus === "fully-grown" ||
                    plot.productStatus === "wilt" ||
                    (plot.productStatus === "growing" &&
                      plot.harvestTime <= new Date())) && (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={!plot.isWatered}
                        onClick={() => handleHarvest(plot.plotNum)}
                        sx={{ mt: 0.75 }}
                      >
                        Harvest
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleSell(plot.plotNum)}
                        sx={{ mt: 0.75 }}
                      >
                        Sell
                      </Button>
                    </>
                  )}
              </CardContent>
            </Card>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "#d2b48c", color: "white" }}
              onClick={handleBuyPlotConfirmation}
            >
              Buy New Plot
            </Button>
            <Typography sx={{ display: "flex", alignItems: "center" }}>
              Next plot cost: {plotCost.toLocaleString()}
              <img
                src="assets//items/currencies/farm-coin.webp"
                alt="coin"
                style={{ width: 20, height: 20, marginLeft: 5 }}
              />
            </Typography>
          </Box>

          {/* Purchase Confirmation Dialog */}
          <Dialog
            open={purchaseConfirmationOpen}
            onClose={handleClosePurchaseConfirmation}
          >
            <DialogTitle>Confirm Purchase</DialogTitle>
            <DialogContent>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                Are you sure you want to purchase a new plot for{" "}
                {plotCost.toLocaleString()}
                <img
                  src="assets//items/currencies/farm-coin.webp"
                  alt="coin"
                  style={{ width: 20, height: 20, marginLeft: 5 }}
                />
                ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePurchaseConfirmation}>Cancel</Button>
              <Button onClick={handleBuyPlot} color="primary">
                Buy
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={plantDialogOpen}
            onClose={() => setPlantDialogOpen(false)}
          >
            <DialogTitle>Select a Seed to Plant</DialogTitle>
            <DialogContent>
              <TextField
                label="Search Seeds"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel id="sort-label">Sort By</InputLabel>
                <Select
                  labelId="sort-label"
                  value={sortType}
                  label="Sort By"
                  onChange={handleSortChange}
                >
                  <MenuItem value="alphabetical-asc">
                    Alphabetical (A-Z)
                  </MenuItem>
                  <MenuItem value="alphabetical-desc">
                    Alphabetical (Z-A)
                  </MenuItem>
                  <MenuItem value="quantity-asc">
                    Quantity (Low to High)
                  </MenuItem>
                  <MenuItem value="quantity-desc">
                    Quantity (High to Low)
                  </MenuItem>
                </Select>
              </FormControl>
              <List>
                {getFilteredAndSortedSeeds().map((seed) => (
                  <ListItem
                    key={seed.itemId}
                    button
                    onClick={() => seed.quantity > 0 && handlePlantSeed(seed)}
                    disabled={seed.quantity === 0}
                    style={{ opacity: seed.quantity > 0 ? 1 : 0.5 }}
                  >
                    <ListItemAvatar>
                      <Avatar src={seed.imageUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${seed.quantity}x ${seed.itemName}`}
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPlantDialogOpen(false)}>Cancel</Button>
            </DialogActions>
          </Dialog>

          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle style={{ textAlign: "center" }}>
              {dialogTitle}
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {dialogContent}
              </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button onClick={() => setDialogOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            message={snackbar.message}
          />
          <Dialog
            open={actionStatus.isLoading}
            onClose={() =>
              setActionStatus({ ...actionStatus, isLoading: false })
            }
            aria-labelledby="loading-dialog-title"
            aria-describedby="loading-dialog-description"
            disableBackdropClick
            disableEscapeKeyDown
            PaperProps={{
              sx: {
                border: "2px solid rgba(173, 216, 230)",
                borderRadius: "8px",
              },
            }}
          >
            <DialogContent style={{ textAlign: "center" }}>
              <CircularProgress style={{ color: "#d2b48c" }} />
              <Typography variant="h6" style={{ marginTop: 16 }}>
                {actionStatus.message}
              </Typography>
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default FarmTab;
