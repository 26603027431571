let itemLevels;

function loadItemLevels() {
  if (!itemLevels) {
    itemLevels = require("./itemLevelsData.js");
    console.log("Item levels loaded.");
  }
}

function getExpForNextItemLevel(currentLevel, itemId) {
  loadItemLevels();
  const itemLevelInfo = itemLevels[itemId];
  if (itemLevelInfo) {
    const levelInfo = itemLevelInfo.find((l) => l.level === currentLevel);
    return levelInfo ? levelInfo.expNeeded : null;
  }
  return null;
}

function levelUp(item) {
  loadItemLevels();
  const itemLevelInfo = itemLevels[item.itemId];
  if (itemLevelInfo) {
    const currentLevelIndex = item.level - 1;
    if (
      currentLevelIndex + 1 < itemLevelInfo.length &&
      item.exp >= itemLevelInfo[currentLevelIndex].expNeeded
    ) {
      item.level += 1;
      item.exp = 0;

      // Check if the item has reached the maximum level
      if (item.level - 1 < itemLevelInfo.length) {
        item.expNeeded = itemLevelInfo[item.level - 1].expNeeded;
        item.expGain = itemLevelInfo[item.level - 1].expGain;
      } else {
        // Set expNeeded to a very large number to simulate infinity
        item.expNeeded = Number.MAX_SAFE_INTEGER;
        item.expGain = 0; // Assuming no exp gain at max level
      }
    }
  }
}

// Ensure itemLevels is loaded when accessed directly
loadItemLevels();

module.exports = { itemLevels, getExpForNextItemLevel, levelUp };
