// sortingUtils.js
export const sortedInventory = (items, sortConfig) => {
  let sortableItems = [...items];
  sortableItems.sort((a, b) => {
    // Special handling for 'itemId' to ensure numeric sorting
    if (sortConfig.key === "itemId") {
      const idA = Number(a.itemId);
      const idB = Number(b.itemId);
      return sortConfig.direction === "ascending" ? idA - idB : idB - idA;
    }

    // General sorting logic for other keys
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });
  return sortableItems;
};

export const requestSort = (key, sortConfig, setSortConfig) => {
  let direction = "ascending";
  if (sortConfig.key === key && sortConfig.direction === "ascending") {
    direction = "descending";
  }
  setSortConfig({ key, direction });
};
