import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./pages/routes";
import { ExpProvider } from "./modules/Home/ExpContext";
import { NotificationProvider } from "./modules/System/Components/Notification/NotificationManager";
import { AutoGatherProvider } from "./modules/Home/Tabs/SkillsTab/GatherTab/AutoGatherContext";
import { TabProvider } from "./modules/Home/TabProvider";
import { GatherCountProvider } from "./modules/System/Components/Gather/GatherCountContext";

function App() {
  useEffect(() => {
    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-1GGEPC70KR");

    // Pageview tracking
    gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname + window.location.search,
    });

    // Button click tracking
    function handleButtonClick(event) {
      const button = event.target.closest("button");

      if (button) {
        const buttonId = button.id || "button-without-id";
        const buttonName =
          button.name || button.textContent || "button-without-name";

        gtag("event", "click", {
          event_category: "Button",
          event_action: "Click",
          event_label: `${buttonId} - ${buttonName}`,
        });
      }
    }

    window.addEventListener("click", handleButtonClick);

    return () => {
      window.removeEventListener("click", handleButtonClick);
    };
  }, []);

  return (
    <ExpProvider>
      <NotificationProvider>
        <GatherCountProvider>
          <AutoGatherProvider>
            <Router>
              <TabProvider>
                <div className="App">
                  <AppRoutes />
                </div>
              </TabProvider>
            </Router>
          </AutoGatherProvider>
        </GatherCountProvider>
      </NotificationProvider>
    </ExpProvider>
  );
}

export default App;
