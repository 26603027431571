import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Snackbar,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import SecureLS from "secure-ls";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { formatCountdown } from "../FarmTab/Utilities/formatCountdown";

const Animal = ({ userInventoryRef, selectedType, setUserInventory }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("quantity-desc");
  const [animalPlots, setAnimalPlots] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [addAnimalDialogOpen, setAddAnimalDialogOpen] = useState(false);

  const [harvestDialogOpen, setHarvestDialogOpen] = useState(false);
  const [harvestedItems, setHarvestedItems] = useState([]);
  const [harvestedAnimalName, setHarvestedAnimalName] = useState("");
  const [isHarvesting, setIsHarvesting] = useState(false);
  const [isFeeding, setIsFeeding] = useState(false);
  const [isGrowing, setIsGrowing] = useState(false);
  const [isPlacingAnimal, setIsPlacingAnimal] = useState(false);

  const [butcherDialogOpen, setButcherDialogOpen] = useState(false);
  const [butcheredItems, setButcheredItems] = useState([]);
  const [isButchering, setIsButchering] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [animalToButcher, setAnimalToButcher] = useState(null);

  const [isSelling, setIsSelling] = useState(false);
  const [sellDialogOpen, setSellDialogOpen] = useState(false);
  const [sellHarvestDialogOpen, setSellHarvestDialogOpen] = useState(false);
  const [sellButcherDialogOpen, setSellButcherDialogOpen] = useState(false);
  const [sellDialogTitle, setSellDialogTitle] = useState("");
  const [itemsToSell, setItemsToSell] = useState([]);
  const [soldDialogOpen, setSoldDialogOpen] = useState(false);
  const [animalToSell, setAnimalToSell] = useState(null);
  const [earnedCoins, setEarnedCoins] = useState(0);
  const [soldCoinImageUrl, setSoldCoinImageUrl] = useState("");
  const [coinName, setCoinName] = useState("");

  const ls = new SecureLS({ isCompression: false });
  const userDetails = ls.get("userDetails");
  const userId = userDetails ? userDetails.userId : null;

  const animals =
    userInventoryRef.current && userInventoryRef.current.itemDetails
      ? userInventoryRef.current.itemDetails.filter(
          (item) =>
            item.itemType === "animal" &&
            item.itemName.toLowerCase().includes(selectedType.toLowerCase())
        )
      : [];

  const fetchAnimalPlots = async () => {
    setIsDataLoading(true);
    if (userId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/initialize`
        );
        const data = await response.json();
        const filteredPlots = data.filter(
          (plot) =>
            !plot.animalDetails.animalName ||
            plot.animalDetails.animalName
              .toLowerCase()
              .includes(selectedType.toLowerCase())
        );
        setAnimalPlots(filteredPlots);
        setIsDataLoading(false);
      } catch (error) {
        console.error("Error initializing animal plots:", error);
        setSnackbar({ open: true, message: "Failed to initialize plots." });
        setIsDataLoading(false);
      }
    } else {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchAnimalPlots();
  }, [userId]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortType(event.target.value);
  };

  const getFilteredAndSortedAnimals = () => {
    return animals
      .filter(
        (animal) =>
          animal.itemName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          animal.quantity.toString().includes(searchQuery)
      )
      .sort((a, b) => {
        switch (sortType) {
          case "alphabetical-asc":
            return a.itemName.localeCompare(b.itemName);
          case "alphabetical-desc":
            return b.itemName.localeCompare(a.itemName);
          case "quantity-asc":
            return a.quantity - b.quantity;
          case "quantity-desc":
            return b.quantity - a.quantity;
          default:
            return 0;
        }
      });
  };

  const handleAddAnimalToPlot = async (animal) => {
    if (!userId || !selectedPlot || !animal || animal.quantity === 0) return;

    setIsPlacingAnimal(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/placeAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: selectedPlot,
            animalId: Number(animal.itemId),
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not add the animal");
      }

      // Update the animal plots after adding an animal
      fetchAnimalPlots();

      // Deduct 1 from the animal quantity in the local state
      updateInventory(animal.itemId, -1);

      setSnackbar({ open: true, message: "Animal successfully added" });
    } catch (error) {
      console.error("Error adding animal to plot:", error);
      setSnackbar({ open: true, message: "Failed to add animal to plot." });
    } finally {
      setIsPlacingAnimal(false);
      setAddAnimalDialogOpen(false);
    }
  };

  const handleGrow = async (plotNumber) => {
    setIsGrowing(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/growAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNumber,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not grow the animal");
      }

      // Update the animal plots after growing an animal
      fetchAnimalPlots();

      setSnackbar({ open: true, message: "Animal grew successfully" });
    } catch (error) {
      console.error("Error growing animal:", error);
      setSnackbar({ open: true, message: "Failed to grow animal." });
    } finally {
      setIsGrowing(false);
    }
  };

  const handleHarvest = async (plotNumber) => {
    setIsHarvesting(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/harvestAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNumber,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not harvest the animal");
      }

      // Update the animal plots after harvesting an animal
      fetchAnimalPlots();

      // Update local state of inventory
      const updatedInventory = [...userInventoryRef.current.itemDetails];
      Object.keys(data.harvestedItems).forEach((itemId) => {
        const item = updatedInventory.find(
          (item) => parseInt(item.itemId) === parseInt(itemId)
        );
        if (item) {
          item.quantity += data.harvestedItems[itemId].quantity;
        } else {
          updatedInventory.push({
            itemId: parseInt(itemId),
            itemName: data.harvestedItems[itemId].name,
            quantity: data.harvestedItems[itemId].quantity,
            imageUrl: data.harvestedItems[itemId].imageUrl,
          });
        }
      });

      userInventoryRef.current = {
        ...userInventoryRef.current,
        itemDetails: updatedInventory,
      };
      setUserInventory((prev) => ({ ...prev, itemDetails: updatedInventory }));

      setHarvestedItems(data.harvestedItems);
      setHarvestedAnimalName(
        animalPlots.find((plot) => plot.plotNum === plotNumber).animalDetails
          .animalName
      );
      setHarvestDialogOpen(true);

      setSnackbar({ open: true, message: "Animal harvested successfully" });
    } catch (error) {
      console.error("Error harvesting animal:", error);
      setSnackbar({ open: true, message: "Failed to harvest animal." });
    } finally {
      setIsHarvesting(false);
    }
  };

  const handleOpenButcherDialog = (plot) => {
    setAnimalToButcher(plot);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmButcher = async () => {
    if (!animalToButcher) return;

    const plotNumber = animalToButcher.plotNum;
    setIsButchering(true);
    setConfirmationDialogOpen(false); // Close the confirmation dialog

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/butcherAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNumber,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not butcher the animal");
      }

      // Update the animal plots after butchering an animal
      fetchAnimalPlots();

      // Update local state of inventory
      const updatedInventory = [...userInventoryRef.current.itemDetails];
      Object.keys(data.butcheredItems).forEach((itemId) => {
        const item = updatedInventory.find(
          (item) => parseInt(item.itemId) === parseInt(itemId)
        );
        if (item) {
          item.quantity += data.butcheredItems[itemId].quantity;
        } else {
          updatedInventory.push({
            itemId: parseInt(itemId),
            itemName: data.butcheredItems[itemId].name,
            quantity: data.butcheredItems[itemId].quantity,
            imageUrl: data.butcheredItems[itemId].imageUrl,
          });
        }
      });

      userInventoryRef.current = {
        ...userInventoryRef.current,
        itemDetails: updatedInventory,
      };
      setUserInventory((prev) => ({ ...prev, itemDetails: updatedInventory }));

      setButcheredItems(data.butcheredItems);
      setHarvestedAnimalName(
        animalPlots.find((plot) => plot.plotNum === plotNumber).animalDetails
          .animalName
      );
      setButcherDialogOpen(true);

      setSnackbar({ open: true, message: "Animal butchered successfully" });
    } catch (error) {
      console.error("Error butchering animal:", error);
      setSnackbar({ open: true, message: "Failed to butcher animal." });
    } finally {
      setIsButchering(false);
    }
  };

  const handleOpenSellDialog = (plot) => {
    setAnimalToSell(plot);
    setSellDialogOpen(true);
  };

  const handleConfirmSell = async () => {
    if (!animalToSell) return;

    const plotNumber = animalToSell.plotNum;
    setIsSelling(true);
    setSellDialogOpen(false); // Close the confirmation dialog

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/sellAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNumber,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not sell the animal");
      }

      // Update the animal plots after selling an animal
      fetchAnimalPlots();

      // Update local state of inventory
      const updatedInventory = [...userInventoryRef.current.itemDetails];
      const item = updatedInventory.find(
        (item) => parseInt(item.itemId) === parseInt(data.coinItemId)
      );
      if (item) {
        item.quantity += data.earnedCoins;
      } else {
        updatedInventory.push({
          itemId: parseInt(data.coinItemId),
          itemName: data.coinName,
          quantity: data.earnedCoins,
          imageUrl: data.coinImg,
        });
      }

      userInventoryRef.current = {
        ...userInventoryRef.current,
        itemDetails: updatedInventory,
      };
      setUserInventory((prev) => ({ ...prev, itemDetails: updatedInventory }));

      setEarnedCoins(data.earnedCoins);
      setSoldCoinImageUrl(data.coinImg);
      setCoinName(data.coinName);
      setSoldDialogOpen(true); // Open the sold dialog

      setSnackbar({ open: true, message: "Animal sold successfully" });
    } catch (error) {
      console.error("Error selling animal:", error);
      setSnackbar({ open: true, message: "Failed to sell animal." });
    } finally {
      setIsSelling(false);
    }
  };

  const handleConfirmSellItems = async () => {
    setIsSelling(true);
    setSellHarvestDialogOpen(false);
    setSellButcherDialogOpen(false);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/sellAnimalProduce`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ itemsToSell }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not sell the items");
      }

      // Update local state of inventory
      const updatedInventory = [...userInventoryRef.current.itemDetails];
      itemsToSell.forEach(({ itemId, quantity }) => {
        const item = updatedInventory.find(
          (item) => parseInt(item.itemId) === parseInt(itemId)
        );
        if (item) {
          item.quantity -= quantity;
          if (item.quantity === 0) {
            updatedInventory.splice(updatedInventory.indexOf(item), 1);
          }
        }
      });

      const coinItem = updatedInventory.find(
        (item) => parseInt(item.itemId) === parseInt(data.coinItemId)
      );
      if (coinItem) {
        coinItem.quantity += data.earnedCoins;
      } else {
        updatedInventory.push({
          itemId: parseInt(data.coinItemId),
          itemName: data.coinName,
          quantity: data.earnedCoins,
          imageUrl: data.coinImg,
        });
      }

      userInventoryRef.current = {
        ...userInventoryRef.current,
        itemDetails: updatedInventory,
      };
      setUserInventory((prev) => ({ ...prev, itemDetails: updatedInventory }));

      setEarnedCoins(data.earnedCoins);
      setSoldCoinImageUrl(data.coinImg);
      setCoinName(data.coinName);
      setSoldDialogOpen(true); // Open the sold dialog

      setSnackbar({ open: true, message: "Items sold successfully" });
    } catch (error) {
      console.error("Error selling items:", error);
      setSnackbar({ open: true, message: "Failed to sell items." });
    } finally {
      setIsSelling(false);
    }
  };

  const handleOpenSellHarvestDialog = () => {
    setItemsToSell(
      Object.keys(harvestedItems).map((itemId) => ({
        itemId: parseInt(itemId),
        quantity: harvestedItems[itemId].quantity,
      }))
    );
    setSellDialogTitle("Are you sure you want to sell the harvested produce?");
    setSellHarvestDialogOpen(true);
  };

  const handleOpenSellButcherDialog = () => {
    setItemsToSell(
      Object.keys(butcheredItems).map((itemId) => ({
        itemId: parseInt(itemId),
        quantity: butcheredItems[itemId].quantity,
      }))
    );
    setSellDialogTitle("Are you sure you want to sell the butchered produce?");
    setSellButcherDialogOpen(true);
  };

  const updateInventory = (itemKey, change) => {
    const updatedInventory = userInventoryRef.current.itemDetails.map(
      (item) => {
        if (parseInt(item.itemId) === parseInt(itemKey)) {
          return { ...item, quantity: item.quantity + change };
        }
        return item;
      }
    );

    // Update ref
    userInventoryRef.current = {
      ...userInventoryRef.current,
      itemDetails: updatedInventory,
    };

    // Update state to trigger re-render
    setUserInventory((prev) => ({
      ...prev,
      itemDetails: updatedInventory,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFeedAnimal = async (plotNumber, feedAmount, feedType) => {
    setIsFeeding(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/animal/${userId}/feedAnimal`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            plotNumber: plotNumber,
            feedAmount: feedAmount,
            feedType: feedType,
          }),
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not feed the animal");
      }

      // Update the animal plots after feeding an animal
      fetchAnimalPlots();

      setSnackbar({ open: true, message: "Animal fed successfully" });
    } catch (error) {
      console.error("Error feeding animal:", error);
      setSnackbar({ open: true, message: "Failed to feed animal." });
    } finally {
      setIsFeeding(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAnimalPlots((currentPlots) =>
        currentPlots.map((plot) => {
          const currentTime = new Date().getTime(); // Current time in milliseconds
          const dateFed = new Date(plot.animalDetails.dateFed).getTime() || 0;
          const dateToFeed =
            new Date(plot.animalDetails.dateToFeed).getTime() || 0;
          const dateToDeath =
            new Date(plot.animalDetails.dateToDeath).getTime() || 0;
          const datePlaced =
            new Date(plot.animalDetails.datePlaced).getTime() || 0;
          const dateToMaturity =
            new Date(plot.animalDetails.dateToMaturity).getTime() || 0;

          // If any date is 0, it means the date is invalid or missing
          if (
            !dateFed ||
            !dateToFeed ||
            !dateToDeath ||
            !datePlaced ||
            !dateToMaturity
          ) {
            return plot;
          }

          // Calculate progress for hunger, time to death, and time to maturity
          let hungerProgress =
            dateToFeed - currentTime < 0
              ? 0
              : Math.max(
                  Math.min(
                    ((dateToFeed - currentTime) / (dateToFeed - dateFed)) * 100,
                    100
                  ),
                  0
                );

          let deathProgress =
            dateToDeath - currentTime < 0
              ? 0
              : Math.max(
                  Math.min(
                    ((dateToDeath - currentTime) / (dateToDeath - dateToFeed)) *
                      100,
                    100
                  ),
                  0
                );

          let maturityProgress =
            dateToMaturity - currentTime < 0
              ? 100
              : Math.max(
                  Math.min(
                    ((dateToMaturity - currentTime) /
                      (dateToMaturity - datePlaced)) *
                      100,
                    100
                  ),
                  0
                );

          return {
            ...plot,
            animalDetails: {
              ...plot.animalDetails,
              hungerProgress,
              deathProgress,
              maturityProgress,
            },
          };
        })
      );
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        {`${selectedType} Management`}
      </Typography>
      {animalPlots.map((plot) => (
        <Card key={plot.plotNum} sx={{ marginBottom: 2 }}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box sx={{ flex: 1, textAlign: "left", mb: { xs: 2, md: 0 } }}>
              <Typography variant="h6">Plot {plot.plotNum}</Typography>
              {plot.animalDetails.addedAnimalId ? (
                <>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    Animal: {plot.animalDetails.animalName}
                    <Box sx={{ display: "flex", ml: 1 }}>
                      {Array.from({ length: 5 }, (_, index) => (
                        <Tooltip
                          key={index}
                          title={`Affection Level: ${plot.animalDetails.affectionLvl.toFixed(
                            2
                          )}`}
                          placement="top"
                        >
                          <FavoriteIcon
                            sx={{
                              color:
                                new Date(plot.animalDetails.dateToDeath) <
                                new Date()
                                  ? index <
                                    Math.floor(plot.animalDetails.affectionLvl)
                                    ? "black"
                                    : "grey"
                                  : index <
                                    Math.floor(plot.animalDetails.affectionLvl)
                                  ? "red"
                                  : "grey",
                            }}
                          />
                        </Tooltip>
                      ))}
                    </Box>
                  </Typography>
                  <Typography variant="body2">
                    Life Stage: {plot.animalDetails.currentLifeStage}
                  </Typography>
                  <Box
                    sx={{
                      width: { xs: 100, md: 175 },
                      height: { xs: 100, md: 175 },
                      mx: "auto",
                      mt: 2,
                      mb: 2,
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      filter:
                        new Date(plot.animalDetails.dateToDeath) < new Date()
                          ? "grayscale(100%)"
                          : "none",
                    }}
                  >
                    {isHarvesting ? (
                      <>
                        <CircularProgress />
                        <Typography
                          variant="caption"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, 100%)",
                            mt: 1,
                          }}
                        >
                          Harvesting Produce...
                        </Typography>
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: { xs: 100, md: 175 },
                          height: { xs: 100, md: 175 },
                          mx: "auto",
                          mt: 2,
                          mb: 2,
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          filter:
                            plot.animalDetails.currentLifeStage === "dead"
                              ? "grayscale(100%)"
                              : "none",
                        }}
                      >
                        {plot.animalDetails.currentLifeStage === "dead" && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 1,
                              pointerEvents: "none",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                height: "7px",
                                backgroundColor: "black",
                                position: "absolute",
                                transform: "rotate(45deg)",
                              }}
                            />
                            <Box
                              sx={{
                                width: "100%",
                                height: "7px",
                                backgroundColor: "black",
                                position: "absolute",
                                transform: "rotate(-45deg)",
                              }}
                            />
                          </Box>
                        )}
                        <Avatar
                          src={plot.animalDetails.animalImg}
                          alt={plot.animalDetails.animalType}
                          sx={{
                            width: { xs: 100, md: 175 },
                            height: { xs: 100, md: 175 },
                            position: "relative",
                            zIndex: 0,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="caption">Hunger</Typography>
                    <Tooltip
                      title={
                        plot.animalDetails.currentLifeStage === "dead"
                          ? "You are too late"
                          : new Date(plot.animalDetails.dateToFeed) < new Date()
                          ? "Animal is Hungry"
                          : `Time until hunger: ${formatCountdown(
                              plot.animalDetails.dateToFeed
                            )}`
                      }
                      placement="top"
                    >
                      <LinearProgress
                        variant="determinate"
                        value={plot.animalDetails.hungerProgress}
                        sx={{
                          height: 10,
                          mb: 1,
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "orange",
                          },
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {plot.animalDetails.hungerProgress === 0 &&
                    plot.animalDetails.currentLifeStage !== "dead" && (
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="caption">Time to Death</Typography>
                        <Tooltip
                          title={`Time until death: ${formatCountdown(
                            plot.animalDetails.dateToDeath
                          )}`}
                          placement="top"
                        >
                          <LinearProgress
                            variant="determinate"
                            value={plot.animalDetails.deathProgress}
                            sx={{
                              height: 10,
                              mb: 1,
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "darkgrey",
                              },
                            }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  {plot.animalDetails.currentLifeStage === "dead" && (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="caption">Time to Death</Typography>
                      <Tooltip title="Animal is dead" placement="top">
                        <LinearProgress
                          variant="determinate"
                          value={0}
                          sx={{
                            height: 10,
                            mb: 1,
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "darkgrey",
                            },
                          }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                  {plot.animalDetails.currentLifeStage !== "adult" &&
                    plot.animalDetails.currentLifeStage !== "dead" && (
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="caption">
                          Time to Maturity
                        </Typography>
                        <Tooltip
                          title={`Time to maturity: ${formatCountdown(
                            plot.animalDetails.dateToMaturity
                          )}`}
                          placement="top"
                        >
                          <LinearProgress
                            variant="determinate"
                            value={100 - plot.animalDetails.maturityProgress}
                            sx={{
                              height: 10,
                              mb: 1,
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "green",
                              },
                            }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      mt: 2,
                    }}
                  >
                    {new Date(plot.animalDetails.dateToDeath) < new Date() ||
                    plot.animalDetails.currentLifeStage === "dead" ? (
                      <Tooltip title="Are you sure???" placement="top">
                        <span>
                          <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mx: 1, mb: 2 }}
                            disabled={isSelling}
                            onClick={() => handleOpenSellDialog(plot)}
                          >
                            {isSelling ? "Selling Animal..." : "Sell"}
                          </Button>
                        </span>
                      </Tooltip>
                    ) : (
                      <>
                        {new Date(plot.animalDetails.dateToFeed) <
                          new Date() && (
                          <Tooltip
                            title={
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography variant="body2">
                                  {plot.animalDetails.feedAmount}x
                                </Typography>
                                <Avatar
                                  src={plot.animalDetails.feedImg}
                                  alt="Feed"
                                  sx={{ width: 24, height: 24, ml: 1 }}
                                />
                              </Box>
                            }
                            placement="top"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ mx: 1, mb: 2 }}
                              onClick={() => {
                                const feedItem =
                                  userInventoryRef.current.itemDetails.find(
                                    (item) =>
                                      parseInt(item.itemId) ===
                                      parseInt(plot.animalDetails.feedType)
                                  );
                                if (
                                  feedItem &&
                                  feedItem.quantity >=
                                    plot.animalDetails.feedAmount
                                ) {
                                  handleFeedAnimal(
                                    plot.plotNum,
                                    plot.animalDetails.feedAmount,
                                    plot.animalDetails.feedType
                                  );
                                } else {
                                  setSnackbar({
                                    open: true,
                                    message: "Not enough feed available.",
                                  });
                                }
                              }}
                              disabled={isFeeding}
                            >
                              {isFeeding ? (
                                <CircularProgress size={24} />
                              ) : (
                                "Feed"
                              )}
                            </Button>
                          </Tooltip>
                        )}
                        <Tooltip
                          title={
                            plot.animalDetails.currentLifeStage === "baby"
                              ? "Too young to produce"
                              : `Time until harvest: ${formatCountdown(
                                  plot.animalDetails.dateToHarvest
                                )}`
                          }
                          placement="top"
                        >
                          <span>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ mx: 1, mb: 2 }}
                              disabled={
                                !plot.animalDetails.dateToHarvest ||
                                new Date(plot.animalDetails.dateToHarvest) >
                                  new Date() ||
                                plot.animalDetails.currentLifeStage ===
                                  "baby" ||
                                isHarvesting
                              }
                              onClick={() => handleHarvest(plot.plotNum)}
                            >
                              {isHarvesting
                                ? "Harvesting Produce..."
                                : "Harvest"}
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Are you sure???" placement="top">
                          <span>
                            <Button
                              variant="contained"
                              color="secondary"
                              sx={{ mx: 1, mb: 2 }}
                              disabled={isSelling}
                              onClick={() => handleOpenSellDialog(plot)}
                            >
                              {isSelling ? "Selling Animal..." : "Sell"}
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={
                            plot.animalDetails.currentLifeStage === "baby"
                              ? "Too young to butcher"
                              : ""
                          }
                          placement="top"
                        >
                          <span>
                            <Button
                              variant="contained"
                              color="error"
                              sx={{ mx: 1, mb: 2 }}
                              disabled={
                                plot.animalDetails.currentLifeStage ===
                                  "baby" || isButchering
                              }
                              onClick={() => handleOpenButcherDialog(plot)}
                            >
                              {isButchering
                                ? "Butchering Animal..."
                                : "Butcher"}
                            </Button>
                          </span>
                        </Tooltip>
                        {plot.animalDetails.maturityProgress >= 100 &&
                          plot.animalDetails.currentLifeStage !== "adult" && (
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ mx: 1, mb: 2 }}
                              onClick={() => handleGrow(plot.plotNum)}
                              disabled={isGrowing}
                            >
                              {isGrowing ? (
                                <CircularProgress size={24} />
                              ) : (
                                "Grow"
                              )}
                            </Button>
                          )}
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setSelectedPlot(plot.plotNum);
                    setAddAnimalDialogOpen(true);
                  }}
                >
                  Add Animal
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      ))}

      <Dialog
        open={addAnimalDialogOpen}
        onClose={() => setAddAnimalDialogOpen(false)}
      >
        <DialogTitle>Select an Animal to Add</DialogTitle>
        <DialogContent>
          <TextField
            label="Search Animals"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="sort-label">Sort By</InputLabel>
            <Select
              labelId="sort-label"
              value={sortType}
              label="Sort By"
              onChange={handleSortChange}
            >
              <MenuItem value="alphabetical-asc">Alphabetical (A-Z)</MenuItem>
              <MenuItem value="alphabetical-desc">Alphabetical (Z-A)</MenuItem>
              <MenuItem value="quantity-asc">Quantity (Low to High)</MenuItem>
              <MenuItem value="quantity-desc">Quantity (High to Low)</MenuItem>
            </Select>
          </FormControl>
          <List>
            {getFilteredAndSortedAnimals().map((animal) => (
              <ListItem
                key={animal.itemId}
                button
                onClick={() =>
                  animal.quantity > 0 && handleAddAnimalToPlot(animal)
                }
                disabled={animal.quantity === 0}
                style={{ opacity: animal.quantity > 0 ? 1 : 0.5 }}
              >
                <ListItemAvatar>
                  <Avatar src={animal.imageUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${animal.quantity}x ${animal.itemName}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddAnimalDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={harvestDialogOpen}
        onClose={() => setHarvestDialogOpen(false)}
      >
        <DialogTitle>You harvested {harvestedAnimalName} and got:</DialogTitle>
        <DialogContent>
          <List>
            {Object.keys(harvestedItems).map((itemId) => (
              <ListItem key={itemId}>
                <ListItemAvatar>
                  <Avatar src={harvestedItems[itemId].imageUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${harvestedItems[itemId].quantity} ${harvestedItems[itemId].name}`}
                />
              </ListItem>
            ))}
          </List>
          <Typography variant="body1">
            Total items:{" "}
            {Object.values(harvestedItems).reduce(
              (acc, item) => acc + item.quantity,
              0
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHarvestDialogOpen(false)} color="primary">
            Store
          </Button>
          <Button onClick={handleOpenSellHarvestDialog} color="secondary">
            Sell
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={butcherDialogOpen}
        onClose={() => setButcherDialogOpen(false)}
      >
        <DialogTitle>You butchered {harvestedAnimalName} and got:</DialogTitle>
        <DialogContent>
          <List>
            {Object.keys(butcheredItems).map((itemId) => (
              <ListItem key={itemId}>
                <ListItemAvatar>
                  <Avatar src={butcheredItems[itemId].imageUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${butcheredItems[itemId].quantity} ${butcheredItems[itemId].name}`}
                />
              </ListItem>
            ))}
          </List>
          <Typography variant="body1">
            Total items:{" "}
            {Object.values(butcheredItems).reduce(
              (acc, item) => acc + item.quantity,
              0
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setButcherDialogOpen(false)} color="primary">
            Store
          </Button>
          <Button onClick={handleOpenSellButcherDialog} color="secondary">
            Sell
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
      >
        <DialogTitle>
          Are you sure you want to butcher{" "}
          {animalToButcher?.animalDetails.animalName}?
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>You currently have </Typography>
            <Box sx={{ display: "flex", alignItems: "center", ml: 1, mr: 1 }}>
              {Array.from({ length: 5 }, (_, index) => (
                <Tooltip
                  key={index}
                  title={`Affection Level: ${animalToButcher?.animalDetails.affectionLvl.toFixed(
                    2
                  )}`}
                  placement="top"
                >
                  <FavoriteIcon
                    sx={{
                      color:
                        index <
                        Math.floor(animalToButcher?.animalDetails.affectionLvl)
                          ? "red"
                          : "grey",
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
            <Typography align="center">affection level</Typography>
          </Box>
          <Typography>Surely you will miss them?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmButcher} color="error">
            Butcher!!
          </Button>
          <Button
            onClick={() => setConfirmationDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={sellDialogOpen} onClose={() => setSellDialogOpen(false)}>
        <DialogTitle>
          Are you sure you want to sell {animalToSell?.animalDetails.animalName}
          ?
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>You currently have </Typography>
            <Box sx={{ display: "flex", alignItems: "center", ml: 1, mr: 1 }}>
              {Array.from({ length: 5 }, (_, index) => (
                <Tooltip
                  key={index}
                  title={`Affection Level: ${animalToSell?.animalDetails.affectionLvl.toFixed(
                    2
                  )}`}
                  placement="top"
                >
                  <FavoriteIcon
                    sx={{
                      color:
                        new Date(animalToSell?.animalDetails.dateToDeath) <
                        new Date()
                          ? index <
                            Math.floor(animalToSell?.animalDetails.affectionLvl)
                            ? "black"
                            : "grey"
                          : index <
                            Math.floor(animalToSell?.animalDetails.affectionLvl)
                          ? "red"
                          : "grey",
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
            <Typography align="center">affection level</Typography>
          </Box>
          <Typography>Surely you will miss them?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSell} color="error">
            Sell!!
          </Button>
          <Button onClick={() => setSellDialogOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={soldDialogOpen}
        onClose={() => {
          setSoldDialogOpen(false);
          setButcherDialogOpen(false);
          setHarvestDialogOpen(false);
        }}
      >
        <DialogTitle>You sold your animal and got:</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={soldCoinImageUrl} />
              </ListItemAvatar>
              <ListItemText primary={`${earnedCoins} ${coinName}`} />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSoldDialogOpen(false);
              setButcherDialogOpen(false);
              setHarvestDialogOpen(false);
            }}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={sellHarvestDialogOpen}
        onClose={() => {
          setSellHarvestDialogOpen(false);
          setHarvestDialogOpen(false);
        }}
      >
        <DialogTitle>{sellDialogTitle}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to sell these items?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSellItems} color="error">
            Sell
          </Button>
          <Button
            onClick={() => {
              setSellHarvestDialogOpen(false);
              setHarvestDialogOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={sellButcherDialogOpen}
        onClose={() => {
          setSellButcherDialogOpen(false);
          setButcherDialogOpen(false);
        }}
      >
        <DialogTitle>{sellDialogTitle}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to sell these items?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmSellItems} color="error">
            Sell
          </Button>
          <Button
            onClick={() => {
              setSellButcherDialogOpen(false);
              setButcherDialogOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isPlacingAnimal}>
        <DialogTitle>Placing Animal</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Box>
  );
};

export default Animal;
