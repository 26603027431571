import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import { useAutoGather } from "../GatherTab/AutoGatherContext";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import "../../../../../css/home.css";

const CraftItemCard = ({
  item,
  onCraft,
  userInventory,
  setUserInventory,
  craftItems,
  allItems,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [craftAmount, setCraftAmount] = useState(1);
  const [isCrafting, setIsCrafting] = useState(false);
  const [currentCraft, setCurrentCraft] = useState(0);
  const [totalCrafts, setTotalCrafts] = useState(0);
  const [hasCrafted, setHasCrafted] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { craftingProgress, updateCraftingProgress } = useAutoGather();
  const [infoDialog, setInfoDialog] = useState(false);
  const inventoryUpdatedRef = useRef(false);

  const matchingItem = userInventory.itemDetails.find(
    (itemDetail) => itemDetail.itemId.toString() === item.itemId.toString()
  );

  const { updateCraftingState, setCraftingInterval, clearCraftingInterval } =
    useAutoGather();

  useEffect(() => {
    // Check if there's existing progress in context and set it
    if (craftingProgress[item.itemId] !== undefined) {
      setCurrentCraft(craftingProgress[item.itemId]);
      setIsCrafting(craftingProgress[item.itemId] < 100);
    }
  }, [item.itemId, craftingProgress]);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  const toggleInfoDialog = () => setInfoDialog(!isDialogOpen);

  // Function to find item name and amount by ID
  const findItemDetailsById = (id) => {
    let foundItem = allItems.find((item) => item.itemId === id);

    if (!foundItem) {
      foundItem = craftItems.find((item) => item.itemId === id);
    }

    const userItem = userInventory.itemDetails.find(
      (invItem) => invItem.itemId === id.toString()
    );

    return {
      name: foundItem ? foundItem.name : "Unknown Item",
      imageUrl: foundItem ? foundItem.imageUrl : "/assets/items/default.webp",
      userAmount: userItem ? userItem.quantity : 0,
    };
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setHasCrafted(false);
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenInfoDialog = () => setInfoDialog(true);
  const handleCloseInfoDialog = () => setInfoDialog(false);

  const getMaxCraftAmount = () => {
    return Math.min(
      ...item.itemProcess.map((craft) => {
        const { userAmount } = findItemDetailsById(craft.itemId);
        return Math.floor(userAmount / craft.itemAmount);
      })
    );
  };

  const handleChangeAmount = (event) => {
    let amount =
      event.target.value === "" ? "" : parseInt(event.target.value, 10);

    // Automatically adjust to the maximum craftable amount if entered amount is too high
    const maxCraftableAmount = getMaxCraftAmount();
    if (amount > maxCraftableAmount) {
      amount = maxCraftableAmount;
    }

    setCraftAmount(amount);
    setHasCrafted(false);
  };

  const isConfirmDisabled = () => {
    if (craftAmount === "" || craftAmount === 0) {
      return true;
    }

    // Disable if craftAmount * itemAmount exceeds userAmount for any craft item
    return item.itemProcess.some((craft) => {
      const { userAmount } = findItemDetailsById(craft.itemId);
      return craftAmount * craft.itemAmount > userAmount;
    });
  };

  const handleConfirmCraft = () => {
    const maxAmount = Math.min(
      ...item.itemProcess.map((craft) => {
        const { userAmount } = findItemDetailsById(craft.itemId);
        return Math.floor(userAmount / craft.itemAmount);
      })
    );

    const amountToCraft = Math.min(craftAmount, maxAmount);

    // Check if the user has enough materials for the requested craft amount
    const hasMaterials = item.itemProcess.every((craft) => {
      const { userAmount } = findItemDetailsById(craft.itemId);
      return amountToCraft * craft.itemAmount <= userAmount;
    });

    if (!hasMaterials) {
      console.error("Not enough materials to craft this amount.");
      // Optionally, add a user notification here to inform them they don't have enough materials
      return;
    }

    // Deduct materials from inventory upfront
    item.itemProcess.forEach((craft) => {
      const inventoryItem = userInventory.itemDetails.find(
        (invItem) => invItem.itemId === craft.itemId.toString()
      );
      if (inventoryItem) {
        inventoryItem.quantity -= craft.itemAmount * amountToCraft;
      }
    });

    console.log("item.itemId: ", item.itemId);

    setIsCrafting(true);
    setTotalCrafts(amountToCraft);
    setCurrentCraft(0);
    onCraft(item.itemId, amountToCraft);
    updateCraftProgress(amountToCraft);
    handleCloseDialog();
    updateCraftingState(item.itemId, true);

    // Setup the crafting interval
    const interval = updateCraftProgress(amountToCraft);
    setCraftingInterval(item.itemId, interval);
  };

  // Call this function after each crafting step is complete
  const updateCraftProgress = (TotalCrafts) => {
    const totalTime = item.processTime * TotalCrafts * 1000;
    let progressIncrement = totalTime === 0 ? 0 : (100 / totalTime) * 100;

    clearCraftingInterval(item.itemId);

    inventoryUpdatedRef.current = false; // Reset the ref

    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += progressIncrement;
        setCurrentCraft(progress);
        updateCraftingProgress(item.itemId, progress);
      } else {
        if (!inventoryUpdatedRef.current) {
          console.log(
            "Updating inventory for item:",
            item.itemId,
            "Total crafts:",
            TotalCrafts
          );

          // Decrement materials
          // item.itemProcess.forEach((craft) => {
          //   const materialInventoryItem = userInventory.itemDetails.find(
          //     (invItem) => invItem.itemId === craft.itemId.toString()
          //   );
          //   if (materialInventoryItem) {
          //     console.log("Before update:", materialInventoryItem.quantity);
          //     materialInventoryItem.quantity -= craft.itemAmount * TotalCrafts;
          //     console.log("After update:", materialInventoryItem.quantity);
          //   }
          // });

          // Increment crafted item
          const craftedItem = userInventory.itemDetails.find(
            (invItem) => invItem.itemId === item.itemId.toString()
          );
          if (craftedItem) {
            console.log("Before craft update:", craftedItem.quantity);
            craftedItem.quantity += 1 * TotalCrafts;
            console.log("After craft update:", craftedItem.quantity);
          } else {
            // If the crafted item doesn't exist in the inventory, add it
            userInventory.itemDetails.push({
              itemId: item.itemId.toString(),
              quantity: 1 * TotalCrafts, // Adjust as needed
              // ...other necessary properties
            });
          }

          inventoryUpdatedRef.current = true;
        }

        clearInterval(interval);
        setIsCrafting(false);
        setCurrentCraft(0);
        updateCraftingState(item.itemId, false);
        updateCraftingProgress(item.itemId, 100);
      }
    }, 100);

    setCraftingInterval(item.itemId, interval);
  };

  return (
    <Card
      sx={{ maxWidth: 345, m: 1, backgroundColor: "rgba(255, 255, 255, 0.4)" }}
    >
      <CardHeader
        title={item.name}
        subheader={`Level: ${matchingItem ? matchingItem.itemLevel : 0} Exp: ${
          item.itemExp ? ((item.itemExp / item.expNeeded) * 100).toFixed(2) : 0
        }%`}
        action={
          <>
            <IconButton onClick={handleOpenInfoDialog}>
              <InfoIcon style={{ color: "gray" }} />
            </IconButton>
            <Dialog
              open={infoDialog}
              onClose={handleCloseInfoDialog}
              PaperProps={{
                sx: {
                  border: "2px solid rgba(173, 216, 230)",
                  borderRadius: "8px",
                },
              }}
            >
              <div
                style={{
                  margin: "10px",
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Requirements:
                </Typography>
                <List style={{ padding: 0 }}>
                  {item.itemProcess.map((craft, index) => {
                    const { name, imageUrl, userAmount } = findItemDetailsById(
                      craft.itemId
                    );
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar src={imageUrl} alt={name} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${name}: ${craft.itemAmount}`}
                          secondary={`(${userAmount})`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <DialogActions>
                <Button onClick={handleCloseInfoDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        }
      />
      <CardMedia
        component="img"
        sx={{
          width: 190,
          maxWidth: 190,
          height: 190,
          maxHeight: 190,
          objectFit: "contain",
          display: "block",
          margin: "auto",
          textAlign: "center",
        }}
        image={item.imageUrl || "/assets/items/default.webp"}
        alt={item.name}
        onClick={toggleDialog}
      />
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ width: "100%", mt: 0.15 }}
        >
          {isCrafting ? (
            <Box sx={{ position: "relative", width: "100%", height: "40px" }}>
              <LinearProgress
                variant="determinate"
                value={currentCraft}
                sx={{ width: "100%", height: "100%" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "white" }}
                >{`${Math.round(currentCraft)}%`}</Typography>
              </Box>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
              sx={{ width: "100%", height: "40px" }}
            >
              Craft
            </Button>
          )}
        </Box>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Craft How Many?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="craftAmount"
            label="Amount to Craft"
            type="number"
            fullWidth
            variant="standard"
            value={craftAmount}
            onChange={handleChangeAmount}
            inputProps={{ min: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmCraft} disabled={isConfirmDisabled()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for displaying item details */}
      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}>
          {item.name}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{item.description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default CraftItemCard;
