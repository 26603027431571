import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import "../../css/landingPage.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

function LandingPage() {
  const navigate = useNavigate();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const cardsRef = useRef([]);
  const stackAreaRef = useRef(null);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const infoRef = useRef(null);

  const [activeTab, setActiveTab] = useState("home");
  const [activeCardText, setActiveCardText] = useState({
    title: "Gather Skill",
    text: "Is tapping relentlessly your forte? The Gather Skill allows one to gather until you falther!",
    images: [
      "/assets/items/materials/stone1.webp",
      "/assets/items/materials/sand1.webp",
      "/assets/items/materials/leaf1.webp",
      "/assets/items/materials/branch1.webp",
      "/assets/items/materials/dirt1.webp",
    ],
  });
  const cardContents = [
    {
      // title: "Gather Skill",
      // text: "Is tapping relentlessly your forte? The Gather Skill allows one to gather until you falther!",
      // images: [
      //   "/assets/items/materials/stone1.webp",
      //   "/assets/items/materials/sand1.webp",
      //   "/assets/items/materials/leaf1.webp",
      //   "/assets/items/materials/branch1.webp",
      // ],
    },
    {
      title: "Process Skill",
      text: "Want to refine your items to greater heights? The process skill allows you to 'upgrade' your items to the next level!",
      images: [
        "/assets/items/processed/crushed-stone.webp",
        "/assets/items/processed/fiber1-processed.webp",
        "/assets/items/processed/greendye1-processed.webp",
        "/assets/items/processed/siftedsand1-processed.webp",
        "/assets/items/processed/stick1-processed.webp",
      ],
    },
    {
      title: "Craft Skill",
      text: "Even more refinement? Complex items are found within the Crafting Skill. Just first earn your materials.",
      images: [
        "/assets/items/crafting/fishingrod.webp",
        "/assets/items/crafting/tree-sapling.webp",
        "/assets/items/crafting/rope.webp",
        "/assets/items/crafting/stone-axe.webp",
        "/assets/items/crafting/stone-pickaxe.webp",
      ],
    },
    {
      title: "Fish Skill",
      text: "Want to relax after all those taps and waiting? Fret not, the Fishing Skill allows one to idle while you fish! or fail!",
      images: [
        "/assets/items/fish/ayungin.webp",
        "/assets/items/fish/bangus.webp",
        "/assets/items/fish/biya.webp",
        "/assets/items/fish/tilapia.webp",
        "/assets/items/fish/dalag.webp",
      ],
    },
  ];
  const backgroundImages = [
    // "/assets/landingpage/gatherskill-bg.webp",
    "/assets/landingpage/processskill-bg.webp",
    "/assets/landingpage/craftskill-bg.webp",
    "/assets/landingpage/fishskill-bg.webp",
  ];

  const handleTryNowClick = () => {
    navigate("/login");
  };

  const rotateCards = () => {
    let angle = 0;
    cardsRef.current.forEach((card) => {
      if (card && card.classList.contains("active")) {
        card.style.transform = `translate(-50%, -120vh) rotate(-48deg)`;
      } else if (card) {
        card.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        angle -= 10;
      }
    });
  };

  const adjust = () => {
    let windowWidth = window.innerWidth;
    let left = document.querySelector(".left");
    if (left) {
      left.remove();
      if (windowWidth < 800) {
        stackAreaRef.current?.insertAdjacentElement("beforebegin", left);
      } else {
        stackAreaRef.current?.insertAdjacentElement("afterbegin", left);
      }
    }
  };

  const handleNavClick = (tab) => {
    setActiveTab(tab); // Update the active tab state
    // Close the menu if it's expanded (for mobile view)
    setIsNavExpanded(false);
  };

  // Use useEffect to update the active tab based on the current URL hash
  useEffect(() => {
    const currentHash = window.location.hash.replace("#", "");
    if (currentHash) {
      setActiveTab(currentHash);
    }
  }, []);

  useEffect(() => {
    rotateCards();
    adjust();
    window.addEventListener("resize", adjust);

    const defaultBackgroundImage = "/assets/landingpage/gatherskill-bg.webp";
    const handleScroll = () => {
      const show = window.scrollY > 50;
      setNavBackground(show);

      let proportion = stackAreaRef.current
        ? stackAreaRef.current.getBoundingClientRect().top / window.innerHeight
        : 0;

      if (proportion > 0) {
        // If the user is above the first card, set the background to the default image
        if (skillsRef.current) {
          skillsRef.current.style.backgroundImage = `url(${defaultBackgroundImage})`;
        }
      } else if (proportion <= 0) {
        let n = cardsRef.current.length;
        let index = Math.ceil((proportion * n) / 2);
        index = Math.abs(index) - 1; // Adjusted index to account for card visibility

        cardsRef.current.forEach((card, i) => {
          if (card) {
            card.classList.toggle("active", i <= index);
          }
        });

        // Ensure the background image updates correctly based on card index
        let backgroundImage = defaultBackgroundImage; // Use default image if no condition matches
        if (index >= 0 && index < backgroundImages.length) {
          backgroundImage = backgroundImages[index];
        } else if (index >= backgroundImages.length) {
          // Keep the last background or revert to default based on specific needs
          backgroundImage = backgroundImages[backgroundImages.length - 1];
          // Option 2: Revert to default background (comment out the above line and uncomment below)
          // backgroundImage = "/path/to/default/background.jpg";
        }

        if (skillsRef.current) {
          skillsRef.current.style.backgroundImage = `url(${backgroundImage})`;
        }

        // if (index >= 0 && index < cardContents.length) {
        if (index >= 0) {
          // Adjust the index to start from the second item in cardContents
          // const adjustedIndex = index + 1;
          const adjustedIndex = Math.min(index + 1, cardContents.length - 1);

          // Ensure the adjusted index is within the bounds of the cardContents array
          const activeCardContent = cardContents[adjustedIndex];
          setActiveCardText({
            title: activeCardContent.title,
            text: activeCardContent.text,
            images: activeCardContent.images,
          });
        } else {
          setActiveCardText({
            title: "Gather Skill",
            text: "Is tapping relentlessly your forte? The Gather Skill allows one to gather until you falther!",
            images: [
              "/assets/items/materials/stone1.webp",
              "/assets/items/materials/sand1.webp",
              "/assets/items/materials/leaf1.webp",
              "/assets/items/materials/branch1.webp",
              "/assets/items/materials/dirt1.webp",
            ],
          });
        }
      }

      rotateCards();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", adjust);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log(entry.target.id, entry.isIntersecting);
          if (entry.isIntersecting) {
            setActiveTab(entry.target.id);
          }
        });
      },
      {
        rootMargin: "0px",
        threshold: 0.2, // Adjust this value based on when you want the callback to fire
      }
    );

    if (homeRef.current) observer.observe(homeRef.current);
    if (aboutRef.current) observer.observe(aboutRef.current);
    if (skillsRef.current) observer.observe(skillsRef.current);
    if (infoRef.current) observer.observe(infoRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="landing-page">
        <div className={`header ${navBackground ? "header-scrolled" : ""}`}>
          <img
            src="/assets/logos/hoarding-life_textlogo.webp"
            alt="Hoarder Life RPG Logo"
            className="logo"
          />
          <nav className={isNavExpanded ? "menu expanded" : "menu"}>
            <a
              href="#home"
              onClick={() => handleNavClick("home")}
              className={activeTab === "home" ? "active" : ""}
            >
              Home
            </a>
            <a
              href="#about"
              onClick={() => handleNavClick("about")}
              className={activeTab === "about" ? "active" : ""}
            >
              About
            </a>
            <a
              href="#skills"
              onClick={() => handleNavClick("skills")}
              className={activeTab === "skills" ? "active" : ""}
            >
              Skills
            </a>
            <a
              href="#info"
              onClick={() => handleNavClick("info")}
              className={activeTab === "info" ? "active" : ""}
            >
              Info
            </a>
            <button className="play-now" onClick={handleTryNowClick}>
              Play Now
            </button>
          </nav>
          <button
            className="menu-toggle"
            onClick={() => setIsNavExpanded(!isNavExpanded)}
          >
            ☰
          </button>
        </div>
        <div id="home" ref={homeRef} class="section center-all">
          <h2>Welcome to Hoarding Life RPG</h2>
          <h5>where your hoarding habits commence</h5>
          <button class="play-now-button" onClick={handleTryNowClick}>
            Play Now!
          </button>
        </div>

        <div id="about" ref={aboutRef} class="section about-section">
          <h2>About Hoarding Life</h2>
          <div class="about-container">
            <h5>
              Hoarding Life RPG is a game for all hoarder enthusiasts and also
              casual gamers. This game will allow you to experience the joy of
              seeing your items increase and their respective levels as you
              brave the unknown to unlocking new items. Just like in real life,
              you never know what you unlock and acquire next!
            </h5>
            <div class="icons">
              <img src="/assets/items/materials/stone1.webp" alt="Icon 1" />
              <img src="/assets/items/materials/sand1.webp" alt="Icon 2" />
              <img src="/assets/items/materials/leaf1.webp" alt="Icon 3" />
              <img src="/assets/items/materials/branch1.webp" alt="Icon 4" />
              <img src="/assets/items/crafting/fishingrod.webp" alt="Icon 5" />
              <img src="/assets/items/fish/dalag.webp" alt="Icon 6" />
            </div>
          </div>
        </div>

        <div id="skills" ref={skillsRef} className="section">
          <div ref={stackAreaRef} class="stack-area">
            <div class="left">
              <div className="leftCard">
                <Card
                  sx={{
                    minWidth: 275,
                    borderRadius: "15px",
                    border: "1px solid black",
                    background: "rgba(255,255,255, 0.8)",
                  }}
                >
                  <CardContent>
                    <div className="title">{activeCardText.title}</div>
                    <Typography variant="body2">
                      {activeCardText.text}
                    </Typography>
                    {activeCardText.images && (
                      <div className="cardImage">
                        {activeCardText.images.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
            </div>
            <div class="right">
              <div class="cards">
                {cardContents.map((card, index) => (
                  <div
                    className={`card card-${index + 1}`}
                    ref={(el) => (cardsRef.current[index] = el)}
                    key={index}
                  >
                    {/* <div class="sub">{card.title}</div>
                    <div class="content">
                      <img
                        src={card.image}
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      />
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div id="info" ref={infoRef} class="section info-section">
          <h2>Can you collect them all?</h2>
          <div class="info-container">
            <p>
              With unlimited skills come unlimited items!
              <br />
              Each item varies from unlocking conditions to what they can unlock
              in return. Determine each and every item and hoard with all your
              might! You’ll never know maybe the leaderboards will soon award a
              user an unobtainable item!
            </p>
          </div>
        </div>

        <footer style={{ color: "black", fontWeight: "700" }}>
          <p>&copy; 2024 Hoarding Life RPG. All rights reserved.</p>
        </footer>
      </div>
    </>
  );
}

export default LandingPage;
