import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Signup() {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    const emailInput = e.target.value;
    setEmail(emailInput);
    setEmailError(!validateEmail(emailInput));
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    if (!username) {
      setUsernameError(true);
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/auth/signup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: username, email, password }),
      }
    );

    const data = await response.json();

    if (response.ok) {
      console.log("Signup successful:", data);
      navigate("/login"); // Navigate to login page after successful signup
    } else {
      console.error("Signup failed:", data);
    }
  };

  const inputStyles = {
    root: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "white", // Set the border color
        },
        "&:hover fieldset": {
          borderColor: "#00b0ff", // Set the border color on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#00b0ff", // Set the border color when focused
        },
      },
      "& label.Mui-focused": {
        color: "white", // Set the label color when focused
      },
      "& .MuiInputBase-input": {
        color: "white", // Set the input text color
      },
    },
  };

  return (
    <div
      style={{
        backgroundImage: `url(/assets/landing_page_bg.webp)`,
        backgroundSize: "cover",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          backdropFilter: "blur(20px)",
          border: "2px solid rgba(255, 255, 255, .5)",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              paddingTop: "10px",
              fontFamily: '"Pixelify Sans", sans-serif',
            }}
          />
          <Typography
            component="h1"
            variant="h5"
            sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
          >
            Hoarding Life
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            Join us and start hoarding!
          </Typography>
          <Box component="form" onSubmit={handleSignup} sx={{ mt: 1 }}>
            <TextField
              error={usernameError}
              helperText={usernameError ? "Username is required" : ""}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setUsernameError(false);
              }}
              sx={inputStyles.root}
              // InputLabelProps={{
              //   style: { color: "white" },
              // }}
            />
            <TextField
              error={emailError}
              helperText={emailError ? "Email is incorrect" : ""}
              margin="normal"
              required
              fullWidth
              id="signup-email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              sx={inputStyles.root}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{ color: "white" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={inputStyles.root}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                borderRadius: "6px",
                background: "white",
                color: "black",
              }}
              disabled={!email || emailError || !username}
            >
              Sign Up
            </Button>
            <Button
              fullWidth
              variant="text"
              sx={{ mt: 2, color: "white" }}
              onClick={() => navigate("/login")}
            >
              Already have an account? Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Signup;
