import { useCallback } from "react";
import SecureLS from "secure-ls";

export const fetchItems = (
  setUiState,
  setUserInventory,
  setDataState,
  isInventoryFetched,
  userInventoryRef,
  fetchUserInventory,
  setIsInventoryFetched
) =>
  useCallback(async () => {
    setUiState((prevState) => ({ ...prevState, isLoading: true }));
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userId = userDetails ? userDetails.userId : null;

    if (!userId) {
      console.error("User ID not found in local storage");
      setUiState((prevState) => ({ ...prevState, isLoading: false }));
      return;
    }

    try {
      // Fetch items from the backend
      const manyItemsResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items?userId=${userId}`
      );
      const { items, lockedItems } = await manyItemsResponse.json();

      // Handle user inventory
      if (!isInventoryFetched) {
        const inventoryResponse = await fetchUserInventory(userId);
        const userInventory = inventoryResponse.itemDetails;
        const purchasedItems = inventoryResponse.purchasedItems;

        setUserInventory({ itemDetails: userInventory, purchasedItems });
        setIsInventoryFetched(true);
      }

      const userInventory = userInventoryRef.current.itemDetails;
      const purchasedItems = userInventoryRef.current.purchasedItems;

      // Map user inventory data to the items
      const updatedItems = items.map((itemData) => {
        const userItem = userInventory.find(
          (inventoryItem) =>
            inventoryItem.itemId.toString() === itemData.itemId.toString()
        );
        return {
          ...itemData,
          itemExp: userItem ? userItem.itemExp : 0,
          itemLvl: userItem ? userItem.itemLevel : 1,
          expNeeded: userItem ? userItem.expNeeded : null,
        };
      });

      // Map user inventory data to the locked items
      const updatedLockedItems = lockedItems.map((itemData) => {
        const userItem = userInventory.find(
          (inventoryItem) =>
            inventoryItem.itemId.toString() === itemData.itemId.toString()
        );
        return {
          ...itemData,
          itemExp: userItem ? userItem.itemExp : 0,
          itemLvl: userItem ? userItem.itemLevel : 1,
          expNeeded: userItem ? userItem.expNeeded : null,
        };
      });

      // Save items and lockedItems separately in the items state
      setDataState((prevState) => ({
        ...prevState,
        items: [...updatedItems, { lockedItems: updatedLockedItems }],
      }));

      setUserInventory({ itemDetails: userInventory, purchasedItems });
    } catch (error) {
      console.error("Error while fetching items and user inventory", error);
    } finally {
      setUiState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [
    setUiState,
    setUserInventory,
    setDataState,
    isInventoryFetched,
    userInventoryRef,
    fetchUserInventory,
    setIsInventoryFetched,
  ]);
