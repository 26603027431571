import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Divider,
  useTheme,
} from "@mui/material";

const Sidebar = ({
  tabTitles,
  tabValue,
  handleSidebarTabChange,
  handleLogout,
  logoSize,
  userProfile,
  userRole,
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: theme.spacing(1),
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          width: 240,
        }}
      >
        <img
          src="/assets/logos/hoarding-life_textlogo.webp"
          alt="Hoarding Life Text Logo"
          style={{
            width: `${logoSize}px`,
            maxHeight: "100px",
            margin: "0 auto",
          }}
        />
      </Box>
      <List>
        {tabTitles.map(
          (text, index) =>
            // Only render the "Admin" tab if the user has the "admin" role
            (text !== "Admin" || userRole === "admin") && (
              <ListItemButton
                key={text}
                selected={tabValue === index}
                onClick={() => handleSidebarTabChange(index)}
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  "&.Mui-selected": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.08)",
                    },
                  },
                }}
              >
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    sx: { fontFamily: '"Pixelify Sans", sans-serif' },
                  }}
                />
              </ListItemButton>
            )
        )}
      </List>
      <Divider />
      <Box
        sx={{
          position: "absolute",
          bottom: 70, 
          width: "100%",
          textAlign: "center",
        }}
      >
        <a
          href="https://www.buymeacoffee.com/korozoma"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=korozoma&button_colour=5F7FFF&font_colour=ffffff&font_family=Comic&outline_colour=000000&coffee_colour=FFDD00"
            alt="Buy Me A Coffee"
          />
        </a>
      </Box>
      <List
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogout}
          sx={{ position: "fixed", bottom: 20, left: 65 }}
        >
          Logout
        </Button>
      </List>
    </>
  );
};

export default Sidebar;
