export const formatCountdown = (harvestTime) => {
  const now = new Date();
  const remainingTime = new Date(harvestTime) - now;
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  // Function to pad single-digit values with leading zeros
  const padZero = (value) => (value < 10 ? `0${value}` : value);

  // Return formatted countdown string
  return `${padZero(days)}:${padZero(hours)}:${padZero(minutes)}:${padZero(
    seconds
  )}`;
};
