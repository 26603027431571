import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getExpNeededForNextLevel } from "../../../../System/Levels/locationLevels";

const ForageLocationCard = ({
  location,
  onForage,
  items,
  userInventoryRef,
  userProfileRef,
  forageInventoryRef,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const foragingSkill = userProfileRef.current.skills.find(
    (skill) => skill.skillId === 5
  );

  const forageLocation = userProfileRef.current.locations.find(
    (loc) => loc.locationId === location.locationId
  );

  const expPercentage = forageLocation
    ? (forageLocation.exp /
        getExpNeededForNextLevel(forageLocation.level, location.locationId)) *
      100
    : 0;

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: 360,
        margin: "auto",
        mb: 2,
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: 175,
          height: 175,
          objectFit: "cover",
          display: "block",
          margin: "auto",
          marginLeft: "10px",
        }}
        image={location.imageUrl || "/assets/locations/default.webp"}
        alt={location.locationName}
        onClick={handleClickOpen}
      />
      <CardContent
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 1,
            textAlign: "center",
            fontFamily: '"Pixelify Sans", sans-serif',
          }}
        >
          {location.locationName}
        </Typography>
        <Typography sx={{ mb: 1, textAlign: "center" }}>
          Level: {forageLocation ? forageLocation.level : "N/A"} Exp:{" "}
          {expPercentage.toFixed(2)}%
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onForage(location, items)}
          sx={{ mt: 2, alignSelf: "center" }}
        >
          Forage Here
        </Button>
      </CardContent>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}>
          {location.locationName}
        </DialogTitle>
        <DialogContent>
          <Typography>{location.locationDescription}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ForageLocationCard;
