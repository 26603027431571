// GatherTab.js
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ItemCardsSlider from "./ItemCardsSlider";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

const GatherTab = ({
  items,
  onGather,
  onAutoGather,
  expPercentages,
  purchasedItems,
  onBackToSkills,
  userProfileRef,
  userInventoryRef,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAutoGather, setIsAutoGather] = useState(false);
  const [autoGatheringItems, setAutoGatheringItems] = useState([]);
  const [isAfk, setIsAfk] = useState(false);
  const [afkModePurchased, setAfkModePurchased] = useState(false);

  useEffect(() => {
    const afkModeProduct = "AFK Mode"; // replace with the actual product name in your inventory
    const isPurchased = userInventoryRef.current?.purchasedItems.some(
      (item) =>
        item.productName === afkModeProduct && item.status === "purchased"
    );
    setAfkModePurchased(isPurchased);
  }, [userInventoryRef.current]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm)
  );

  const handleAutoGatherChange = (itemId, isActive) => {
    setAutoGatheringItems((prev) => {
      const newItems = new Set(prev);
      if (isActive) {
        newItems.add(itemId);
      } else {
        newItems.delete(itemId);
      }
      return Array.from(newItems);
    });
  };

  const handleSetAfk = async () => {
    console.log("autoGatheringItems: ", autoGatheringItems);
    try {
      const userId = userProfileRef.current.id;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/users/${userId}/set-afk`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ items: autoGatheringItems }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        setIsAfk(true);
      }

      alert(data.message);
    } catch (error) {
      console.error("Failed to set AFK mode:", error);
    }
  };

  return (
    <div
      style={{
        padding: "0 20px",
        margin: "auto",
        maxWidth: "350px",
        marginBottom: "10px",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h2 style={{ color: "green" }}>Gather</h2>
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Tooltip title={!afkModePurchased ? "Purchase AFK Mode in Store" : ""}>
          <span>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSetAfk}
              disabled={!afkModePurchased || isAfk}
            >
              Set AFK Mode
            </Button>
          </span>
        </Tooltip>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <IconButton onClick={onBackToSkills}>
          <ArrowBack />
        </IconButton>
        <TextField
          label="Search Items"
          variant="outlined"
          margin="normal"
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: "20px" },
          }}
          style={{ width: "266px", marginLeft: "10px" }}
        />
      </div>
      <div style={{ marginTop: "7px" }}>
        {items.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        ) : filteredItems.length > 0 ? (
          <ItemCardsSlider
            items={filteredItems}
            onGather={onGather}
            onAutoGather={onAutoGather}
            onAutoGatherChange={handleAutoGatherChange}
            expPercentages={expPercentages}
            purchasedItems={purchasedItems}
            isAutoGather={isAutoGather}
          />
        ) : (
          <Typography textAlign="center" mt={2}>
            No items available or no match found.
          </Typography>
        )}
      </div>
    </div>
  );
};
export default GatherTab;
