import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Card } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { deepOrange, deepPurple, blue } from "@mui/material/colors";
import "../../../css/leaderboard.css";

export default function LeaderboardTab() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Leaderboard Timer
  const targetTime = new Date("March 31, 2024 23:59:59").getTime();
  const [currentTime, setCurrentTime] = useState(Date.now());

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const [rewardsData, setRewardsData] = useState([
    // Example data structure. You might fetch this data or structure it differently.
    {
      // itemId: "salt Water",
      amount: 50,
      imageUrl: "/assets/items/forage/seed-bag.webp",
    },
    {
      // itemId: "salt Water",
      amount: 25,
      imageUrl: "/assets/items/forage/seed-bag.webp",
    },
    {
      // itemId: "salt Water",
      amount: 10,
      imageUrl: "/assets/items/forage/seed-bag.webp",
    },
    // {
    //   itemId: "16",
    //   amount: 5,
    //   imageUrl: "/assets/items/crafting/fishingrod.webp",
    // },
  ]);

  const calculateRemainingTime = () => {
    const milliseconds = targetTime - currentTime;
    let remaining = {};

    if (milliseconds > 0) {
      remaining.days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
      remaining.hours = Math.floor(
        (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
        .toString()
        .padStart(2, "0");
      remaining.minutes = Math.floor(
        (milliseconds % (1000 * 60 * 60)) / (1000 * 60)
      )
        .toString()
        .padStart(2, "0");
      remaining.seconds = Math.floor((milliseconds % (1000 * 60)) / 1000)
        .toString()
        .padStart(2, "0");
    } else {
      remaining = null;
    }

    return remaining;
  };

  const remaining = calculateRemainingTime();

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/users/leaderboard`
        );
        const data = await response.json();

        setLeaderboardData(data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeaderboard();

    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  const firstUserImageUrl =
    leaderboardData.length > 0
      ? leaderboardData[0].imageUrl
      : "/assets/items/default.webp";

  const filteredLeaderboardData = leaderboardData.filter(
    (user) => user.totalItems > 0
  );

  // Check if there are no players
  const hasNoPlayers =
    leaderboardData.length === 0 || filteredLeaderboardData.length === 0;

  console.log("filteredleaderboard: ", filteredLeaderboardData);
  return (
    <>
      <Box>
        <Typography
          variant="h4"
          align="center"
          sx={{
            marginBottom: "8px",
            fontFamily: '"Pixelify Sans", sans-serif', 
          }}
        >
          {/* This Week's{" "} */}
          Until Farming Update's{" "}
          <img
            src={firstUserImageUrl}
            alt="Item"
            style={{
              verticalAlign: "middle",
              width: "60px",
              height: "60px",
              cursor: "pointer",
            }}
            onClick={toggleDialog}
          />
          Leaderboard
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="20px"
        >
          {remaining ? (
            <>
              <section class="countdown-container">
                <div class="days-container">
                  <div class="days">
                    {remaining.days > 0 ? `${remaining.days} ` : ""}
                  </div>
                  <div class="days-label">days</div>
                </div>

                <div class="hours-container">
                  <div class="hours">{remaining.hours}</div>
                  <div class="hours-label">hours</div>
                </div>

                <div class="minutes-container">
                  <div class="minutes">{remaining.minutes}</div>
                  <div class="minutes-label">minutes</div>
                </div>

                <div class="seconds-container">
                  <div class="seconds">{remaining.seconds}</div>
                  <div class="seconds-label">seconds</div>
                </div>
              </section>
            </>
          ) : (
            <Typography variant="h5" align="center">
              CONGRATULATIONS TO {leaderboardData[0]?.name}
            </Typography>
          )}
        </Box>
        <Card sx={{ backgroundColor: "rgba(255, 255, 255, 0.25)" }}>
          <div className="leaderboard">
            {hasNoPlayers ? (
              <Typography variant="h6" align="center" sx={{ padding: "20px" }}>
                Currently no players
              </Typography>
            ) : (
              <>
                <div className="top-profiles">
                  <div className="profile top-1">
                    {filteredLeaderboardData[0].profileImg ? (
                      <Avatar
                        src={`/assets/profile/${filteredLeaderboardData[0].profileImg}`}
                        alt="Profile"
                        sx={{
                          bgcolor: deepOrange[500],
                          width: 100,
                          height: 100,
                          border: "6px solid gold",
                        }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: deepOrange[500],
                          width: 100,
                          height: 100,
                          border: "6px solid gold",
                        }}
                      >
                        {filteredLeaderboardData[0].initials}
                      </Avatar>
                    )}
                    <div className="user-info">
                      <h3 className="name">
                        {filteredLeaderboardData[0].name}
                      </h3>
                      <span>
                        {filteredLeaderboardData[0].totalItems.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="sub-top-profiles">
                    {filteredLeaderboardData.slice(1, 3).map((user, index) => (
                      <div
                        key={user.id}
                        className={`profile sub-top-${index + 2}`}
                      >
                        {user.profileImg ? (
                          <Avatar
                            src={`/assets/profile/${user.profileImg}`}
                            alt="Profile"
                            sx={{
                              bgcolor: blue[500],
                              width: 80,
                              height: 80,
                              border: `${
                                index === 0
                                  ? "6px solid silver"
                                  : "6px solid #cd7f32"
                              }`,
                            }}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              bgcolor: blue[500],
                              width: 80,
                              height: 80,
                              border: `${
                                index === 0
                                  ? "6px solid silver"
                                  : "6px solid #cd7f32"
                              }`,
                            }}
                          >
                            {user.initials}
                          </Avatar>
                        )}
                        <div className="user-info">
                          <h3 className="name text-dark">{user.name}</h3>
                          <span>{user.totalItems.toLocaleString()}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="board">
                  <div id="profile">
                    {filteredLeaderboardData.slice(3).map((user) => (
                      <div className="flex">
                        <div className="item">
                          {user.profileImg ? (
                            <Avatar
                              src={`/assets/profile/${user.profileImg}`}
                              alt="Profile"
                              sx={{
                                width: 30,
                                height: 30,
                                bgcolor: deepPurple[500],
                              }}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: 30,
                                height: 30,
                                bgcolor: deepPurple[500],
                              }}
                            >
                              {user.initials}
                            </Avatar>
                          )}
                          <div className="info">
                            <h3
                              className="name"
                              style={{ width: "100px", margin: 0 }}
                            >
                              {user.name}
                            </h3>
                            <span>{user.totalItems.toLocaleString()}</span>
                          </div>
                        </div>
                        <div className="item">
                          <span>{user.rank}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </Card>
      </Box>
      <Dialog
        onClose={toggleDialog}
        open={isDialogOpen}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle style={{ fontFamily: '"Pixelify Sans", sans-serif' }}>
          This Week's Rewards
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          New Random Farm Seeds
        </DialogContent>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <table style={{ margin: "0 auto" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Place</th>
                <th style={{ textAlign: "center" }}>Item</th>
                <th style={{ textAlign: "center" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {rewardsData.map((reward, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    {index === 0
                      ? "1st"
                      : index === 1
                      ? "2nd"
                      : index === 2
                      ? "3rd"
                      : `${index + 1}th`}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={reward.imageUrl}
                      alt={`Item ${reward.itemId}`}
                      style={{
                        width: "50px",
                        height: "50px",
                        marginRight: "10px",
                        verticalAlign: "middle",
                      }}
                    />
                    {reward.itemId}{" "}
                  </td>
                  <td style={{ textAlign: "center" }}>{reward.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
      ;
    </>
  );
}
