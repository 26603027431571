import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { getExpForNextSkillLevel } from "../../../System/Levels/skillLevels";

const SkillCard = ({ skill, onNavigateToSkill }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const nextLevelExp = getExpForNextSkillLevel(skill.skillLevel, skill.skillId);
  const progress = nextLevelExp ? (skill.skillExp / nextLevelExp) * 100 : 100;
  const formattedSkillExp = Math.round(skill.skillExp).toLocaleString();
  const formattedNextLevelExp = Math.round(nextLevelExp).toLocaleString();
  const expText = `${formattedSkillExp}/${formattedNextLevelExp} exp`;

  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: "auto",
        margin: "auto",
        mb: 2,
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0.4)",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: 175,
          height: 175,
          objectFit: "contain",
          display: "block",
          margin: "auto",
          marginLeft: "10px",
        }}
        image={skill.skillImage || "/assets/items/default.webp"}
        alt={skill.skillName}
        onClick={handleClickOpen}
      />
      <CardContent
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            mb: 1,
            textAlign: "center",
            fontFamily: '"Pixelify Sans", sans-serif',
          }}
        >
          {skill.skillName}
        </Typography>
        <Typography sx={{ mb: 1, textAlign: "center" }}>
          Level: {skill.skillLevel}
        </Typography>
        <Tooltip title={expText}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ mt: 1, mb: 2, height: 10, borderRadius: 5 }}
          />
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onNavigateToSkill(skill.skillId)}
          sx={{ mt: 2, alignSelf: "center" }}
        >
          Let's Go!
        </Button>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
        aria-labelledby="description-dialog-title"
      >
        <DialogTitle
          id="description-dialog-title"
          sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}
        >
          {skill.skillName}'s Description
        </DialogTitle>
        <DialogContent>
          <Typography>{skill.description}</Typography>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default SkillCard;
