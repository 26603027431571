import React, { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import {
  Box,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Shop from "./Shop";
import Animal from "./Animal";
import AnimalTypeMenu from "./Component/AnimalTypeMenu";

const AnimalTab = ({ onBackToSkills, userInventoryRef, setUserInventory }) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  const [userId, setUserId] = useState("");
  const [actionStatus, setActionStatus] = useState({
    isLoading: false,
    message: "",
  });

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userIdFromStorage = userDetails ? userDetails.userId : null;
    setUserId(userIdFromStorage);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedType(null); // Reset selected type when switching tabs
  };

  const animalTypes = [
    ...new Set(
      userInventoryRef.current.itemDetails
        .filter((item) => item.itemType === "animal")
        .map((animal) => animal.itemName.split(" ")[1])
    ),
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ color: "green" }}>Animal Management</h2>
      </div>
      <IconButton onClick={onBackToSkills}>
        <ArrowBack />
      </IconButton>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Animal" />
        <Tab label="Shop" />
      </Tabs>

      {tabValue === 0 && selectedType === null && (
        <AnimalTypeMenu
          animalTypes={animalTypes}
          userInventoryRef={userInventoryRef}
          setSelectedType={setSelectedType}
          setUserInventory={setUserInventory}
          userId={userId}
        />
      )}

      {tabValue === 0 && selectedType !== null && (
        <Animal
          userInventoryRef={userInventoryRef}
          setUserInventory={setUserInventory}
          selectedType={selectedType}
          userId={userId}
        />
      )}

      {tabValue === 1 && (
        <Shop
          userInventoryRef={userInventoryRef}
          setUserInventory={setUserInventory}
          userId={userId}
        />
      )}

      <Dialog
        open={actionStatus.isLoading}
        onClose={() => setActionStatus({ ...actionStatus, isLoading: false })}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        disableBackdropClick
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogContent style={{ textAlign: "center" }}>
          <CircularProgress style={{ color: "#d2b48c" }} />
          <Typography variant="h6" style={{ marginTop: 16 }}>
            {actionStatus.message}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AnimalTab;
