import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import axios from "axios";

function BatchProcessedDialog({
  batchProcessedDialogOpen,
  setBatchProcessedDialogOpen,
  items,
  userProfileRef,
  localGatherBatch,
  setLocalGatherBatch,
  setGatherBatch,
  userInventoryRef,
}) {
  const findItemDetails = (itemId) =>
    items.find((item) => String(item.itemId) === String(itemId));

  const handleStoreItems = async () => {
    const userId = userProfileRef.current.id;

    try {
      const afkItems = userProfileRef.current.afkDetails.gatherAfkItems;

      // Calculate the total quantity based on the number of AFK items and time passed
      const afkStartTime = new Date(
        userProfileRef.current.afkDetails.gatherAfkStart
      );
      const currentTime = new Date();
      const timePassedInSeconds = Math.floor(
        (currentTime - afkStartTime) / 1000
      );
      const totalQuantity = Math.floor(timePassedInSeconds / 5);

      // Create an array of items with each item and its amount
      const itemIds = afkItems.map((itemId) => [itemId, totalQuantity]);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/afkGather`,
        {
          itemIds: itemIds,
          skillExpGain: 0,
        }
      );

      const updatedItems = response.data.updatedItems;
      const updatedItemDetails = userInventoryRef.current.itemDetails.map(
        (item) => {
          const updatedItem = updatedItems.find((updatedItem) => {
            const updatedItemIdString = String(updatedItem.itemId);
            const itemItemIdString = String(item.itemId);
            return updatedItemIdString === itemItemIdString;
          });
          if (updatedItem) {
            const newAmount = item.quantity + updatedItem.quantity;
            return { ...item, quantity: newAmount };
          } else {
            return item;
          }
        }
      );

      // Update the itemDetails in userInventoryRef
      userInventoryRef.current.itemDetails = updatedItemDetails;
    } catch (error) {
      console.error("Error storing items:", error);
    }

    setBatchProcessedDialogOpen(false);
    setLocalGatherBatch({});
    setGatherBatch({});
  };

  if (userProfileRef.current.afkDetails.isAfk) {
    const afkDetails = userProfileRef.current.afkDetails;
    const afkItems = afkDetails.gatherAfkItems;

    const afkStartTime = new Date(
      userProfileRef.current.afkDetails.gatherAfkStart
    );
    const currentTime = new Date();
    const timePassedInSeconds = Math.floor((currentTime - afkStartTime) / 1000);

    const totalQuantity = Math.floor(timePassedInSeconds / 5);

    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => {}}
        aria-labelledby="batch-process-dialog-title"
        aria-describedby="batch-process-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="batch-process-dialog-title">
          {"AFK Batch Processed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="batch-process-dialog-description">
            Here is the Summary of your AFK Gathering Venture:
          </DialogContentText>
          <List style={{ margin: "auto", textAlign: "center" }}>
            {afkItems.map((item) => {
              const itemDetails = findItemDetails(item);

              return (
                <ListItem key={item.itemId}>
                  <ListItemAvatar>
                    <Avatar
                      src={itemDetails?.imageUrl || "/path/to/default/image"}
                      alt={itemDetails?.name || "Item"}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={itemDetails?.name || "Unknown Item"}
                    secondary={`Quantity: ${totalQuantity.toLocaleString()}`}
                  />
                </ListItem>
              );
            })}

            <Divider />

            <ListItem>
              <ListItemText
                primary={`Total Gathered Items: ${(
                  totalQuantity * afkItems.length
                ).toLocaleString()}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Time Passed: ${Math.floor(
                  timePassedInSeconds / 86400
                )} days, ${Math.floor(
                  (timePassedInSeconds % 86400) / 3600
                )} hours, ${Math.floor(
                  (timePassedInSeconds % 3600) / 60
                )} minutes`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStoreItems} color="primary">
            Store Items
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    let totalQuantity = 0;
    Object.values(localGatherBatch).forEach((quantity) => {
      totalQuantity += quantity;
    });

    const formattedTotalQuantity = totalQuantity.toLocaleString();

    return (
      <Dialog
        open={batchProcessedDialogOpen}
        onClose={() => {
          setBatchProcessedDialogOpen(false);
          setLocalGatherBatch({});
          setGatherBatch({});
        }}
        aria-labelledby="batch-process-dialog-title"
        aria-describedby="batch-process-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="batch-process-dialog-title">
          {"Batch Processed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="batch-process-dialog-description">
            Here is the Summary of your Gathering Venture:
          </DialogContentText>
          <List style={{ margin: "auto", textAlign: "center" }}>
            {Object.entries(localGatherBatch).map(([itemId, quantity]) => {
              const item = findItemDetails(itemId);
              return (
                <ListItem key={itemId}>
                  <ListItemAvatar>
                    <Avatar
                      src={item?.imageUrl || "/path/to/default/image"}
                      alt={item?.name || "Item"}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item?.name || "Unknown Item"}
                    secondary={`Quantity: ${quantity}`}
                  />
                </ListItem>
              );
            })}

            <Divider />

            <ListItem>
              <ListItemText
                primary={`Total Gathered Items: ${formattedTotalQuantity}`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setBatchProcessedDialogOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default BatchProcessedDialog;
