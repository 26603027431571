import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import { useAutoGather } from "../GatherTab/AutoGatherContext";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "../../../../../css/home.css";

const ProcessItemCard = ({
  item,
  onProcess,
  gatherItems,
  userInventory,
  setUserInventory,
  processItems,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [processAmount, setProcessAmount] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentProcess, setCurrentProcess] = useState(0);
  const [totalProcesses, setTotalProcesses] = useState(0);
  const [hasProcessed, setHasProcessed] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { processingProgress, updateProcessingProgress } = useAutoGather();
  const [infoDialog, setInfoDialog] = useState(false);
  const inventoryUpdatedRef = useRef(false);

  const matchingItem = userInventory.itemDetails.find(
    (itemDetail) => itemDetail.itemId.toString() === item.itemId.toString()
  );

  const {
    updateProcessingState,
    setProcessingInterval,
    clearProcessingInterval,
  } = useAutoGather();

  useEffect(() => {
    // Check if there's existing progress in context and set it
    if (processingProgress[item.itemId] !== undefined) {
      setCurrentProcess(processingProgress[item.itemId]);
      setIsProcessing(processingProgress[item.itemId] < 100);
    }
  }, [item.itemId, processingProgress]);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);
  const toggleInfoDialog = () => setInfoDialog(!isDialogOpen);

  // Function to find item name and amount by ID
  const findItemDetailsById = (id) => {
    let foundItem = gatherItems.find((item) => item.itemId === id);

    if (!foundItem) {
      foundItem = processItems.find((item) => item.itemId === id);
    }

    const userItem = userInventory.itemDetails.find(
      (invItem) => invItem.itemId === id.toString()
    );

    return {
      name: foundItem ? foundItem.name : "Unknown Item",
      imageUrl: foundItem ? foundItem.imageUrl : "/assets/items/default.webp",
      userAmount: userItem ? userItem.quantity : 0,
    };
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setHasProcessed(false);
  };
  const handleCloseDialog = () => setOpenDialog(false);
  const handleOpenInfoDialog = () => setInfoDialog(true);
  const handleCloseInfoDialog = () => setInfoDialog(false);

  const getMaxProcessAmount = () => {
    return Math.min(
      ...item.itemProcess.map((process) => {
        const { userAmount } = findItemDetailsById(process.itemId);
        return Math.floor(userAmount / process.itemAmount);
      })
    );
  };

  const handleChangeAmount = (event) => {
    let amount =
      event.target.value === "" ? "" : parseInt(event.target.value, 10);

    // Automatically adjust to the maximum processable amount if entered amount is too high
    const maxProcessableAmount = getMaxProcessAmount();
    if (amount > maxProcessableAmount) {
      amount = maxProcessableAmount;
    }

    setProcessAmount(amount);
    setHasProcessed(false);
  };

  const isConfirmDisabled = () => {
    if (processAmount === "" || processAmount === 0) {
      return true;
    }

    // Disable if processAmount * itemAmount exceeds userAmount for any process item
    return item.itemProcess.some((process) => {
      const { userAmount } = findItemDetailsById(process.itemId);
      return processAmount * process.itemAmount > userAmount;
    });
  };

  const handleConfirmProcess = () => {
    const maxAmount = getMaxProcessAmount();

    // Ensure we do not attempt to process more than we have materials for
    const amountToProcess = Math.min(processAmount, maxAmount);

    // Check if the user has enough materials for the requested process amount
    const hasMaterials = item.itemProcess.every((process) => {
      const { userAmount } = findItemDetailsById(process.itemId);
      return amountToProcess * process.itemAmount <= userAmount;
    });

    if (!hasMaterials) {
      console.error("Not enough materials to process this amount.");
      // Optionally, add a user notification here to inform them they don't have enough materials
      return;
    }

    // Deduct materials from inventory upfront
    item.itemProcess.forEach((process) => {
      const inventoryItem = userInventory.itemDetails.find(
        (invItem) => invItem.itemId === process.itemId.toString()
      );
      if (inventoryItem) {
        inventoryItem.quantity -= process.itemAmount * amountToProcess;
      }
    });

    setIsProcessing(true);
    setTotalProcesses(amountToProcess);
    setCurrentProcess(0);
    onProcess(item.itemId, amountToProcess);
    updateProcessProgress(amountToProcess);
    handleCloseDialog();
    updateProcessingState(item.itemId, true);

    // Setup the processing interval
    const interval = updateProcessProgress(amountToProcess);
    setProcessingInterval(item.itemId, interval);
  };

  // Call this function after each processing step is complete
  const updateProcessProgress = (totalProcesses) => {
    const totalTime = item.processTime * totalProcesses * 1000;
    let progressIncrement = totalTime === 0 ? 0 : (100 / totalTime) * 100;

    clearProcessingInterval(item.itemId);

    inventoryUpdatedRef.current = false; // Reset the ref

    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += progressIncrement;
        setCurrentProcess(progress);
        updateProcessingProgress(item.itemId, progress);
      } else {
        if (!inventoryUpdatedRef.current) {
          console.log(
            "Updating inventory for item:",
            item.itemId,
            "Total processes:",
            totalProcesses
          );

          // Decrement materials
          // item.itemProcess.forEach((process) => {
          //   const materialInventoryItem = userInventory.itemDetails.find(
          //     (invItem) => invItem.itemId === process.itemId.toString()
          //   );
          //   if (materialInventoryItem) {
          //     console.log("Before update:", materialInventoryItem.quantity);
          //     materialInventoryItem.quantity -=
          //       process.itemAmount * totalProcesses;
          //     console.log("After update:", materialInventoryItem.quantity);
          //   }
          // });

          // Increment processed item
          const processedItem = userInventory.itemDetails.find(
            (invItem) => invItem.itemId === item.itemId.toString()
          );
          if (processedItem) {
            console.log("Before process update:", processedItem.quantity);
            processedItem.quantity += 1 * totalProcesses;
            console.log("After process update:", processedItem.quantity);
          } else {
            // If the processed item doesn't exist in the inventory, add it
            userInventory.itemDetails.push({
              itemId: item.itemId.toString(),
              quantity: 1 * totalProcesses, // Adjust as needed
              // ...other necessary properties
            });
          }

          inventoryUpdatedRef.current = true;
        }

        clearInterval(interval);
        setIsProcessing(false);
        setCurrentProcess(0);
        updateProcessingState(item.itemId, false);
        updateProcessingProgress(item.itemId, 100);
      }
    }, 100);

    setProcessingInterval(item.itemId, interval);
  };

  return (
    <Card
      sx={{ maxWidth: 345, m: 1, backgroundColor: "rgba(255, 255, 255, 0.4)" }}
    >
      <CardHeader
        title={item.name}
        subheader={`Level: ${matchingItem ? matchingItem.itemLevel : 0} Exp: ${
          item.itemExp ? ((item.itemExp / item.expNeeded) * 100).toFixed(2) : 0
        }%`}
        action={
          <>
            <IconButton onClick={handleOpenInfoDialog}>
              <InfoIcon style={{ color: "gray" }} />
            </IconButton>
            <Dialog
              open={infoDialog}
              onClose={handleCloseInfoDialog}
              PaperProps={{
                sx: {
                  border: "2px solid rgba(173, 216, 230)",
                  borderRadius: "8px",
                },
              }}
            >
              <div
                style={{
                  margin: "10px",
                }}
              >
                <Typography variant="subtitle1" gutterBottom>
                  Requirements:
                </Typography>
                <List style={{ padding: 0 }}>
                  {item.itemProcess.map((process, index) => {
                    const { name, imageUrl, userAmount } = findItemDetailsById(
                      process.itemId
                    );
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <Avatar src={imageUrl} alt={name} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${name}: ${process.itemAmount}`}
                          secondary={`(${userAmount})`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <DialogActions>
                <Button onClick={handleCloseInfoDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        }
      />
      <CardMedia
        component="img"
        sx={{
          width: 190,
          maxWidth: 190,
          height: 190,
          maxHeight: 190,
          objectFit: "contain",
          display: "block",
          margin: "auto",
          textAlign: "center",
        }}
        image={item.imageUrl || "/assets/items/default.webp"}
        alt={item.name}
        onClick={toggleDialog}
      />
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ width: "100%", mt: 0.15 }}
        >
          {isProcessing ? (
            <Box sx={{ position: "relative", width: "100%", height: "40px" }}>
              <LinearProgress
                variant="determinate"
                value={currentProcess}
                sx={{ width: "100%", height: "100%" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ color: "white" }}
                >{`${Math.round(currentProcess)}%`}</Typography>
              </Box>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDialog}
              sx={{ width: "100%", height: "40px" }}
            >
              Process
            </Button>
          )}
        </Box>
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Process How Many?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="processAmount"
            label="Amount to Process"
            type="number"
            fullWidth
            variant="standard"
            value={processAmount}
            onChange={handleChangeAmount}
            inputProps={{ min: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmProcess} disabled={isConfirmDisabled()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for displaying item details */}
      <Dialog
        open={isDialogOpen}
        onClose={toggleDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ fontFamily: '"Pixelify Sans", sans-serif' }}>
          {item.name}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{item.description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProcessItemCard;
