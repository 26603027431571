// services/userService.js

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const fetchUserProfile = async (userId) => {
    try {
        const response = await fetch(`${BASE_URL}/v1/api/users/${userId}`);
        if (!response.ok) {
            throw new Error('Failed to fetch user data');
        }
        return await response.json();
    } catch (error) {
        console.error("Error while fetching user data", error);
        throw error;
    }
};
