import React, { createContext, useContext, useRef, useState } from "react";

const GatherCountContext = createContext();

export const useGatherCount = () => useContext(GatherCountContext);

export const GatherCountProvider = ({ children }) => {
  const localGatherCountRef = useRef({});
  const [gatherCounts, setGatherCounts] = useState({});

  // Function to update counts
  const updateGatherCounts = (itemId, increment = 1) => {
    if (itemId == null) return; // Avoid updating if itemId is null or undefined
    const currentCount = localGatherCountRef.current[itemId] || 0;
    const newCount = currentCount + increment;
    localGatherCountRef.current[itemId] = newCount;

    setGatherCounts((prev) => ({
      ...prev,
      [itemId]: newCount,
    }));
  };

  return (
    <GatherCountContext.Provider
      value={{ localGatherCountRef, updateGatherCounts }}
    >
      {children}
    </GatherCountContext.Provider>
  );
};
