import axios from "axios";
import SecureLS from "secure-ls";
import { useCallback } from "react";
import {
  skillLevels,
  getExpForNextSkillLevel,
  levelUpSkill,
} from "../../System/Levels/skillLevels";

// Functions are now set up to return useCallback hooks
export const handleGather = (
  accumulatedSkillExp,
  setAccumulatedSkillExp,
  userProfile,
  userProfileRef,
  setUserState,
  fetchUserProfile,
  items,
  expNeededData,
  inventory,
  setInventory,
  localInventory,
  setLocalInventory,
  setGatherBatch,
  calculateAndUpdateExpPercentages,
  addNotification,
  isInventoryFetched,
  setIsInventoryFetched,
  userInventoryRef,
  inventoryItemRef,
  isRequestInProgress,
  itemLevels,
  setDataState,
  getExpForNextLevel,
  gatheredItems,
  setGatheredItems
) =>
  useCallback(
    async (itemId, isAutoGather) => {
      try {
        const ls = new SecureLS({ isCompression: false });
        const userDetails = ls.get("userDetails");
        const userId = userDetails ? userDetails.userId : null;

        if (!userId) {
          console.error("User ID not found in local storage");
          return;
        }

        if (!userProfile) {
          const fetchedUserProfile = await fetchUserProfile(userId);

          if (fetchedUserProfile) {
            setUserState((prevState) => ({
              ...prevState,
              userProfile: fetchedUserProfile,
            }));
          } else {
            console.error("Failed to fetch user profile");
            return;
          }
        }

        const gatheredItem = items.find(
          (item) => item.itemId.toString() === itemId.toString()
        );

        if (!gatheredItem) {
          console.error("Gathered item not found in items list");
          return;
        }

        if (!inventoryItemRef.current) {
          inventoryItemRef.current = userInventoryRef.current?.itemDetails.find(
            (item) => item.itemId === itemId.toString()
          );
        }

        // Use the inventoryItem from useRef
        const inventoryItem = inventoryItemRef.current;

        const gatherUpdateFunction = (prevCounts) => {
          const currentCount = prevCounts[itemId] || 0;
          return {
            ...prevCounts,
            [itemId]: currentCount + 1, // Correctly increment the count
          };
        };

        if (!isAutoGather) {
          setGatherBatch(gatherUpdateFunction);

          if (!inventoryItemRef.current) {
            // Check if a request is already in progress
            if (isRequestInProgress.current) {
              console.log("A request is already in progress.");
              return;
            }

            try {
              isRequestInProgress.current = true;
              const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/hoard/${itemId}`
              );
              const { inventoryItem: newItem } = response.data;

              // Update local state with the new item
              setUserInventory({
                ...userInventory,
                itemDetails: [...itemDetails, newItem],
              });
              inventoryItemRef.current = newItem;
            } catch (error) {
              console.error("Error adding item to inventory:", error);
              return;
            } finally {
              // Reset the request flag after the request is complete
              isRequestInProgress.current = false;
            }
          }
        } else {
          // Auto-gather logic remains the same
          setGatherBatch(gatherUpdateFunction);
        }

        const itemCurrentLevel = inventoryItem.itemLevel || 1;

        const levelInfo = itemLevels[String(itemId)]?.find(
          (level) => level.level === itemCurrentLevel
        );

        if (!levelInfo) {
          console.error(
            `Level info not found for item ID ${itemId} at level ${itemCurrentLevel}`
          );
          return;
        }

        const gatheringSkillId = 1;
        const skill = userProfile.skills.find(
          (skill) => skill.skillId === gatheringSkillId
        );

        if (skill) {
          const skillExpToAdd = levelInfo.expGain;
          let skillUpdated = false; // Flag to check if skill was updated

          // Directly manipulate userProfileRef for real-time updates
          userProfileRef.skills = userProfileRef.current.skills.map((s) => {
            if (s.skillId === gatheringSkillId) {
              const updatedSkill = { ...s };
              updatedSkill.skillExp += skillExpToAdd;

              // Check if the skill needs to level up and update accordingly
              while (
                updatedSkill.skillExp >=
                getExpForNextSkillLevel(
                  updatedSkill.skillLevel,
                  gatheringSkillId
                )
              ) {
                levelUpSkill(updatedSkill); // Assuming levelUpSkill mutates the skill object to increment level and adjust exp
                skillUpdated = true; // Mark skill as updated
              }

              return updatedSkill;
            }
            return s;
          });

          if (skillUpdated) {
            // If skill was updated, reflect this change in the application's state
            setUserState((prevState) => ({
              ...prevState,
              userProfile: { ...userProfileRef.current },
            }));
          }

          // Update accumulatedSkillExp state
          setAccumulatedSkillExp((prevExp) => prevExp + skillExpToAdd);
        }

        const updatedGatherCount = isAutoGather
          ? (gatherBatch[itemId] || 0) + 1
          : (gatheredItems[itemId] || 0) + 1;

        if (isAutoGather) {
          // Auto-gather logic
          setGatherBatch(gatherUpdateFunction);

          addNotification({
            id: Date.now(),
            name: gatheredItem.name,
            imageUrl: gatheredItem.imageUrl,
          });
        } else {
          const currentExpGain = levelInfo.expGain;
          userProfileRef.current.exp =
            (userProfileRef.current.exp || 0) + currentExpGain;
          const newExp = userProfileRef.current.exp;
          const nextLevelExp = getExpForNextLevel(userProfileRef.current.level);
          if (newExp >= nextLevelExp) {
            userProfileRef.current.level += 1;
          }

          setUserState((prevState) => ({
            ...prevState,
            userProfile: { ...userProfileRef.current },
          }));

          // Increment quantity in gatheredItems
          const updatedGatheredItems = { ...gatheredItems };
          updatedGatheredItems[itemId] =
            (updatedGatheredItems[itemId] || 0) + 1;

          // Increment quantity in local inventory
          let incrementedQuantity = 1;
          if (localInventory[itemId]) {
            incrementedQuantity = localInventory[itemId] + 1;
          }

          setLocalInventory({
            ...localInventory,
            [itemId]: incrementedQuantity,
          });

          const updatedItems = items.map((item) => {
            if (item.itemId.toString() === itemId.toString()) {
              const newQuantity =
                (inventoryItem.quantity || 0) + incrementedQuantity;
              return {
                ...item,
                itemExp: (item.itemExp || 0) + gatheredItem.expGain,
                quantity: newQuantity,
              };
            }
            return item;
          });

          setDataState((prevState) => ({ ...prevState, items: updatedItems }));

          const updatedGatherCount = (gatheredItems[itemId] || 0) + 1;

          // Manual gather logic
          setGatheredItems(gatherUpdateFunction);

          // setGatherBatch(gatherUpdateFunction);

          const expPercentage = nextLevelExp
            ? ((newExp / nextLevelExp) * 100).toFixed(2)
            : 0;

          addNotification({
            id: Date.now(),
            name: gatheredItem.name,
            imageUrl: gatheredItem.imageUrl,
            expPercentage: expPercentage,
          });

          await calculateAndUpdateExpPercentages(items, gatheredItem);
        }
      } catch (error) {
        console.error("Error handling gather:", error);
      }
    },
    [
      userProfile,
      userProfileRef,
      setUserState,
      fetchUserProfile,
      items,
      expNeededData,
      inventory,
      setInventory,
      localInventory,
      setLocalInventory,
      setGatherBatch,
      calculateAndUpdateExpPercentages,
      addNotification,
      isInventoryFetched,
      setIsInventoryFetched,
      userInventoryRef,
      inventoryItemRef,
      gatheredItems,
      setDataState,
      setGatheredItems,
      accumulatedSkillExp,
      setAccumulatedSkillExp,
    ]
  );

export const processGatherBatch = (
  accumulatedSkillExp,
  setAccumulatedSkillExp,
  gatherBatch,
  resetGatheredItems,
  setInventory,
  setIsInventoryFetched,
  setLocalInventory,
  setBatchProcessedDialogOpen,
  fetchUserInventory,
  gatheredItems,
  isBatchProcessing,
  setGatherBatch,
  setShouldFetchInventory,
  userInventoryRef,
  setUserInventory
) =>
  useCallback(async () => {
    if (Object.keys(gatherBatch).length === 0 || isBatchProcessing.current) {
      return;
    }

    isBatchProcessing.current = true;

    // Retrieve user details from SecureLS
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userId = userDetails ? userDetails.userId : null;

    if (!userId) {
      console.error("User ID not found in local storage");
      isBatchProcessing.current = false;
      return;
    }

    // Log the gatherBatch to check the itemId values
    console.log("gatherBatch:", gatherBatch);

    // Aggregate item amounts in the batch
    // const aggregatedBatch = {};
    // Object.entries({ ...gatherBatch, ...gatheredItems }).forEach(
    //   ([itemId, amount]) => {
    //     const itemIdInt = parseInt(itemId);
    //     if (aggregatedBatch[itemIdInt]) {
    //       aggregatedBatch[itemIdInt] += amount;
    //     } else {
    //       aggregatedBatch[itemIdInt] = amount;
    //     }
    //   }
    // );

    // Convert aggregatedBatch to an array of objects
    const batchItems = Object.entries(gatherBatch).map(([itemId, amount]) => ({
      itemId: parseInt(itemId),
      amount,
    }));

    // Log the data being sent to the endpoint
    console.log("Data to be sent to the endpoint:", batchItems);

    // API call to process the batch
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/batchGather`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            itemIds: batchItems,
            skillExpGain: accumulatedSkillExp,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Batch processed successfully:", data);
        const updatedUserInventory = await fetchUserInventory(userId);
        userInventoryRef.current = updatedUserInventory;
        setShouldFetchInventory(true);
        setInventory(updatedUserInventory);
        setUserInventory;
        setIsInventoryFetched(false);
        setLocalInventory({});
        setAccumulatedSkillExp(0);
        setBatchProcessedDialogOpen(true);
        resetGatheredItems();
        setGatherBatch({});
      } else {
        console.error("Failed to process batch:", data);
      }
    } catch (error) {
      console.error("Batch processing error:", error);
    } finally {
      isBatchProcessing.current = false;
    }
  }, [
    gatherBatch,
    accumulatedSkillExp,
    setGatherBatch,
    setAccumulatedSkillExp,
    isBatchProcessing,
  ]);

export const handleAutoGather = (
  accumulatedSkillExp,
  setAccumulatedSkillExp,
  autoGathering,
  setAutoGathering,
  processGatherBatch,
  localGatherCountRef,
  handleGather,
  setGatherBatch,
  items,
  addNotification,
  userProfile,
  userProfileRef,
  setUserState,
  getExpForNextLevel,
  itemLevels,
  inventoryItemRef,
  userInventoryRef
) =>
  useCallback(
    (itemId) => {
      if (!localGatherCountRef.current) {
        localGatherCountRef.current = {};
      }

      const inventoryItem = userInventoryRef.current.itemDetails.find(
        (item) => item.itemId === itemId.toString()
      );
      const itemCurrentLevel = inventoryItem ? inventoryItem.itemLevel : 1;
      const levelInfo = itemLevels[String(itemId)]?.find(
        (level) => level.level === itemCurrentLevel
      );

      if (autoGathering[itemId]) {
        // Stop the auto-gathering
        clearInterval(autoGathering[itemId]);

        // Save the current localGatherCount to gatherBatch before stopping
        setGatherBatch((prevBatch) => {
          const currentBatchCount = prevBatch[itemId] || 0;
          const localCount = localGatherCountRef.current[itemId] || 0;
          return {
            ...prevBatch,
            [itemId]: currentBatchCount + localCount, // Correctly add the local count
          };
        });

        // Reset the local counter
        localGatherCountRef.current[itemId] = 0;

        // Update autoGathering state to remove this itemId
        setAutoGathering((prev) => {
          const newAutoGathering = { ...prev };
          delete newAutoGathering[itemId];
          return newAutoGathering;
        });

        console.log(`Auto-gather stopped for item ${itemId}`);
      } else {
        localGatherCountRef.current[itemId] = 0; // Initialize the count for this item

        // Start auto-gathering
        const intervalId = setInterval(() => {
          localGatherCountRef.current[itemId]++;
          const gatheredItem = items.find(
            (item) => item.itemId.toString() === itemId.toString()
          );

          if (gatheredItem) {
            if (levelInfo) {
              const currentExpGain = levelInfo.expGain;

              // Accumulate skill experience
              const gatheringSkillId = 1;
              const skill = userProfileRef.current.skills.find(
                (skill) =>
                  skill.skillId.toString() === gatheringSkillId.toString()
              );
              if (skill) {
                setAccumulatedSkillExp((prevExp) => prevExp + currentExpGain);

                // Check if the skill needs to level up
                while (
                  skill.skillExp >=
                  getExpForNextSkillLevel(skill.skillLevel, gatheringSkillId)
                ) {
                  levelUpSkill(skill);
                }
              }
            }

            if (!levelInfo) {
              console.error(
                `Level info not found for item ID ${itemId} at level ${itemCurrentLevel}`
              );
              return;
            }

            const currentExpGain = levelInfo.expGain;

            if (!inventoryItemRef.current) {
              inventoryItemRef.current =
                userInventoryRef.current?.itemDetails.find(
                  (item) => item.itemId === itemId.toString()
                );
            }

            const newUserProfile = { ...userProfileRef };
            const currentUserExp = newUserProfile.exp || 0;
            newUserProfile.exp = currentUserExp + currentExpGain;

            // Fetch the latest userExp after updating
            const updatedUserProfileExp = newUserProfile.exp;

            const nextLevelExp = getExpForNextLevel(userProfile?.level);

            const expPercentage = nextLevelExp
              ? ((updatedUserProfileExp / nextLevelExp) * 100).toFixed(2)
              : 0;

            // Update userExp and itemExp
            setUserState((prevState) => {
              const newUserProfile = { ...prevState.userProfileRef };
              gatheredItem.itemExp =
                (gatheredItem.itemExp || 0) + currentExpGain;
              newUserProfile.exp;
              return {
                ...prevState,
                userProfile: newUserProfile,
              };
            });

            setUserState((prevState) => ({
              ...prevState,
              userProfile: newUserProfile,
            }));

            addNotification({
              id: Date.now(),
              name: gatheredItem.name,
              imageUrl: gatheredItem.imageUrl,
              expPercentage: "Auto...",
            });
          }
        }, 1000);

        setAutoGathering((prev) => ({ ...prev, [itemId]: intervalId }));
      }
    },
    [
      handleGather,
      autoGathering,
      setAutoGathering,
      processGatherBatch,
      localGatherCountRef,
      setGatherBatch,
      items,
      addNotification,
      userProfile,
      setUserState,
      getExpForNextLevel,
      itemLevels,
      inventoryItemRef,
      userInventoryRef,
      accumulatedSkillExp,
      setAccumulatedSkillExp,
    ]
  );
