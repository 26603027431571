import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Tabs,
  Tab,
  Divider,
  Typography,
} from "@mui/material";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { useNotificationsContext } from "../../System/Components/Notification/NotificationContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function AddItemForm() {
  const [tabValue, setTabValue] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Add Item States
  const [itemId, setItemId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [level, setLevel] = useState("");
  const [itemType, setItemType] = useState("");
  const [expGain, setExpGain] = useState("");
  const [preqItemId, setPreqItemId] = useState("");
  const [preqItemLvl, setPreqItemLvl] = useState("");
  const [preqSkillId, setPreqSkillId] = useState("");
  const [preqSkillLevel, setPreqSkillLevel] = useState("");

  // Processable Item States
  const [processItemId, setProcessItemId] = useState("");
  const [processName, setProcessName] = useState("");
  const [processDescription, setProcessDescription] = useState("");
  const [processValue, setProcessValue] = useState("");
  const [processLevel, setProcessLevel] = useState("");
  const [processExpGain, setProcessExpGain] = useState("");
  const [processItemType, setProcessItemType] = useState("");
  const [processImageUrl, setProcessImageUrl] = useState("");
  const [processStatus, setProcessStatus] = useState("");
  const [itemProcesses, setItemProcesses] = useState([]);
  const [itemPreqs, setItemPreqs] = useState([]);
  const [processMaterialItemId, setProcessMaterialItemId] = useState("");
  const [processMaterialItemAmount, setProcessMaterialItemAmount] =
    useState("");
  const [processItemProbability, setProcessItemProbability] = useState("");

  // Shop Product States
  const [productName, setProductName] = useState("");
  const [productValue, setProductValue] = useState("");
  const [productImg, setProductImg] = useState("");
  const [shopProducts, setShopProducts] = useState([]);
  const [shopCurrency, setShopCurrency] = useState([]);

  // Skill States
  const [skillId, setSkillId] = useState("");
  const [skillName, setSkillName] = useState("");
  const [skillDescription, setSkillDescription] = useState("");
  const [skillLevel, setSkillLevel] = useState("");
  const [skillExp, setSkillExp] = useState("");
  const [skillImage, setSkillImage] = useState("");
  const [skillStatus, setSkillStatus] = useState("");
  const [skillPreqId, setSkillPreqId] = useState("");
  const [skillPreqLevel, setSkillPreqLevel] = useState("");

  // Location States
  const [locationId, setLocationId] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationDescription, setLocationDescription] = useState("");
  const [locationLevel, setLocationLevel] = useState("");
  const [locationExpNeeded, setLocationExpNeeded] = useState("");
  const [locationImageUrl, setLocationImageUrl] = useState("");
  const [locationPreqs, setLocationPreqs] = useState([]);
  const [locationPreqItemId, setLocationPreqItemId] = useState("");
  const [locationPreqItemLvl, setLocationPreqItemLvl] = useState("");
  const [locationPreqSkillId, setLocationPreqSkillId] = useState("");
  const [locationPreqSkillLevel, setLocationPreqSkillLevel] = useState("");
  const [locationImageBGUrl, setLocationImageBGUrl] = useState("");
  const [fishItemIdsInput, setFishItemIdsInput] = useState("");
  const [fishItemIds, setFishItemIds] = useState([]);
  const [locTag, setLocTag] = useState("");

  // Farm Seed Item States
  const [harvestDays, setHarvestDays] = useState(0);
  const [harvestHours, setHarvestHours] = useState(0);
  const [harvestMinutes, setHarvestMinutes] = useState(0);
  const [wiltDays, setWiltDays] = useState(0);
  const [wiltHours, setWiltHours] = useState(0);
  const [wiltMinutes, setWiltMinutes] = useState(0);
  const [harvestProduct, setHarvestProduct] = useState("");
  const [wiltProduct, setWiltProduct] = useState("");
  const [typeOfWater, setTypeOfWater] = useState("");
  const [waterAmount, setWaterAmount] = useState(1);
  const [minAmountProbability, setMinAmountProbability] = useState("");
  const [maxAmountProbability, setMaxAmountProbability] = useState("");
  const [bonusProducts, setBonusProducts] = useState([]);

  // Animal Item States
  const [affectionLvl, setAffectionLvl] = useState("");
  const [lifeStage, setLifeStage] = useState("");
  const [adultAnimalId, setAdultAnimalId] = useState("");
  const [affectionProduce, setAffectionProduce] = useState([]);
  const [feedType, setFeedType] = useState("");
  const [feedAmount, setFeedAmount] = useState("");
  const [timeToAdult, setTimeToAdult] = useState("");
  const [affectionProduceLevel, setAffectionProduceLevel] = useState("");
  const [affectionProduceId, setAffectionProduceId] = useState("");
  const [affectionProduceProbability, setAffectionProduceProbability] =
    useState("");
  const [butcherIds, setButcherIds] = useState("");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSnackbarOpen = (message, severity = "info") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handlers for shopProducts
  const handleAddShopProductItem = () => {
    setShopProducts([
      ...shopProducts,
      { itemId: "", amount: "", itemImage: "" },
    ]);
  };

  const handleShopProductChange = (index, field, value) => {
    const updatedProducts = [...shopProducts];
    updatedProducts[index][field] = value;
    setShopProducts(updatedProducts);
  };

  // Handlers for shopCurrency
  const handleAddShopCurrencyItem = () => {
    setShopCurrency([
      ...shopCurrency,
      { itemId: "", amount: "", itemImage: "" },
    ]);
  };

  const handleShopCurrencyChange = (index, field, value) => {
    const updatedCurrencies = [...shopCurrency];
    updatedCurrencies[index][field] = value;
    setShopCurrency(updatedCurrencies);
  };

  const handleAddShopProduct = async (e) => {
    e.preventDefault();
    // Updated shopProduct object to include shopProducts and shopCurrency
    const shopProduct = {
      productName,
      value: productValue,
      productImg,
      shopProducts, // Include the array of shop products
      shopCurrency, // Include the array of shop currencies
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/products/new`, // Make sure this matches your actual API endpoint
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Include any other necessary headers, such as authorization tokens
          },
          body: JSON.stringify(shopProduct),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Shop product added:", data);
        handleSnackbarOpen("Shop product added successfully", "success");
        // Optionally reset form fields or provide further user feedback
        setProductName("");
        setProductValue("");
        setProductImg("");
        setShopProducts([]); // Reset shopProducts
        setShopCurrency([]); // Reset shopCurrency
      } else {
        console.error("Failed to add shop product:", data.message);
        handleSnackbarOpen("Failed to add shop product", "error");
        // Handle failure, possibly showing an error message to the user
      }
    } catch (error) {
      console.error("Error adding shop product:", error);
      handleSnackbarOpen("Failed to add shop product", "error");
      // Handle error, possibly by showing an error message to the user
    }
  };

  const handleAddSkill = async (e) => {
    e.preventDefault();

    const skill = {
      skillId,
      skillName,
      description: skillDescription,
      skillLevel,
      skillExp: 0,
      skillImage,
      skillStatus: "locked",
      skillPreq:
        skillPreqId && skillPreqLevel
          ? { skillId: skillPreqId, requiredLevel: skillPreqLevel }
          : undefined,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/skills/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(skill),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Skill added:", data);
        handleSnackbarOpen("Skill successfully added", "success");
        // Reset form fields or handle success
      } else {
        console.error("Failed to add skill:", data);
        handleSnackbarOpen("Failed to add skill", "error");
        // Handle failure
      }
    } catch (error) {
      console.error("Error adding skill:", error);
      handleSnackbarOpen("Failed to add skill", "error");
    }
  };

  const handleAddProcessItem = async (e) => {
    e.preventDefault();

    const processItem = {
      itemId: processItemId,
      name: processName,
      description: processDescription,
      value: processValue,
      level: processLevel,
      expGain: processExpGain,
      itemType: processItemType,
      imageUrl: processImageUrl,
      status: processStatus,
      itemProcess: itemProcesses,
      itemPreq: itemPreqs,
      probability: parseFloat(processItemProbability),
      amountMin: parseFloat(minAmountProbability),
      amountMax: parseFloat(maxAmountProbability),
      ...((harvestDays ||
        harvestHours ||
        harvestMinutes ||
        wiltDays ||
        wiltHours ||
        wiltMinutes ||
        harvestProduct ||
        wiltProduct ||
        typeOfWater ||
        waterAmount) && {
        itemFarm: {
          dateToHarvest: {
            days: harvestDays,
            hours: harvestHours,
            minutes: harvestMinutes,
          },
          dateToWilt: {
            days: wiltDays,
            hours: wiltHours,
            minutes: wiltMinutes,
          },
          harvestProduct,
          wiltProduct,
          typeOfWater,
          waterAmount,
          bonusProducts,
        },
      }),
      ...((affectionLvl ||
        lifeStage ||
        affectionProduce.length ||
        feedType ||
        feedAmount ||
        timeToAdult ||
        adultAnimalId ||
        butcherIds.length) && {
        itemAnimal: {
          affectionLvl: parseInt(affectionLvl, 10),
          lifeStage,
          affectionProduce,
          feedType,
          feedAmount: parseInt(feedAmount, 10),
          timeToAdult: parseInt(timeToAdult, 10),
          adultAnimalId,
          butcherIds: butcherIds
            .split(",")
            .map((id) => parseInt(id.trim(), 10))
            .filter((id) => !isNaN(id)),
        },
      }),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/add-process`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(processItem),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Process item added:", data);
        handleSnackbarOpen("Item successfully added", "success");
        // Reset form fields or handle success
      } else {
        console.error("Failed to add process item:", data);
        handleSnackbarOpen("Failed to add process item", "error");
        // Handle failure
      }
    } catch (error) {
      console.error("Error adding process item:", error);
      handleSnackbarOpen("Failed to add process item", "error");
    }
  };

  const handleAddAffectionProduce = () => {
    const newAffectionProduce = {
      affectionLvl: parseInt(affectionProduceLevel, 10),
      produces: [
        {
          produceId: parseInt(affectionProduceId, 10),
          probability: parseFloat(affectionProduceProbability),
        },
      ],
    };
    setAffectionProduce([...affectionProduce, newAffectionProduce]);

    // Reset the input fields
    setAffectionProduceLevel("");
    setAffectionProduceId("");
    setAffectionProduceProbability("");
  };

  const handleAddPreq = () => {
    setItemPreqs([
      ...itemPreqs,
      {
        itemId: preqItemId ? parseInt(preqItemId, 10) : undefined,
        requiredLevel: preqItemLvl ? parseInt(preqItemLvl, 10) : undefined,
        preqSkillId: preqSkillId ? parseInt(preqSkillId, 10) : undefined,
        preqSkillLevel: preqSkillLevel
          ? parseInt(preqSkillLevel, 10)
          : undefined,
      },
    ]);

    // Reset the prerequisite input fields
    setPreqItemId("");
    setPreqItemLvl("");
    setPreqSkillId("");
    setPreqSkillLevel("");
  };

  const handleAddProcessMaterial = () => {
    setItemProcesses([
      ...itemProcesses,
      {
        itemId: parseInt(processMaterialItemId, 10),
        itemAmount: parseInt(processMaterialItemAmount, 10),
      },
    ]);
    setProcessMaterialItemId("");
    setProcessMaterialItemAmount("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const item = {
      itemId,
      name,
      description,
      value,
      level,
      itemType,
      expGain,
      itemPreq: {
        ...(preqItemId && { itemId: preqItemId }),
        ...(preqItemLvl && { requiredLevel: preqItemLvl }),
        ...(preqSkillId && { preqSkillId: preqSkillId }), // Include preqSkillId
        ...(preqSkillLevel && { preqSkillLevel: preqSkillLevel }), // Include preqSkillLevel
      },
    };

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/add-item`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      }
    );

    const data = await response.json();

    if (response.ok) {
      console.log("Item added:", data);
      handleSnackbarOpen("Item successfully added", "success");
      // Handle successful item addition
    } else {
      console.error("Failed to add item:", data);
      handleSnackbarOpen("Failed to add process item", "error");
      // Handle failure
    }
  };

  const handleAddFishItemId = () => {
    const ids = fishItemIdsInput
      .split(",")
      .map((id) => parseInt(id.trim(), 10))
      .filter((id) => !isNaN(id));
    setFishItemIds(fishItemIds.concat(ids));
    setFishItemIdsInput(""); // Reset input
  };

  const handleAddLocation = async (e) => {
    e.preventDefault();

    const location = {
      locationId,
      locationName,
      locationDescription,
      level: locationLevel,
      expNeeded: locationExpNeeded,
      imageUrl: locationImageUrl,
      imageLocationBGUrl: locationImageBGUrl, // Include new field
      fishItemIds: fishItemIds,
      itemPreq: locationPreqs,
      locTag, // Adjust based on how you manage prerequisites
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/fish/add-location`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(location),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Location added:", data);
        handleSnackbarOpen("Location Successfully added!", "success");
        // Optionally reset form fields or handle success further
      } else {
        console.error("Failed to add location:", data);
        handleSnackbarOpen("Failed to add location", "error");
        // Handle failure
      }
    } catch (error) {
      console.error("Error adding location:", error);
      handleSnackbarOpen("Failed to add location", "error");
    }
  };

  const handleAddLocationPreq = () => {
    const newLocationPreq = {
      itemId: locationPreqItemId ? parseInt(locationPreqItemId, 10) : undefined,
      requiredLevel: locationPreqItemLvl
        ? parseInt(locationPreqItemLvl, 10)
        : undefined,
      preqSkillId: locationPreqSkillId
        ? parseInt(locationPreqSkillId, 10)
        : undefined,
      preqSkillLevel: locationPreqSkillLevel
        ? parseInt(locationPreqSkillLevel, 10)
        : undefined,
    };

    setLocationPreqs([...locationPreqs, newLocationPreq]);

    // Reset the form fields for the next input
    setLocationPreqItemId("");
    setLocationPreqItemLvl("");
    setLocationPreqSkillId("");
    setLocationPreqSkillLevel("");
  };

  // Notification
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [notificationImg, setNotificationImg] = useState("");

  const handleTitleChange = (event) => {
    setNotificationTitle(event.target.value);
  };

  const handleBodyChange = (event) => {
    setNotificationBody(event.target.value);
  };

  const handleImgChange = (event) => {
    setNotificationImg(event.target.value);
  };

  const { addNotification } = useNotificationsContext();

  const handleNotificationSubmit = async (e) => {
    e.preventDefault();
    const notification = {
      title: notificationTitle,
      body: notificationBody,
      img: notificationImg,
    };

    addNotification(notification)
      .then(() => {
        console.log("Notification added successfully");
        handleSnackbarOpen("Notification Successfully added!", "success");
        setNotificationTitle("");
        setNotificationBody("");
        setNotificationImg("");
      })
      .catch((error) => {
        let errorMessage = "Failed to add notification";
        if (error.response) {
          console.error("Error data:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);

          errorMessage += `: ${
            error.response.data.message || error.response.statusText
          }`;
        } else if (error.request) {
          console.error(
            "No response received for notification request:",
            error.request
          );
          errorMessage += ": No response from server";
        } else {
          console.error("Error", error.message);
          errorMessage += `: ${error.message}`;
        }
        handleSnackbarOpen(errorMessage, "error");
      });
  };

  return (
    <>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Add Item" />
        <Tab label="Add Process" />
        <Tab label="Add Shop Product" />
        <Tab label="Add Skill" />
        <Tab label="Add Location" />
        <Tab label="Add Notification" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="itemId"
            label="Item ID"
            name="itemId"
            autoComplete="itemId"
            autoFocus
            value={itemId}
            onChange={(e) => setItemId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="name"
            label="Name"
            type="text"
            id="name"
            autoComplete="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="description"
            label="Description"
            type="text"
            id="description"
            autoComplete="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="value"
            label="Value"
            type="number"
            id="value"
            autoComplete="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="level"
            label="Level"
            type="number"
            id="level"
            autoComplete="level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="itemType"
            label="Item Type"
            type="text"
            id="itemType"
            autoComplete="itemType"
            value={itemType}
            onChange={(e) => setItemType(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="expGain"
            label="Experience Gain"
            type="number"
            id="expGain"
            autoComplete="expGain"
            value={expGain}
            onChange={(e) => setExpGain(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="preqItemId"
            label="Prerequisite Item ID"
            type="text"
            id="preqItemId"
            value={preqItemId}
            onChange={(e) => setPreqItemId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="preqItemLvl"
            label="Prerequisite Item Level"
            type="number"
            id="preqItemLvl"
            value={preqItemLvl}
            onChange={(e) => setPreqItemLvl(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="preqSkillId"
            label="Prerequisite Skill ID"
            type="text"
            value={preqSkillId}
            onChange={(e) => setPreqSkillId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="preqSkillLevel"
            label="Prerequisite Skill Level"
            type="number"
            value={preqSkillLevel}
            onChange={(e) => setPreqSkillLevel(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Item
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Box
          component="form"
          onSubmit={handleAddProcessItem}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item ID"
            value={processItemId}
            onChange={(e) => setProcessItemId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Name"
            value={processName}
            onChange={(e) => setProcessName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Description"
            value={processDescription}
            onChange={(e) => setProcessDescription(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Value"
            type="number"
            value={processValue}
            onChange={(e) => setProcessValue(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Level"
            type="number"
            value={processLevel}
            onChange={(e) => setProcessLevel(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Exp Gain"
            type="number"
            value={processExpGain}
            onChange={(e) => setProcessExpGain(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Type"
            value={processItemType}
            onChange={(e) => setProcessItemType(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Image URL"
            value={processImageUrl}
            onChange={(e) => setProcessImageUrl(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Status"
            value={processStatus}
            onChange={(e) => setProcessStatus(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Process Item Probability"
            value={processItemProbability}
            onChange={(e) => setProcessItemProbability(e.target.value)}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
            helperText="Enter a value between 0 and 1 (e.g., 0.5 for 50% probability)"
          />
          {/* Additional fields for itemProcess */}

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Prerequisites</Typography>
          </Divider>

          <TextField
            margin="normal"
            fullWidth
            label="Prerequisite Item ID"
            value={preqItemId}
            onChange={(e) => setPreqItemId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Prerequisite Item Level"
            type="number"
            value={preqItemLvl}
            onChange={(e) => setPreqItemLvl(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Process Prerequisite Skill ID"
            value={preqSkillId}
            onChange={(e) => setPreqSkillId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Process Prerequisite Skill Level"
            type="number"
            value={preqSkillLevel}
            onChange={(e) => setPreqSkillLevel(e.target.value)}
          />

          <Button onClick={handleAddPreq} sx={{ mb: 2 }}>
            Add Prerequisite
          </Button>

          {itemPreqs.map((preq, index) => (
            <div key={index}>
              Item ID: {preq.itemId}, Level: {preq.requiredLevel}, Skill ID:{" "}
              {preq.preqSkillId}, Skill Level: {preq.preqSkillLevel}
            </div>
          ))}

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Process Materials</Typography>
          </Divider>

          <TextField
            margin="normal"
            fullWidth
            label="Process Material Item ID"
            value={processMaterialItemId}
            onChange={(e) => setProcessMaterialItemId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Process Material Item Amount"
            type="number"
            value={processMaterialItemAmount}
            onChange={(e) => setProcessMaterialItemAmount(e.target.value)}
          />
          <Button onClick={handleAddProcessMaterial} sx={{ mb: 2 }}>
            Add Process Mats
          </Button>

          {/* Display added process materials */}
          {itemProcesses.map((process, index) => (
            <div key={index}>
              Item ID: {process.itemId}, Amount: {process.itemAmount}
            </div>
          ))}

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Farm Details (Optional)</Typography>
          </Divider>

          <Typography variant="body1" sx={{ mt: 2 }}>
            Enter the following details if this is a farm-related item (e.g.,
            seeds):
          </Typography>

          <TextField
            margin="normal"
            fullWidth
            label="Days to Harvest"
            type="number"
            value={harvestDays}
            onChange={(e) => setHarvestDays(e.target.value)}
            helperText="Enter the number of days until the item can be harvested"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Hours to Harvest"
            type="number"
            value={harvestHours}
            onChange={(e) => setHarvestHours(e.target.value)}
            helperText="Enter the number of hours until the item can be harvested"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Minutes to Harvest"
            type="number"
            value={harvestMinutes}
            onChange={(e) => setHarvestMinutes(e.target.value)}
            helperText="Enter the number of minutes until the item can be harvested"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Days to Wilt"
            type="number"
            value={wiltDays}
            onChange={(e) => setWiltDays(e.target.value)}
            helperText="Enter the number of days until the item wilts after being ready for harvest"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Hours to Wilt"
            type="number"
            value={wiltHours}
            onChange={(e) => setWiltHours(e.target.value)}
            helperText="Enter the number of hours until the item wilts after being ready for harvest"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Minutes to Wilt"
            type="number"
            value={wiltMinutes}
            onChange={(e) => setWiltMinutes(e.target.value)}
            helperText="Enter the number of minutes until the item wilts after being ready for harvest"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Harvest Product ID"
            value={harvestProduct}
            onChange={(e) => setHarvestProduct(e.target.value)}
            helperText="Enter the ID of the product obtained from harvesting this item"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Wilt Product ID"
            value={wiltProduct}
            onChange={(e) => setWiltProduct(e.target.value)}
            helperText="Enter the ID of the product obtained if the item wilts"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Type of Water ID"
            value={typeOfWater}
            onChange={(e) => setTypeOfWater(e.target.value)}
            helperText="Enter the ID of the water type required for this item"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Water Amount"
            type="number"
            value={waterAmount}
            onChange={(e) => setWaterAmount(e.target.value)}
            helperText="Enter the amount of water required"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Minimum Amount Probability"
            type="number"
            value={minAmountProbability}
            onChange={(e) => setMinAmountProbability(e.target.value)}
            helperText="Enter the minimum amount obtained on successful harvest"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Maximum Amount Probability"
            type="number"
            value={maxAmountProbability}
            onChange={(e) => setMaxAmountProbability(e.target.value)}
            helperText="Enter the maximum amount obtained on successful harvest"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Bonus Product IDs (comma-separated)"
            value={bonusProducts.join(",")}
            onChange={(e) => setBonusProducts(e.target.value.split(","))}
            helperText="Enter bonus product IDs separated by commas, e.g., 1,2,3"
          />

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Animal Details</Typography>
          </Divider>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Affection Level"
            type="number"
            value={affectionLvl}
            onChange={(e) => setAffectionLvl(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Life Stage"
            value={lifeStage}
            onChange={(e) => setLifeStage(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Adult Animal Id"
            value={adultAnimalId}
            onChange={(e) => setAdultAnimalId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Feed Type"
            value={feedType}
            onChange={(e) => setFeedType(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Feed Amount"
            type="number"
            value={feedAmount}
            onChange={(e) => setFeedAmount(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Time to Adult (days)"
            type="number"
            value={timeToAdult}
            onChange={(e) => setTimeToAdult(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Butcher IDs (comma-separated)"
            value={butcherIds}
            onChange={(e) => setButcherIds(e.target.value)}
          />

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Affection Produce</Typography>
          </Divider>

          <TextField
            margin="normal"
            fullWidth
            label="Affection Produce Level"
            type="number"
            value={affectionProduceLevel}
            onChange={(e) => setAffectionProduceLevel(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Produce ID"
            type="number"
            value={affectionProduceId}
            onChange={(e) => setAffectionProduceId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Produce Probability"
            type="number"
            value={affectionProduceProbability}
            onChange={(e) => setAffectionProduceProbability(e.target.value)}
          />
          <Button onClick={handleAddAffectionProduce} sx={{ mb: 2 }}>
            Add Affection Produce
          </Button>

          {affectionProduce.map((produce, index) => (
            <div key={index}>
              Affection Level: {produce.affectionLvl}, Produce ID:{" "}
              {produce.produces[0].produceId}, Probability:{" "}
              {produce.produces[0].probability}
            </div>
          ))}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Process Item
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <Box
          component="form"
          onSubmit={handleAddShopProduct}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            name="productName"
            label="Product Name"
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="productValue"
            label="Value"
            type="number"
            value={productValue}
            onChange={(e) => setProductValue(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="productImg"
            label="Product Image URL"
            type="text"
            value={productImg}
            onChange={(e) => setProductImg(e.target.value)}
          />

          <Typography variant="h6" sx={{ mt: 2 }}>
            Shop Products
          </Typography>
          {shopProducts.map((product, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <TextField
                label="Product Item ID"
                value={product.itemId}
                onChange={(e) =>
                  handleShopProductChange(index, "itemId", e.target.value)
                }
                type="number"
                sx={{ mr: 1 }}
              />
              <TextField
                label="Product Amount"
                value={product.amount}
                onChange={(e) =>
                  handleShopProductChange(index, "amount", e.target.value)
                }
                type="number"
                sx={{ mr: 1 }}
              />
              <TextField
                label="Product Image URL (optional)"
                value={product.itemImage}
                onChange={(e) =>
                  handleShopProductChange(index, "itemImage", e.target.value)
                }
              />
            </Box>
          ))}
          <Button
            onClick={handleAddShopProductItem}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            Add Another Product
          </Button>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Shop Currency
          </Typography>
          {shopCurrency.map((currency, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <TextField
                label="Currency Item ID"
                value={currency.itemId}
                onChange={(e) =>
                  handleShopCurrencyChange(index, "itemId", e.target.value)
                }
                type="number"
                sx={{ mr: 1 }}
              />
              <TextField
                label="Currency Amount"
                value={currency.amount}
                onChange={(e) =>
                  handleShopCurrencyChange(index, "amount", e.target.value)
                }
                type="number"
                sx={{ mr: 1 }}
              />
              <TextField
                label="Currency Image URL (optional)"
                value={currency.itemImage}
                onChange={(e) =>
                  handleShopCurrencyChange(index, "itemImage", e.target.value)
                }
              />
            </Box>
          ))}
          <Button
            onClick={handleAddShopCurrencyItem}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            Add Another Currency
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Shop Product
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        <Box
          component="form"
          onSubmit={handleAddSkill}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="skillId"
            label="Skill ID"
            name="skillId"
            value={skillId}
            onChange={(e) => setSkillId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="skillName"
            label="Skill Name"
            type="text"
            value={skillName}
            onChange={(e) => setSkillName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="skillDescription"
            label="Description"
            type="text"
            value={skillDescription}
            onChange={(e) => setSkillDescription(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="skillLevel"
            label="Skill Level"
            type="number"
            value={skillLevel}
            onChange={(e) => setSkillLevel(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="skillExp"
            label="Skill Experience"
            type="number"
            value={skillExp}
            onChange={(e) => setSkillExp(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="skillImage"
            label="Skill Image URL"
            type="text"
            value={skillImage}
            onChange={(e) => setSkillImage(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="skillStatus"
            label="Skill Status"
            type="text"
            value={skillStatus}
            onChange={(e) => setSkillStatus(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="skillPreqId"
            label="Prerequisite Skill ID"
            type="text"
            value={skillPreqId}
            onChange={(e) => setSkillPreqId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="skillPreqLevel"
            label="Prerequisite Skill Level"
            type="number"
            value={skillPreqLevel}
            onChange={(e) => setSkillPreqLevel(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Skill
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={4}>
        {" "}
        {/* Adjust the index based on the actual order */}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="locationId"
            label="Location ID"
            value={locationId}
            onChange={(e) => setLocationId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Location Name"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Description"
            value={locationDescription}
            onChange={(e) => setLocationDescription(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Level"
            type="number"
            value={locationLevel}
            onChange={(e) => setLocationLevel(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Experience Needed"
            type="number"
            value={locationExpNeeded}
            onChange={(e) => setLocationExpNeeded(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Image URL"
            value={locationImageUrl}
            onChange={(e) => setLocationImageUrl(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Background Image URL"
            value={locationImageBGUrl}
            onChange={(e) => setLocationImageBGUrl(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Location Tag"
            value={locTag}
            onChange={(e) => setLocTag(e.target.value)}
          />

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Fishes</Typography>
          </Divider>

          <TextField
            margin="normal"
            fullWidth
            label="Fish Item IDs (comma-separated)"
            value={fishItemIdsInput}
            onChange={(e) => setFishItemIdsInput(e.target.value)}
            helperText="Enter IDs separated by commas, e.g., 1, 2, 3"
          />
          <Button onClick={handleAddFishItemId} sx={{ mt: 2, mb: 2 }}>
            Add Fish Item IDs
          </Button>
          {fishItemIds.length > 0 && (
            <Typography sx={{ mt: 2 }}>
              Added IDs: {fishItemIds.join(", ")}
            </Typography>
          )}

          <Divider sx={{ my: 2 }}>
            <Typography variant="h6">Location Prerequisites</Typography>
          </Divider>

          <TextField
            margin="normal"
            fullWidth
            label="Prerequisite Item ID"
            value={locationPreqItemId}
            onChange={(e) => setLocationPreqItemId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Prerequisite Item Level"
            type="number"
            value={locationPreqItemLvl}
            onChange={(e) => setLocationPreqItemLvl(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Prerequisite Skill ID"
            value={locationPreqSkillId}
            onChange={(e) => setLocationPreqSkillId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Prerequisite Skill Level"
            type="number"
            value={locationPreqSkillLevel}
            onChange={(e) => setLocationPreqSkillLevel(e.target.value)}
          />
          {locationPreqs.map((preq, index) => (
            <Box key={index} sx={{ mt: 1 }}>
              Item ID: {preq.itemId}, Level: {preq.requiredLevel}, Skill ID:{" "}
              {preq.preqSkillId}, Skill Level: {preq.preqSkillLevel}
            </Box>
          ))}
          <Button onClick={handleAddLocationPreq} sx={{ mt: 2, mb: 2 }}>
            Add Prerequisite
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleAddLocation} // Implement this function
          >
            Add Location
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={5}>
        {" "}
        {/* Adjust the index based on the actual order */}
        <Box
          component="form"
          onSubmit={handleNotificationSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label="Title"
            value={notificationTitle}
            onChange={handleTitleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Body"
            multiline
            rows={4}
            value={notificationBody}
            onChange={handleBodyChange}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Image URL (optional)"
            value={notificationImg}
            onChange={handleImgChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Notification
          </Button>
        </Box>
      </TabPanel>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
