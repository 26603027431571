// ItemCardsSlider.js
import React, { useState } from "react";
import ItemCard from "./ItemCard";
import { Box } from "@mui/material";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ItemCardsSlider = ({
  items,
  onGather,
  onAutoGather,
  expPercentages,
  purchasedItems,
  isAutoGather,
  onAutoGatherChange
}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 0.35,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
  };

  // Create a state variable to manage the auto-gather state for each item
  const [autoGatherState, setAutoGatherState] = useState({});

  // Function to toggle auto-gather for a specific item
  const toggleAutoGather = (itemId) => {
    setAutoGatherState((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  // Filter out items with itemId 31, 32, and 33
  const filteredItems = items.filter(
    (item) => ![31, 32, 33].includes(item.itemId)
  );

  return (
    <>
      {window.innerWidth <= 1024 ? (
        <Slider {...settings}>
          {filteredItems.map((item, idx) => (
            <Box key={idx}>
              <ItemCard
                item={item}
                onGather={() => onGather(item.itemId)}
                onAutoGather={() => {
                  onAutoGather(item.itemId);
                  toggleAutoGather(item.itemId);
                }}
                onAutoGatherChange={onAutoGatherChange}
                expPercentage={
                  expPercentages[item.itemId]
                    ? expPercentages[item.itemId].toFixed(2) + "%"
                    : "Loading..."
                }
                purchasedItems={purchasedItems}
                isAutoGather={autoGatherState[item.itemId] || false}
              />
            </Box>
          ))}
        </Slider>
      ) : (
        <div style={{ overflowY: "scroll", maxHeight: "370px" }}>
          {filteredItems.map((item, idx) => (
            <Box key={idx}>
              <ItemCard
                item={item}
                onGather={() => onGather(item.itemId)}
                onAutoGather={() => {
                  onAutoGather(item.itemId);
                  toggleAutoGather(item.itemId);
                }}
                onAutoGatherChange={onAutoGatherChange}
                expPercentage={
                  expPercentages[item.itemId]
                    ? expPercentages[item.itemId].toFixed(2) + "%"
                    : "Loading..."
                }
                purchasedItems={purchasedItems}
                isAutoGather={autoGatherState[item.itemId] || false}
              />
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default ItemCardsSlider;
