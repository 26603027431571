// AutoGatherContext.js
import React, { createContext, useContext, useState } from "react";

const AutoGatherContext = createContext();

export const AutoGatherProvider = ({ children }) => {
  // GatherTab States
  const [itemCardStates, setItemCardStates] = useState({});
  const [autoGatherStates, setAutoGatherStates] = useState({});
  const [animationStates, setAnimationStates] = useState({});
  const [isGatheringInProgressStates, setIsGatheringInProgressStates] =
    useState({});

  const updateItemCardState = (itemId, state) => {
    setItemCardStates((prevState) => ({
      ...prevState,
      [itemId]: state,
    }));
  };

  const updateAutoGatherState = (itemId, state) => {
    setAutoGatherStates((prevState) => ({
      ...prevState,
      [itemId]: state,
    }));
  };

  const updateAnimationState = (itemId, state) => {
    setAnimationStates((prevState) => ({
      ...prevState,
      [itemId]: state,
    }));
  };

  const updateIsGatheringInProgressState = (itemId, state) => {
    setIsGatheringInProgressStates((prevState) => ({
      ...prevState,
      [itemId]: state,
    }));
  };

  // ProcessTab states
  const [processingStates, setProcessingStates] = useState({});
  const [processingIntervals, setProcessingIntervals] = useState({});
  const [processingProgress, setProcessingProgress] = useState({});

  const updateProcessingState = (itemId, state) => {
    setProcessingStates((prevState) => ({
      ...prevState,
      [itemId]: state,
    }));
  };

  const setProcessingInterval = (itemId, interval) => {
    setProcessingIntervals((prevState) => ({
      ...prevState,
      [itemId]: interval,
    }));
  };

  const updateProcessingProgress = (itemId, progress) => {
    setProcessingProgress((prev) => ({ ...prev, [itemId]: progress }));
  };

  const clearProcessingInterval = (itemId) => {
    const interval = processingIntervals[itemId];
    if (interval) {
      clearInterval(interval);
      setProcessingIntervals((prevState) => ({
        ...prevState,
        [itemId]: null,
      }));
    }
  };

   // CraftTab states
   const [craftingStates, setCraftingStates] = useState({});
   const [craftingIntervals, setCraftingIntervals] = useState({});
   const [craftingProgress, setCraftingProgress] = useState({});
 
   const updateCraftingState = (itemId, state) => {
     setCraftingStates((prevState) => ({
       ...prevState,
       [itemId]: state,
     }));
   };
 
   const setCraftingInterval = (itemId, interval) => {
     setCraftingIntervals((prevState) => ({
       ...prevState,
       [itemId]: interval,
     }));
   };
 
   const updateCraftingProgress = (itemId, progress) => {
     setCraftingProgress((prev) => ({ ...prev, [itemId]: progress }));
   };
 
   const clearCraftingInterval = (itemId) => {
     const interval = craftingIntervals[itemId];
     if (interval) {
       clearInterval(interval);
       setCraftingIntervals((prevState) => ({
         ...prevState,
         [itemId]: null,
       }));
     }
   };

  return (
    <AutoGatherContext.Provider
      value={{
        itemCardStates,
        updateItemCardState,
        autoGatherStates,
        updateAutoGatherState,
        animationStates,
        updateAnimationState,
        isGatheringInProgressStates,
        updateIsGatheringInProgressState,
        processingStates,
        updateProcessingState,
        setProcessingInterval,
        clearProcessingInterval,
        processingProgress,
        updateProcessingProgress,
        craftingStates,
        updateCraftingState,
        setCraftingInterval,
        clearCraftingInterval,
        craftingProgress,
        updateCraftingProgress,
      }}
    >
      {children}
    </AutoGatherContext.Provider>
  );
};

export const useAutoGather = () => {
  return useContext(AutoGatherContext);
};
