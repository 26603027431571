import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SecureLS from "secure-ls";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import Home from "./Game/Home";
import LandingPage from "./LandingPage/LandingPage";
import { NotificationContextProvider } from "../modules/System/Components/Notification/NotificationContext";

const ls = new SecureLS({ isCompression: false });
const userDetails = ls.get("userDetails");
const userId = userDetails ? userDetails.userId : null;

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/hoard"
        element={
          <NotificationContextProvider userId={userId}>
            <Home />
          </NotificationContextProvider>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
