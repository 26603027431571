let locationLevels;

function loadLocationLevels() {
  if (!locationLevels) {
    locationLevels = require("./locationLevelsData.js");
    console.log("Location levels loaded.");
  }
}

function getFailPercentageForNextLevel(currentLevel, locationId) {
  loadLocationLevels();
  const locationLevelInfo = locationLevels[locationId];
  if (locationLevelInfo) {
    const levelInfo = locationLevelInfo.find((l) => l.level === currentLevel);
    return levelInfo ? levelInfo.failPercentage : null;
  }
  return null;
}

function getExpNeededForNextLevel(currentLevel, locationId) {
  loadLocationLevels();
  const locationLevelInfo = locationLevels[locationId];
  if (locationLevelInfo) {
    const levelInfo = locationLevelInfo.find(
      (l) => l.level === currentLevel + 1
    );
    return levelInfo ? levelInfo.expNeeded : null;
  }
  return null;
}

function levelUpLocation(location) {
  loadLocationLevels();
  const locationLevelInfo = locationLevels[location.locationId];
  if (locationLevelInfo) {
    const currentLevelIndex = location.level - 1;
    if (
      currentLevelIndex + 1 < locationLevelInfo.length &&
      location.exp >= locationLevelInfo[currentLevelIndex].expNeeded
    ) {
      location.level += 1;
      location.exp = 0; // Reset exp after level up

      // Update failPercentage and expNeeded for the new level
      const nextLevelInfo = locationLevelInfo[location.level - 1];
      location.failPercentage = nextLevelInfo.failPercentage;
      location.expNeeded = nextLevelInfo.expNeeded;
    }
  }
}

// Ensure locationLevels is loaded when accessed directly
loadLocationLevels();

module.exports = {
  locationLevels,
  getFailPercentageForNextLevel,
  getExpNeededForNextLevel,
  levelUpLocation,
};
