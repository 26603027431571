import React, { useState } from "react";
import ShopItemCard from "./ShopItemCard";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

const ShopItemCardsSlider = ({ products, userId, userInventoryRef, setBronzeCoinQuantity }) => {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const maxProductsWithoutSlider = 6;
  const shouldUseSlider =
    window.innerWidth <= 1024 || products.length > maxProductsWithoutSlider;

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    edgeFriction: 0.35,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <>
      {shouldUseSlider ? (
        <div style={{ overflow: "auto", maxHeight: "70vh" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: isSmallScreen ? 3 : 1 }}
              columns={{ xs: isSmallScreen ? 12 : 4, sm: 8, md: 12 }}
            >
              {products.map((product, productIdx) => (
                <Grid item xs={12} sm={4} md={4} key={productIdx}>
                  <Box key={productIdx}>
                    <ShopItemCard
                      userId={userId}
                      products={products}
                      product={product}
                      userInventoryRef={userInventoryRef}
                      setBronzeCoinQuantity={setBronzeCoinQuantity}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </div>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: isSmallScreen ? 3 : 1 }}
            columns={{ xs: isSmallScreen ? 12 : 4, sm: 8, md: 12 }}
          >
            {products.map((product, productIdx) => (
              <Grid item xs={12} sm={4} md={4} key={productIdx}>
                <Box key={productIdx}>
                  <ShopItemCard
                    userId={userId}
                    products={products}
                    product={product}
                    userInventoryRef={userInventoryRef}
                    setBronzeCoinQuantity={setBronzeCoinQuantity}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopItemCardsSlider;
