import React, { useState, useEffect, useRef } from "react";
import SecureLS from "secure-ls";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  CardMedia,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Badge from "@mui/material/Badge";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PercentIcon from "@mui/icons-material/Percent";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import DialogContentText from "@mui/material/DialogContentText";

import {
  itemLevels,
  getExpForNextItemLevel,
} from "../../../../System/Levels/itemLevels";
import {
  locationLevels,
  getExpNeededForNextLevel,
} from "../../../../System/Levels/locationLevels";
import { getFailPercentageForNextLevel } from "../../../../System/Levels/locationLevels";

const FishingActivity = ({
  location,
  items,
  userInventoryRef,
  userProfileRef,
  onBackToFishingTab,
  fishInventoryRef,
  onGather,
}) => {
  const [fishCount, setFishCount] = useState(0);
  const [caughtFish, setCaughtFish] = useState({});
  const [isFishing, setIsFishing] = useState(false);
  const [fishingProgress, setFishingProgress] = useState(0);
  const [fishingAttempts, setFishingAttempts] = useState(0);
  const [fishingRodQuantity, setFishingRodQuantity] = useState(0);
  const [wormQuantity, setWormQuantity] = useState(0);
  const [fishDialogOpen, setFishDialogOpen] = useState(false);
  const [selectedFishDetail, setSelectedFishDetail] = useState({});
  const [inventoryDialogOpen, setInventoryDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [userId, setUserId] = useState(null);
  const [locationLevel, setLocationLevel] = useState(location.level);
  const [failPercentage, setFailPercentage] = useState(location.failPercentage);
  const [insufficientMaterialsDialogOpen, setInsufficientMaterialsDialogOpen] =
    useState(false);
  const [insufficientMaterialsInfo, setInsufficientMaterialsInfo] = useState({
    rods: 0,
    worms: 0,
    requiredRods: 1,
    requiredWorms: 5,
  });
  const [arrowBackDialog, setArrowBackDialog] = useState(false);
  const [probabilityDialogOpen, setProbabilityDialogOpen] = useState(false);
  const [adjustedProbabilitiesState, setAdjustedProbabilitiesState] = useState({
    adjustedProbabilities: [],
    failPercentage: 0,
  });
  const [fishDetails, setFishDetails] = useState([]);
  const [bronzeCoinsEarned, setBronzeCoinsEarned] = useState(0);
  const [bronzeCoinsDialogOpen, setBronzeCoinsDialogOpen] = useState(false);
  const [storageSuccessDialogOpen, setStorageSuccessDialogOpen] =
    useState(false);

  const [buttonHoldStart, setButtonHoldStart] = useState(null);
  const [autoFishingEnabled, setAutoFishingEnabled] = useState(false);

  const startFishing = () => {
    setIsFishing(true);
  };

  useEffect(() => {
    let interval;
    if (autoFishingEnabled) {
      startFishing();
      interval = setInterval(() => {
        startFishing();
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [autoFishingEnabled]);

  // Updated useEffect for setting up fishDetails based on special location
  useEffect(() => {
    if (location.locTag === "spacial-fish") {
      const dynamicFishDetails = userInventoryRef.current.itemDetails
        .filter((item) => {
          const itemIdAsString = String(item.itemId);
          const foundItem = items.find(
            (gameItem) =>
              String(gameItem.itemId) === itemIdAsString &&
              gameItem.itemType === "fished"
          );
          return foundItem;
        })
        .map((inventoryItem) => {
          // No need to convert here as it's used for finding in the same array
          const fullItemDetails = items.find(
            (item) => String(item.itemId) === String(inventoryItem.itemId)
          );
          return { ...fullItemDetails };
        });

      setFishDetails(dynamicFishDetails);

      // Update fishInventoryRef to use item IDs as keys
      const updatedFishInventory = {};
      dynamicFishDetails.forEach((item) => {
        updatedFishInventory[item.itemId] = { ...item };
      });
      fishInventoryRef.current = updatedFishInventory;
    } else {
      const predefinedFishDetails = location.fishItemIds.map((fishItemId) => {
        const foundItem = items.find((item) => item.itemId === fishItemId);
        return foundItem;
      });

      setFishDetails(predefinedFishDetails);

      const updatedFishInventory = items
        .filter(
          (item) =>
            location.fishItemIds.includes(item.itemId) && item.status === "fish"
        )
        .reduce((acc, item) => {
          const expNeeded = getExpForNextItemLevel(item.itemLvl, item.itemId);
          const levelInfo = itemLevels[item.itemId]?.find(
            (lvl) => lvl.level === item.itemLvl
          );
          const expGain = levelInfo?.expGain || item.expGain; // Ensure to fetch expGain based on current level

          acc[item.itemId] = {
            ...item,
            exp: item.itemExp || 0,
            expNeeded,
            expGain, // Updated to dynamically adjust based on item's level
          };
          return acc;
        }, {});

      fishInventoryRef.current = updatedFishInventory;
    }
  }, [location, userInventoryRef.current, items]);

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    setUserId(userDetails ? userDetails.userId : null);

    const fishingRod = userInventoryRef.current.itemDetails.find(
      (item) => item.itemId === "16"
    ); // Assuming '16' is the itemId for fishing rods
    if (fishingRod) {
      setFishingRodQuantity(fishingRod.quantity);
    }

    const worm = userInventoryRef.current.itemDetails.find(
      (item) => item.itemId === "11"
    ); // Assuming '11' is the itemId for worms
    if (worm) {
      setWormQuantity(worm.quantity);
    }
  }, [items, location.fishItemIds, userInventoryRef]);

  useEffect(() => {
    let start = null;
    const duration = 2000; // Duration of the progress in milliseconds

    const animate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const percentage = Math.min((progress / duration) * 100, 100);
      setFishingProgress(percentage);

      if (progress < duration) {
        requestAnimationFrame(animate);
      } else {
        setIsFishing(false);
        handleLoanFishClick(); // Trigger the fishing logic after progress completes
      }
    };

    if (isFishing) {
      requestAnimationFrame(animate);
    }
  }, [isFishing]);

  const toggleProbabilityDialog = () => {
    setProbabilityDialogOpen(!probabilityDialogOpen);
  };

  const handleFishCaught = (caughtFishDetail) => {
    const amount = caughtFish[caughtFishDetail.itemId]
      ? caughtFish[caughtFishDetail.itemId] + 1
      : 1;
    setCaughtFish((prev) => ({ ...prev, [caughtFishDetail.itemId]: amount }));

    let itemDetail = fishInventoryRef.current[caughtFishDetail.itemId];
    console.log("itemDetail: ", itemDetail);
    const isFish = caughtFishDetail.status === "fish";
    if (!itemDetail) {
      // Item not in fishInventoryRef, try to find it in the items array
      const itemFromItemsArray = items.find(
        (item) => item.itemId.toString() === caughtFishDetail.itemId.toString()
      );
      if (itemFromItemsArray) {
        // Initialize item in fishInventoryRef with details from items array
        itemDetail = {
          ...itemFromItemsArray,
          itemExp: itemFromItemsArray.itemExp || 0, // Use itemExp from items array
        };
        fishInventoryRef.current[caughtFishDetail.itemId] = itemDetail;
      }
    }

    if (itemDetail) {
      let { itemExp, expNeeded, itemLvl } = itemDetail;
      const levelInfo = itemLevels[caughtFishDetail.itemId]?.find(
        (lvl) => lvl.level === itemLvl
      );
      let expGain = levelInfo?.expGain || itemDetail.expGain; // Use level-specific expGain if available

      itemExp += expGain * amount; // Add expGain for each caught item, considering the amount caught

      // Level up logic
      while (itemExp >= expNeeded) {
        itemExp -= expNeeded;
        itemLvl += 1;
        const nextLevelInfo = itemLevels[caughtFishDetail.itemId]?.find(
          (lvl) => lvl.level === itemLvl
        );
        expGain = nextLevelInfo?.expGain || expGain; // Update expGain based on new level
        expNeeded = getExpForNextItemLevel(itemLvl, caughtFishDetail.itemId);
      }

      // Update the item details in fishInventoryRef
      fishInventoryRef.current[caughtFishDetail.itemId] = {
        ...itemDetail,
        itemExp,
        itemLvl,
        expNeeded,
        expGain,
      };
    } else {
      console.warn(`Detail not found for itemId: ${caughtFishDetail.itemId}`);
    }

    // Update user experience and levels accordingly
    updateUserExpAndLevels(caughtFishDetail.expGain);
  };

  const updateUserExpAndLevels = async (expGain) => {
    const fishingSkillIndex = userProfileRef.current.skills.findIndex(
      (skill) => skill.skillId === 4
    );

    if (fishingSkillIndex !== -1) {
      // Update fishing skill experience
      userProfileRef.current.skills[fishingSkillIndex].skillExp += expGain;

      const fishingSkill = userProfileRef.current.skills[fishingSkillIndex];

      // Check if the fishing skill level needs to be increased
      if (
        fishingSkill.skillExp >=
        getExpNeededForNextLevel(
          fishingSkill.skillLevel,
          userProfileRef.current.locations[0].locationId // Update to use fishing location's ID
        )
      ) {
        fishingSkill.skillExp -= getExpNeededForNextLevel(
          fishingSkill.skillLevel,
          userProfileRef.current.locations[0].locationId // Update to use fishing location's ID
        );
        fishingSkill.skillLevel += 1;
      }
    }

    // Update location experience based on fishing
    const locationIndex = userProfileRef.current.locations.findIndex(
      (loc) => loc.locationId === userProfileRef.current.locations[0].locationId
    );

    if (
      locationIndex !== -1 &&
      userProfileRef.current.locations[locationIndex].level <
        locationLevels[userProfileRef.current.locations[0].locationId].length &&
      fishCount > 0
    ) {
      const currentExpNeeded =
        locationLevels[userProfileRef.current.locations[0].locationId][
          userProfileRef.current.locations[locationIndex].level - 1
        ].expNeeded;

      if (
        userProfileRef.current.locations[locationIndex].exp + expGain >=
        currentExpNeeded
      ) {
        const nextLevelInfo =
          locationLevels[userProfileRef.current.locations[0].locationId][
            userProfileRef.current.locations[locationIndex].level
          ];
        userProfileRef.current.locations[locationIndex].exp =
          userProfileRef.current.locations[locationIndex].exp +
          expGain -
          currentExpNeeded;
        userProfileRef.current.locations[locationIndex].level += 1;
        setFailPercentage(nextLevelInfo.failPercentage);
      } else {
        userProfileRef.current.locations[locationIndex].exp += expGain;
      }
    }

    // Update user experience
    userProfileRef.current.exp += expGain;
  };

  const handleFishClick = (fish) => {
    const isCaught = isFishInInventory(fish.itemId.toString());

    let fishDetail;

    if (location.locTag === "spacial-fish") {
      // For "spacial-fish" location, use fishInventoryRef or fallback to fish
      fishDetail = fishInventoryRef.current[fish.itemId] || fish;
      if (isCaught) {
        const { itemExp, expNeeded } = fishDetail;
        expPercentage = expNeeded
          ? ((itemExp / expNeeded) * 100).toFixed(2)
          : "0";
      }
    } else {
      // For non-"spacial-fish" locations, use userInventoryRef or fallback to items or fish
      fishDetail =
        userInventoryRef.current.itemDetails.find(
          (item) => item.itemId === fish.itemId
        ) ||
        items.find(
          (item) => item.itemId.toString() === fish.itemId.toString()
        ) ||
        fish;
    }

    let expPercentage = "0";

    if (isCaught) {
      let exp = 0;
      let expNeeded = 0;

      if (location.locTag === "spacial-fish") {
        const fishDetail =
          fishInventoryRef.current[fish.itemId] ||
          items.find(
            (item) => item.itemId.toString() === fish.itemId.toString()
          );
        if (fishDetail) {
          exp = fishDetail.itemExp || 0;
          expNeeded = fishDetail.expNeeded || 0;
        }
      } else {
        // For non-"spacial-fish" locations, combine info from `items` and `userInventoryRef`
        const baseDetail = items.find(
          (item) => item.itemId.toString() === fish.itemId.toString()
        );
        const userDetail = userInventoryRef.current.itemDetails.find(
          (item) => item.itemId.toString() === fish.itemId.toString()
        );

        if (baseDetail) {
          expNeeded = baseDetail.expNeeded || 0;
        }
        if (userDetail) {
          exp = userDetail.itemExp || 0;
        }
      }

      if (expNeeded > 0) {
        // Prevent division by zero
        expPercentage = ((exp / expNeeded) * 100).toFixed(2);
      }
    }

    setSelectedFishDetail({
      name: isCaught ? fishDetail.itemName || fishDetail.name : "???",
      description: isCaught ? "Caught" : "Uncaught",
      fishLvl: isCaught
        ? fishDetail.itemLevel || fishDetail.itemLvl
        : undefined,
      expPercentage,
      imgUrl: fishDetail.imageUrl || "",
      fishDescription: isCaught
        ? fishDetail.description
        : "Catch to discover its details",
    });

    setFishDialogOpen(true);
  };

  const handleLoanFishClick = () => {
    const targetLocationId = location.locationId;
    const fishLocation = userProfileRef.current.locations.find(
      (location) => location.locationId === targetLocationId
    );
    const locationLevel = fishLocation ? fishLocation.level : 1;

    if (fishingRodQuantity < 1 || wormQuantity < 5) {
      // Update the insufficient materials info based on current inventory
      setInsufficientMaterialsInfo({
        rods: fishingRodQuantity,
        worms: wormQuantity,
        requiredRods: 1,
        requiredWorms: 5,
      });
      setInsufficientMaterialsDialogOpen(true); // Show dialog
      return; // Prevent fishing attempt
    }

    setFishingAttempts((prevAttempts) => prevAttempts + 1);
    setWormQuantity((prevQuantity) => Math.max(prevQuantity - 5, 0));

    const currentFailPercentage =
      getFailPercentageForNextLevel(locationLevel, targetLocationId) ||
      failPercentage;

    // Introduce a 40% chance of failing to catch any fish directly
    const failChance = Math.random();
    if (failChance <= currentFailPercentage) {
      setNotification({ open: true, message: "Fishing Failed" });
      return;
    }

    // Adjust the remaining probability for success (60%) among the available fish
    const totalProbability = fishDetails.reduce((total, fish) => {
      const fishDetail =
        userInventoryRef.current.itemDetails.find(
          (item) => item.itemId === fish.itemId
        ) ||
        fishInventoryRef.current[fish.itemId] ||
        fish;
      const fishProbability = fishDetail.itemProbability[0]?.probability || 0;
      return total + fishProbability;
    }, 0);

    let adjustedTotalProbability = 0;

    const adjustedProbabilities = fishDetails.map((fish) => {
      const fishDetail =
        userInventoryRef.current.itemDetails.find(
          (item) => item.itemId === fish.itemId
        ) ||
        fishInventoryRef.current[fish.itemId] ||
        fish;
      const originalProbability = fish.itemProbability[0]?.probability || 0;
      const adjustedProbability =
        (originalProbability / totalProbability) *
        (100 - currentFailPercentage * 100);
      adjustedTotalProbability += adjustedProbability;
      return { fish, fishDetail, adjustedProbability };
    });

    const sortedAdjustedProbabilities = adjustedProbabilities.sort(
      (a, b) => b.adjustedProbability - a.adjustedProbability
    );

    setAdjustedProbabilitiesState({
      adjustedProbabilities: sortedAdjustedProbabilities,
      failPercentage: currentFailPercentage,
    });

    // adjustedProbabilities.forEach(({ fishDetail, adjustedProbability }) => {
    //   console.log(
    //     `Fish: ${
    //       fishDetail.name
    //     }, Adjusted Probability: ${adjustedProbability.toFixed(2)}%`
    //   );
    // });

    // Determine which fish is caught based on adjusted probabilities
    let cumulativeProbability = 0;
    const randomChance = Math.random() * adjustedTotalProbability;
    let caughtFishDetail;

    for (const { fish, adjustedProbability } of adjustedProbabilities) {
      cumulativeProbability += adjustedProbability;
      if (randomChance <= cumulativeProbability) {
        caughtFishDetail = fish;
        break;
      }
    }

    if (caughtFishDetail) {
      handleFishCaught(caughtFishDetail);

      setFishCount((prevCount) => prevCount + 1);
      setNotification({
        open: true,
        message: `You caught a ${caughtFishDetail.name}!`,
      });
    } else {
      setNotification({ open: true, message: "Fishing Failed" });
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleGetWaterClick = async () => {
    let itemId;
    switch (location.locWater) {
      case "fresh":
        itemId = 31; // ID for fresh water
        break;
      case "salt":
        itemId = 32; // ID for salt water
        break;
      case "space":
        itemId = 33; // ID for space water
        break;
      default:
        console.error("Invalid water type");
        return;
    }

    // Call the onGather function with the appropriate itemId
    try {
      await onGather(itemId, false);
      console.log(`Successfully gathered water with itemId: ${itemId}`);
    } catch (error) {
      console.error("Error gathering water:", error);
    }
  };

  const handleOpenInventory = () => {
    setInventoryDialogOpen(true);
  };

  const handleCloseInventory = () => {
    setInventoryDialogOpen(false);
  };

  const handleStoreFish = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    // Prepare fishData based on caughtFish with valid quantities

    console.log("items: ", items);
    console.log("caughtFish: ", caughtFish);
    console.log("userInventoryRef: ", userInventoryRef.current.itemDetails);

    const fishData = Object.entries(caughtFish)
      .filter(([_, quantity]) => quantity > 0)
      .map(([itemId, quantity]) => {
        let fishDetail = fishInventoryRef.current[itemId];
        console.log("fishDetail: ", fishDetail);

        if (!fishDetail) {
          fishDetail = items.find((item) => item.itemId.toString() === itemId);
          if (fishDetail) {
            let currentExp = fishDetail.itemExp || fishDetail.expGain; // Default to expGain if itemExp is not defined

            // Check if it's a non-fish item and adjust currentExp accordingly
            if (fishDetail.status !== "fish") {
              const inventoryItem = userInventoryRef.current.itemDetails.find(
                (item) => item.itemId === fishDetail.itemId
              );
              currentExp = inventoryItem ? inventoryItem.itemExp : currentExp; // Use current exp from inventory if available
            }

            // Update fishInventoryRef with complete details from items array
            fishInventoryRef.current[itemId] = {
              ...fishDetail,
              itemExp: currentExp,
              expGain: fishDetail.status === "fish" ? fishDetail.expGain : 1, // Ensure non-fish items have expGain of 1
            };
          }
        }

        if (!fishDetail) {
          console.warn(`Fish detail not found for itemId: ${itemId}`);
          return null;
        }

        return {
          itemId,
          expGain: fishDetail.expGain,
          exp: fishDetail.itemExp,
          level: fishDetail.itemLvl,
          amount: quantity,
        };
      })
      .filter(Boolean);

    console.log("fishData: ", fishData);

    const rodsToRemove = Math.floor(fishingAttempts / 10);
    removeFishingRods(fishingAttempts);

    const removeWorms = () => {
      setWormQuantity((prevQuantity) =>
        Math.max(prevQuantity - fishingAttempts * 5, 0)
      );
    };

    removeWorms(fishingAttempts);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/fish/storeFish`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            fishData,
            rodsToRemove,
            locationId: location.locationId,
            fishingAttempts,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);

        // Locally update userInventoryRef with the new fish data, ensuring we're only adding valid entries
        const newItemDetails = [...userInventoryRef.current.itemDetails];

        fishData.forEach((fish) => {
          if (!fish) return;
          const index = newItemDetails.findIndex(
            (item) => item.itemId === fish.itemId
          );
          if (index !== -1) {
            newItemDetails[index].itemLevel = fish.level;
            if (newItemDetails[index].quantity) {
              newItemDetails[index].quantity += fish.amount;
            } else {
              newItemDetails[index].quantity = fish.amount;
            }
          } else {
            const fishDetail =
              fishInventoryRef.current[fish.itemId] ||
              items.find((item) => item.itemId === fish.itemId);
            newItemDetails.push({
              itemId: fish.itemId,
              itemName: fishDetail.name,
              itemLevel: fish.level,
              itemType: "fished",
              imageUrl: fishDetail.imageUrl,
              quantity: fish.amount,
            });
          }
        });

        // Update the inventory reference; use a state setter if userInventoryRef is part of the state
        userInventoryRef.current = {
          ...userInventoryRef.current,
          itemDetails: newItemDetails,
        };

        // Reset local states
        resetFishAmounts();
        setStorageSuccessDialogOpen(true);
      } else {
        console.error(
          "Failed to store fish and update rods",
          await response.text()
        );
      }
    } catch (error) {
      console.error("Error storing fish and updating rods:", error);
    } finally {
      resetFishAmounts();
    }
  };

  const handleSellFish = async () => {
    console.log("Selling fishes:", fishInventoryRef.current);

    // Calculate the total value from selling the fish
    let totalValue = 0;
    Object.entries(caughtFish).forEach(([itemId, quantity]) => {
      if (quantity > 0) {
        const fishValue = fishInventoryRef.current[itemId]?.value || 0;
        totalValue += fishValue * quantity;
      }
    });

    const rodsToRemove = Math.floor(fishingAttempts / 10);
    removeFishingRods(fishingAttempts);

    const removeWorms = () => {
      setWormQuantity((prevQuantity) =>
        Math.max(prevQuantity - fishingAttempts * 5, 0)
      ); // Deduct 5 worms per attempt
    };

    removeWorms(fishingAttempts);

    // Find bronze coins in userInventoryRef and update its amount
    const bronzeCoinIndex = userInventoryRef.current.itemDetails.findIndex(
      (item) => item.itemId === "45"
    );

    if (bronzeCoinIndex !== -1) {
      // Update the quantity of bronze coins
      userInventoryRef.current.itemDetails[bronzeCoinIndex].quantity +=
        totalValue;
    } else {
      // If bronze coins are not in the inventory, add them
      userInventoryRef.current.itemDetails.push({
        itemId: "45",
        itemName: "Fish Coin", // Assuming you have a name field for items
        quantity: totalValue,
        itemType: "currency", // Assuming you have a type field for items
      });
    }

    // Now proceed to inform the backend about the sale for data consistency, without waiting for the total value
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/fish/sellFish`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId,
            fishData: Object.entries(caughtFish)
              .filter(([_, q]) => q > 0)
              .map(([itemId, quantity]) => ({ itemId, amount: quantity })),
            locationId: location.locationId,
            fishingAttempts,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to sell fish");
      // You might want to update the state or UI based on the successful sale
      console.log("Fish sold successfully");

      setBronzeCoinsEarned(totalValue);
      setBronzeCoinsDialogOpen(true);
    } catch (error) {
      console.error("Error during fish sale:", error);
    } finally {
      resetFishAmounts();
    }

    // Reset local states
    resetFishAmounts();
  };

  const resetFishAmounts = () => {
    Object.keys(fishInventoryRef.current).forEach((itemId) => {
      if (fishInventoryRef.current[itemId].amount !== undefined) {
        fishInventoryRef.current[itemId].amount = 0;
      }
    });

    setCaughtFish({});
    setFishCount(0);
    setFishingAttempts(0);
  };

  const removeFishingRods = (count) => {
    // Determine the amount of rods to remove
    const rodsToRemove = Math.ceil(count / 10);
    const fishingRodId = "16"; // Assuming '16' is the itemId for fishing rods
    const wormId = "11"; // Assuming '11' is the itemId for worms

    // Remove fishing rods
    const fishingRodIndex = userInventoryRef.current.itemDetails.findIndex(
      (item) => item.itemId === fishingRodId
    );
    if (fishingRodIndex !== -1) {
      let currentQuantity = parseInt(
        userInventoryRef.current.itemDetails[fishingRodIndex].quantity,
        10
      );
      currentQuantity = Math.max(currentQuantity - rodsToRemove, 0); // Ensure quantity doesn't go negative
      userInventoryRef.current.itemDetails[fishingRodIndex].quantity =
        currentQuantity;

      // Optionally trigger a re-render or update UI
      console.log(`Updated fishing rods quantity to ${currentQuantity}`);
    } else {
      console.warn("Fishing rods not found in inventory.");
    }

    // Remove worms
    const wormsToRemove = count * 5; // Assuming 5 worms per fishing attempt
    const wormIndex = userInventoryRef.current.itemDetails.findIndex(
      (item) => item.itemId === wormId
    );
    if (wormIndex !== -1) {
      let currentQuantity = parseInt(
        userInventoryRef.current.itemDetails[wormIndex].quantity,
        10
      );
      currentQuantity = Math.max(currentQuantity - wormsToRemove, 0); // Ensure quantity doesn't go negative
      userInventoryRef.current.itemDetails[wormIndex].quantity =
        currentQuantity;

      // Optionally trigger a re-render or update UI
      console.log(`Updated worm quantity to ${currentQuantity}`);
    } else {
      console.warn("Worms not found in inventory.");
    }

    // Update state or UI accordingly
    setFishingRodQuantity((prevQuantity) =>
      Math.max(prevQuantity - rodsToRemove, 0)
    );
  };

  const isFishInInventory = (fishItemId) => {
    // Check if the fish is already caught
    if (caughtFish[fishItemId] && caughtFish[fishItemId] > 0) {
      return true;
    }

    // Check if the fish exists in the userInventoryRef
    return userInventoryRef.current.itemDetails.some(
      (item) => item.itemId === fishItemId
    );
  };

  const handleBackClick = () => {
    if (fishCount > 0) {
      console.log("fishcount", fishCount);
      setArrowBackDialog(true);
    } else {
      onBackToFishingTab();
    }
  };

  const handleContinueClick = () => {
    onBackToFishingTab();
  };

  const handleButtonRelease = (holdDuration) => {
    if (holdDuration >= 3000) {
      const autoFishItemExists = userInventoryRef.current.purchasedItems.some(
        (item) =>
          item.productName === `${location.locationName} Auto Fish` &&
          item.status === "purchased"
      );
      setAutoFishingEnabled(autoFishItemExists);
    } else {
      startFishing();
    }
    setButtonHoldStart(null);
  };

  // Remove the unnecessary button and effect hook for background setting
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${location.imageLocationBGUrl})`,
        backgroundSize: "cover",
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: 1,
          left: 2.5,
        }}
      >
        <IconButton onClick={handleBackClick}>
          <ArrowBack
            sx={{
              color: location.locTag === "spacial-fish" ? "white" : "black",
              height: 25,
              width: 50,
              border: `1px solid ${
                location.locTag === "spacial-fish" ? "white" : "black"
              }`,
              borderRadius: "15px",
            }}
          />
        </IconButton>
        <IconButton
          onClick={toggleProbabilityDialog}
          sx={{
            height: 25,
            width: 50,
            marginLeft: "5px",
            color: location.locTag === "spacial-fish" ? "white" : "black", // Match ArrowBack color
            border: `1px solid ${
              location.locTag === "spacial-fish" ? "white" : "black"
            }`,
            borderRadius: "15px",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor:
                location.locTag === "spacial-fish"
                  ? "rgba(255, 255, 255, 0.1)"
                  : "rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <PercentIcon sx={{ fontSize: "1.5rem" }} />
        </IconButton>
      </Box>
      {fishDetails.map((fish, index) => (
        <Card
          key={index}
          sx={{
            position: "absolute",
            top: `${40 + index * 60}px`,
            left: "10px",
            maxWidth: "100px",
            cursor: "pointer",
            filter: isFishInInventory(fish.itemId.toString())
              ? "none"
              : "grayscale(100%)",
          }}
          onClick={() => handleFishClick(fish)}
        >
          <CardMedia
            component="img"
            image={fish.imageUrl}
            alt={`Fish ${fish.name}`}
            sx={{ height: "50px", objectFit: "contain" }}
          />
        </Card>
      ))}

      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "140px",
          height: "40px",
        }}
      >
        {isFishing ? (
          <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <LinearProgress
                variant="determinate"
                value={fishingProgress}
                sx={{ width: "100%", height: "100%" }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Typography variant="body2" component="div">{`${Math.round(
                  fishingProgress
                )}%`}</Typography>
              </Box>
              {autoFishingEnabled && (
                <IconButton
                  onClick={() => {
                    setAutoFishingEnabled(false);
                    setIsFishing(false);
                  }}
                  sx={{
                    color: "red",
                    position: "absolute",
                    right: 25,
                  }}
                >
                  <ReplayIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        ) : (
          <>
            <Tooltip
              title={
                !autoFishingEnabled &&
                userInventoryRef.current.purchasedItems.some(
                  (item) =>
                    item.productName === `${location.locationName} Auto Fish` &&
                    item.status === "purchased"
                )
                  ? "To auto fish, hold button for 3 seconds then release."
                  : ""
              }
              placement="top"
              arrow
            >
              <Button
                variant="contained"
                // Mouse event handlers
                onMouseDown={() => setButtonHoldStart(Date.now())}
                onMouseUp={() => {
                  const holdDuration = Date.now() - buttonHoldStart;
                  handleButtonRelease(holdDuration);
                }}
                onMouseLeave={() => setButtonHoldStart(null)} // Cancel if the mouse leaves the button
                // Touch event handlers
                onTouchStart={() => setButtonHoldStart(Date.now())}
                onTouchEnd={() => {
                  const holdDuration = Date.now() - buttonHoldStart;
                  handleButtonRelease(holdDuration);
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {autoFishingEnabled ? "AUTO FISH" : "FISH"}
              </Button>
            </Tooltip>
          </>
        )}
      </Box>
      <Button
        variant="contained"
        onClick={handleGetWaterClick}
        sx={{ position: "absolute", bottom: 20, right: 20 }}
      >
        Get Water
      </Button>
      <Box
        sx={{
          position: "absolute",
          top: 20,
          right: 20,
          padding: "10px",
          cursor: "pointer",
        }}
        onClick={handleOpenInventory}
      >
        <Badge badgeContent={fishCount} color="error">
          <ShoppingBasketIcon
            sx={{
              fontSize: 40,
              color: location.locTag === "spacial-fish" ? "white" : "black",
              borderRadius: "15px",
            }}
          />
        </Badge>
      </Box>

      <Dialog
        open={inventoryDialogOpen}
        onClose={handleCloseInventory}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>Inventory</DialogTitle>
        <DialogContent>
          {location.locTag === "spacial-fish" ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fish</TableCell>
                  <TableCell align="right">Level</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(fishInventoryRef.current)
                  .map((fish) => ({
                    ...fish,
                    quantity: caughtFish[fish.itemId] || 0,
                  }))
                  .filter((fish) => fish.quantity > 0)
                  .sort((a, b) => b.quantity - a.quantity)
                  .map((fish, index) => (
                    <TableRow key={index}>
                      <TableCell>{fish.name}</TableCell>
                      <TableCell align="right">{fish.itemLvl}</TableCell>
                      <TableCell align="right">{fish.quantity}</TableCell>
                    </TableRow>
                  ))}
                {Object.keys(caughtFish).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No fish caught yet.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fish | Item</TableCell>
                  <TableCell align="right">Level</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(caughtFish)
                  .filter(
                    ([itemId, quantity]) =>
                      quantity > 0 &&
                      location.fishItemIds.includes(parseInt(itemId))
                  )
                  .map(([itemId, quantity]) => {
                    const itemDetail =
                      fishInventoryRef.current[itemId] ||
                      items.find((item) => item.itemId.toString() === itemId);
                    if (!itemDetail) {
                      console.warn(`Detail not found for itemId: ${itemId}`);
                      return null;
                    }
                    return {
                      ...itemDetail,
                      quantity,
                    };
                  })
                  .filter(Boolean)
                  .sort((a, b) => b.quantity - a.quantity)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">
                        {item.itemLvl || "N/A"}
                      </TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                    </TableRow>
                  ))}
                {Object.keys(caughtFish).length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3}>No items caught yet.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStoreFish}
            color="primary"
            disabled={fishCount === 0}
          >
            Store
          </Button>
          <Button
            onClick={handleSellFish}
            color="secondary"
            disabled={fishCount === 0}
          >
            Sell
          </Button>
          <Button onClick={handleCloseInventory}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={fishDialogOpen}
        onClose={() => setFishDialogOpen(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            sx={{
              height: 180,
              objectFit: "cover",
              filter:
                selectedFishDetail.description === "Uncaught"
                  ? "grayscale(100%)"
                  : "none",
            }}
            image={selectedFishDetail.imgUrl}
            title={selectedFishDetail.name}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                fontFamily: '"Pixelify Sans", sans-serif',
              }}
            >
              {selectedFishDetail.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {selectedFishDetail.fishDescription}
            </Typography>
            &nbsp;
            <Typography variant="body2">
              {selectedFishDetail.description}
            </Typography>
            {selectedFishDetail.fishLvl !== undefined && (
              <Typography variant="body2">
                Level: {selectedFishDetail.fishLvl}
              </Typography>
            )}
            {selectedFishDetail.expPercentage && (
              <Typography variant="body2">
                EXP: {selectedFishDetail.expPercentage}%
              </Typography> // Display EXP Percentage
            )}
          </CardContent>
        </Card>
      </Dialog>

      <Dialog
        open={insufficientMaterialsDialogOpen}
        onClose={() => setInsufficientMaterialsDialogOpen(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Insufficient Materials</DialogTitle>
        <DialogContent>
          <Typography>You do not have enough materials to fish.</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Fishing Rods</TableCell>
                <TableCell>
                  {insufficientMaterialsInfo.rods} (Required:{" "}
                  {insufficientMaterialsInfo.requiredRods})
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Worms</TableCell>
                <TableCell>
                  {insufficientMaterialsInfo.worms} (Required:{" "}
                  {insufficientMaterialsInfo.requiredWorms})
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInsufficientMaterialsDialogOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={probabilityDialogOpen}
        onClose={toggleProbabilityDialog}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>Catch Probabilities</DialogTitle>
        <DialogContent>
          {adjustedProbabilitiesState.adjustedProbabilities.map(
            ({ fishDetail, adjustedProbability }, index) => {
              // Check if the fish name is in the user's inventory
              const isFishInInventory =
                userInventoryRef.current.itemDetails.some(
                  (item) => item.itemName === fishDetail.name
                );
              return (
                <DialogContentText key={index} style={{ marginBottom: "10px" }}>
                  {isFishInInventory ? fishDetail.name : "???"}:{" "}
                  {adjustedProbability.toFixed(2)}%
                </DialogContentText>
              );
            }
          )}
          <hr style={{ margin: "20px 0" }} />
          <DialogContentText style={{ marginTop: "10px" }}>
            Fail Probability:{" "}
            {(adjustedProbabilitiesState.failPercentage * 100).toFixed(2)}%
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleProbabilityDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={arrowBackDialog}
        onClose={() => setArrowBackDialog(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <CardContent sx={{ maxWidth: "350px" }}>
          <Typography
            variant="h4"
            component="div"
            sx={{
              textAlign: "center",
              fontFamily: '"Pixelify Sans", sans-serif',
            }}
          >
            Fish Basket
          </Typography>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            <br />
            You already caught{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "blue",
              }}
            >
              {fishCount.toLocaleString()}
            </span>{" "}
            fish!
          </Typography>
          <br />
          <Typography>
            Are you sure you want to exit? Exiting will empty your basket.
          </Typography>
        </CardContent>
        <DialogActions>
          <Button onClick={() => setArrowBackDialog(false)}>Cancel</Button>
          <Button color="error" onClick={() => handleContinueClick()}>
            Exit anyway
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={bronzeCoinsDialogOpen}
        onClose={() => setBronzeCoinsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Fish Sold"}</DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography variant="h10" component="div" gutterBottom>
              {"You have earned"}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <Typography
                variant="h7"
                component="span"
                style={{ fontWeight: "bold" }}
              >
                {bronzeCoinsEarned.toLocaleString()}
              </Typography>
              <img
                src="/assets/items/currencies/fish-coin1.webp"
                alt="Bronze Coin"
                style={{ width: 24, height: 24 }}
              />
            </Box>
            <Typography variant="h10" component="div">
              {"from selling your fish"}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBronzeCoinsDialogOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={storageSuccessDialogOpen}
        onClose={() => setStorageSuccessDialogOpen(false)}
        PaperProps={{
          sx: {
            border: "2px solid rgba(173, 216, 230)",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>{"Fish Stored"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fishes are successfully stored to your inventory.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStorageSuccessDialogOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={1000} // Adjusted to 1 second
        onClose={() => setNotification({ ...notification, open: false })}
        message={notification.message}
      />
    </Box>
  );
};

export default FishingActivity;
