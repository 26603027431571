import axios from "axios";
import SecureLS from "secure-ls";
import { useCallback } from "react";
import { levelUp } from "../../System/Levels/itemLevels";
import { getExpForNextLevel } from "../../System/Levels/userlevels";

let lastProcessTime = 0;

export const handleProcess = (
  userProfileRef,
  setUserState,
  fetchUserProfile,
  processItems,
  userInventoryRef,
  setProcessBatch,
  addNotification,
  accumulatedSkillExp,
  setAccumulatedSkillExp,
  itemLevels
) =>
  useCallback(
    async (itemId, amountToProcess) => {
      try {
        const ls = new SecureLS({ isCompression: false });
        const userDetails = ls.get("userDetails");
        const userId = userDetails ? userDetails.userId : null;

        if (!userId) {
          console.error("User ID not found in local storage");
          return;
        }

        if (!userProfileRef) {
          const fetchedUserProfile = await fetchUserProfile(userId);
          if (fetchedUserProfile) {
            setUserState((prevState) => ({
              ...prevState,
              userProfileRef: fetchedUserProfile,
            }));
          } else {
            console.error("Failed to fetch user profile");
            return;
          }
        }

        const processItem = processItems.find((item) => item.itemId === itemId);
        if (!processItem) {
          console.error("Process item not found");
          return;
        }

        // Fetch expNeeded and expGain from itemLevels
        const itemLevelData = itemLevels[processItem.itemId.toString()];
        const currentLevelInfo = itemLevelData.find(
          (levelInfo) => levelInfo.level === processItem.itemLvl
        );

        if (!currentLevelInfo) {
          console.error(
            `Level info not found for itemId: ${itemId}, level: ${processItem.itemLvl}`
          );
          return; // Exit if we cannot find the level info
        }

        let { expNeeded, expGain } = currentLevelInfo;

        if (typeof expGain !== "number" || typeof expNeeded !== "number") {
          console.error(
            `Invalid expNeeded or expGain for itemId: ${processItem.itemId}, level: ${processItem.itemLvl}`
          );
          return;
        }

        const processingSkillExpGain = currentLevelInfo
          ? currentLevelInfo.expGain
          : null;

        if (processingSkillExpGain === null || isNaN(processingSkillExpGain)) {
          console.error(
            `Invalid expGain for itemId: ${itemId}, level: ${processItem.itemLvl}`
          );
          return;
        }

        if (processingSkillExpGain === null) {
          console.error(
            `Exp gain not found for itemId: ${itemId} at level: ${processItem.itemLvl}`
          );
          return;
        }

        const userInventory = userInventoryRef.current.itemDetails;
        if (!userInventory) {
          console.error("User inventory itemDetails not found");
          return;
        }

        for (let i = 0; i < amountToProcess; i++) {
          // Simulate the item processing delay.
          await new Promise((resolve) =>
            setTimeout(resolve, processItem.processTime * 1000)
          );

          // Calculate the experience gained for processing one item.
          const skillExpGain = processingSkillExpGain;
          userProfileRef.exp += skillExpGain;

          // Check if the current experience exceeds the requirement for the next level.
          while (
            userProfileRef.exp >= getExpForNextLevel(userProfileRef.level)
          ) {
            // Calculate the excess experience beyond what is needed for the level-up.
            const excessExp =
              userProfileRef.exp - getExpForNextLevel(userProfileRef.level);

            // Level up the user.
            levelUp(userProfileRef);

            // Apply the excess experience towards the new level.
            userProfileRef.exp = excessExp;
          }

          // Update the accumulated skill experience.
          setAccumulatedSkillExp((prevExp) => prevExp + skillExpGain);

          // Update the application state with the new user profile information.
          setUserState((prevState) => ({
            ...prevState,
            userProfile: { ...userProfileRef },
          }));
        }

        // Check if the user has the necessary materials
        // const hasMaterials = processItem.itemProcess.every((process) => {
        //   const inventoryItem = userInventory.find(
        //     (invItem) => invItem.itemId.toString() === process.itemId.toString()
        //   );
        //   return (
        //     inventoryItem &&
        //     parseInt(inventoryItem.quantity, 10) >=
        //       parseInt(process.itemAmount, 10)
        //   );
        // });

        // if (!hasMaterials) {
        //   console.error("Not enough materials to process");
        //   return;
        // }

        const currentTime = Date.now();
        const timeDiff = currentTime - lastProcessTime;
        const processDelay = 0;

        if (timeDiff < processDelay) {
          await new Promise((resolve) =>
            setTimeout(resolve, processDelay - timeDiff)
          );
        }

        // Process the item (API call)
        const payload = {
          itemIds: [{ itemId, amount: amountToProcess, expGain, expNeeded }],
        };

        // Process the item (API call)
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/batchProcess`,
          payload
        );

        // After successful processing, update experience and check for level up
        let newExp = processItem.itemExp + expGain * amountToProcess;
        while (
          newExp >= expNeeded &&
          processItem.itemLvl < itemLevelData.length
        ) {
          // Level up the item
          newExp -= expNeeded; // Remove exp for next level
          processItem.itemLvl++; // Increment level

          // Update expNeeded and expGain for the next level
          const nextLevelInfo = itemLevelData[processItem.itemLvl - 1];
          if (nextLevelInfo) {
            expNeeded = nextLevelInfo.expNeeded;
            processItem.expGain = nextLevelInfo.expGain;
          }
        }
        processItem.itemExp = newExp;

        lastProcessTime = Date.now();

        // // Update user's inventory after processing
        // processItem.itemProcess.forEach((process) => {
        //   const inventoryItem = userInventory.find(
        //     (invItem) => invItem.itemId === process.itemId
        //   );
        //   if (inventoryItem) {
        //     inventoryItem.quantity -= process.itemAmount; // Subtract the used quantity
        //   }
        // });

        // // Update the userInventoryRef
        // userInventoryRef.current.itemDetails = userInventory;

        // Add the processed item to the batch
        setProcessBatch((prevBatch) => ({
          ...prevBatch,
          [itemId]: (prevBatch[itemId] || 0) + 1,
        }));

        const userExpPercentage =
          (userProfileRef.exp / getExpForNextLevel(userProfileRef.level)) * 100;

        addNotification({
          id: Date.now(),
          name: processItem.name,
          imageUrl: processItem.imageUrl,
          message: `${amountToProcess}`,
          expPercentage: userExpPercentage.toFixed(2),
        });

        setAccumulatedSkillExp((prevExp) => prevExp + processingSkillExpGain);

        // Handle item level up if needed
        const inventoryItem = userInventory.find(
          (invItem) => invItem.itemId === itemId
        );
        if (inventoryItem) {
          levelUp(inventoryItem);
        }
      } catch (error) {
        console.error("Error processing item:", error);
      }
    },
    [
      userProfileRef,
      setUserState,
      fetchUserProfile,
      processItems,
      userInventoryRef,
      setProcessBatch,
      addNotification,
      accumulatedSkillExp,
      setAccumulatedSkillExp,
      itemLevels,
    ]
  );
