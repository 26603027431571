import React, { useState, useEffect } from "react";
import SecureLS from "secure-ls";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const ProfileTab = ({ userProfile }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedFile(null);
  };

  const handleImageUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return; // No file selected, do nothing
    }

    const formData = new FormData();
    formData.append("profileImg", selectedFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/users/${userProfile.id}/upload-image`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      setIsDialogOpen(false); // Close the dialog
      setSelectedFile(null); // Reset selected file
    } catch (error) {
      console.error("Error uploading profile image:", error);
      // Handle error here, such as showing a message to the user
    }
  };

  console.log("userProfile: ", userProfile);

  return (
    <>
      <Card sx={{ maxWidth: 400, margin: "auto", mb: 2 }}>
        <CardContent style={{ display: "flex", alignItems: "center" }}>
          {/* Display profile image */}
          {userProfile.profileImg && (
            <img
              src={`/assets/profile/${userProfile.profileImg}`}
              alt="Profile"
              style={{ width: 100, height: 100, marginRight: 20 }}
            />
          )}

          <div>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: '"Pixelify Sans", sans-serif',
              }}
            >
              Hoarder Profile
            </Typography>
            <Typography variant="body1">{userProfile.name}</Typography>
            <Typography variant="body1">Level: {userProfile.level}</Typography>
            <Typography variant="body1">
              Current EXP: {userProfile.exp.toFixed(2)}/
              {userProfile.expNeededForNextLevel} (
              {(
                (userProfile.exp / userProfile.expNeededForNextLevel) *
                100
              ).toFixed(2)}
              %)
            </Typography>
          </div>
        </CardContent>
      </Card>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" onClick={handleUploadClick}>
          Upload Image
        </Button>
      </div>
      <Dialog onClose={handleDialogClose} open={isDialogOpen}>
        <DialogTitle>Upload Profile Image</DialogTitle>
        <DialogContent>
          <input type="file" onChange={handleImageUpload} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleUpload} disabled={!selectedFile}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileTab;
