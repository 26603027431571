import React, { useEffect } from "react";
import { useNotifications } from "./NotificationManager";
import { useSpring, animated } from "react-spring";
import { Box, Typography } from "@mui/material";

const NotificationItem = ({ notification, onRemove }) => {
  // Fade in
  const fadeInProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  // Fade out
  const fadeOutProps = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { duration: 2000 },
    delay: 1500,
    onRest: () => onRemove(notification.id),
  });

  useEffect(() => {
    const timer = setTimeout(() => onRemove(notification.id), 3500); // Total time = 0.5s + 1.5s + 2s
    return () => clearTimeout(timer);
  }, [notification, onRemove]);

  return (
    <animated.div style={fadeOutProps}>
      <animated.div style={fadeInProps}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            padding: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            mb: 1,
          }}
        >
          <img
            src={notification.imageUrl || "/assets/items/default.png"}
            alt={notification.name}
            style={{ width: "30px", height: "30px", marginRight: "8px" }}
          />
          <Typography variant="body1" style={{ marginRight: "8px" }}>
            {notification.name}
          </Typography>
          <Typography variant="body1" style={{ color: "green" }}>
            {notification.message ? `+${notification.message}` : "+1"}
          </Typography>
          {/* Display EXP percentage */}
          <Typography
            variant="body2"
            style={{ marginLeft: "8px", color: "blue" }}
          >
            EXP: {notification.expPercentage}%
          </Typography>
        </Box>
      </animated.div>
    </animated.div>
  );
};

const NotificationsDisplay = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <Box sx={{ position: "fixed", bottom: 10, left: 10, zIndex: 1000 }}>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          onRemove={removeNotification}
        />
      ))}
    </Box>
  );
};

export default NotificationsDisplay;
