import React, { useState, useEffect } from "react";
import { useTabContext } from "../../TabProvider";
import SecureLS from "secure-ls";
import {
  Box,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from "@mui/material";
import { useExp } from "../../ExpContext";
import { sortedInventory, requestSort } from "./SortingUtils";
import { itemLevels } from "../../../System/Levels/itemLevels";
import "../../../../../src/css/inventory.css";

export default function InventoryTab({
  filterType,
  updatedInventory,
  localInventory,
  shouldFetchInventory,
  onInventoryFetched,
  userInventoryRef,
  setLocalInventory,
}) {
  const { setActiveTab } = useTabContext();
  const [inventory, setInventory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { expPercentages, calculateAndUpdateExpPercentages } = useExp();
  const [sortConfig, setSortConfig] = useState({
    key: "itemId",
    direction: "ascending",
  });
  const [isSellDialogOpen, setIsSellDialogOpen] = useState(false);
  const [selectedItemForSale, setSelectedItemForSale] = useState(null);
  const [sellQuantity, setSellQuantity] = useState(0);
  const [userId, setUserId] = useState(null);
  const [isInventoryFetched, setIsInventoryFetched] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [bronzeCoinValue, setBronzeCoinValue] = useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setBronzeCoinValue(0);
    setSellQuantity(0);
  };

  const getLocalExpNeeded = (itemId, level) => {
    const levels = itemLevels[itemId];
    const levelInfo = levels?.find((l) => l.level === level);
    return levelInfo ? levelInfo.expNeeded : null;
  };

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userIdFromStorage = userDetails ? userDetails.userId : null;
    setUserId(userIdFromStorage);
  }, []);

  useEffect(() => {
    if (userInventoryRef && Array.isArray(userInventoryRef.inventory)) {
      const sortedInventory = userInventoryRef.inventory.sort(
        (a, b) => Number(a.itemId) - Number(b.itemId)
      );
      setInventory(sortedInventory);
    }

    const fetchInventory = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/${userId}`
        );
        if (response.ok) {
          const inventoryResponse = await response.json();
          const inventoryData = inventoryResponse.itemDetails;

          // Ensure inventoryData is an array before sorting
          if (Array.isArray(inventoryData)) {
            const sortedData = inventoryData.sort(
              (a, b) => a.itemId - b.itemId
            );
            setInventory(sortedData);
          } else {
            console.error("Invalid inventory data format");
          }

          onInventoryFetched();
        } else {
          console.error("Failed to fetch inventory data");
        }
      } catch (error) {
        console.error("Error while fetching inventory data", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (shouldFetchInventory && userId && !isInventoryFetched) {
      fetchInventory();
    }
  }, [
    shouldFetchInventory,
    userId,
    isInventoryFetched,
    onInventoryFetched,
    userInventoryRef,
    isSuccessDialogOpen,
    setActiveTab,
  ]);

  useEffect(() => {
    let timer;
    if (isSuccessDialogOpen) {
      timer = setTimeout(() => {
        setIsSuccessDialogOpen(false);
      }, 1000); // Close dialog after 1 second
    }
    return () => clearTimeout(timer); // Clear timer on component unmount or if isSuccessDialogOpen changes
  }, [isSuccessDialogOpen]);

  // Function to close dialog
  const handleCloseSellDialog = () => {
    setIsSellDialogOpen(false);
    setSelectedItemForSale(null);
    setSellQuantity(0);
    setBronzeCoinValue(0);
  };

  // Function to handle selling
  const handleSellItem = async (itemId) => {
    try {
      if (!userId) {
        console.error("User ID not found in local storage");
        return;
      }

      if (!selectedItem) {
        console.error("No selected item for sale");
        return;
      }

      const requestBody = {
        itemId,
        quantityToSell: sellQuantity,
        itemValue: selectedItem.itemValue,
      };

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/sell/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const updatedInventory = await response.json();
        if (updatedInventory && Array.isArray(updatedInventory.inventory)) {
          // Update the state of Inventory
          // Local inventory update
          setInventory((prevInventory) => {
            const newInventory = [...prevInventory];
            const itemIndex = newInventory.findIndex(
              (item) => item.itemId === itemId
            );
            const coinIndex = newInventory.findIndex(
              (item) => item.itemId === "5"
            ); // Replace "coinId" with the actual ID for coins

            if (itemIndex !== -1) {
              newInventory[itemIndex].quantity -= sellQuantity;
              if (newInventory[itemIndex].quantity <= 0) {
                newInventory.splice(itemIndex, 1); // Remove the item if quantity is 0 or less
              }
            }

            if (coinIndex !== -1) {
              // Update coin quantity
              newInventory[coinIndex].quantity += bronzeCoinValue; // Adjust this logic based on how you calculate coin quantity
            } else {
              // Add coins to the inventory if they don't exist
              newInventory.push({
                itemId: "5",
                quantity: bronzeCoinValue /* other coin properties */,
              });
            }

            return newInventory;
          });

          setIsSuccessDialogOpen(true);
          handleCloseSellDialog();
        } else {
          console.error("Failed to sell item. Response:", response);
        }
      } else {
        console.error("Failed to sell item. Response:", response);
      }
    } catch (error) {
      console.error("Error selling item:", error);
    }
  };

  useEffect(() => {
    const calculateAndUpdateExpPercentagesLocally = () => {
      const newExpPercentages = {};
      for (let item of inventory) {
        if (!expPercentages.hasOwnProperty(item.itemId)) {
          const expNeeded = getLocalExpNeeded(item.itemId, item.itemLevel);
          if (expNeeded !== null) {
            newExpPercentages[item.itemId] = (item.itemExp / expNeeded) * 100;
          }
        }
      }
      calculateAndUpdateExpPercentages(newExpPercentages);
    };

    if (inventory.length > 0) {
      calculateAndUpdateExpPercentagesLocally();
    }
  }, [inventory, expPercentages, calculateAndUpdateExpPercentages]);

  useEffect(() => {
    // Remove the merging logic here and directly set the inventory
    if (updatedInventory) {
      setInventory(updatedInventory);
    }
  }, [updatedInventory]);

  // Filtering of Inventory to itemType tabs
  let filteredInventory;
  if (filterType === "all" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(inventory, sortConfig);
  } else if (filterType === "materials" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "material"),
      sortConfig
    );
  } else if (filterType === "processed" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "processed"),
      sortConfig
    );
  } else if (filterType === "crafted" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "crafted"),
      sortConfig
    );
  } else if (filterType === "currency" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "currency"),
      sortConfig
    );
  } else if (filterType === "fish" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "fished"),
      sortConfig
    );
  } else if (filterType === "seeds" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "seeds"),
      sortConfig
    );
  } else if (filterType === "produce" && Array.isArray(inventory)) {
    filteredInventory = sortedInventory(
      inventory.filter((item) => item.itemType === "harvested"),
      sortConfig
    );
  } else {
    filteredInventory = [];
  }

  const handleQuantityChange = (e) => {
    let newQuantity = e.target.value;
    newQuantity = Math.max(0, newQuantity);

    const maxQuantity = selectedItem?.quantity || 0;
    let quantity =
      newQuantity === ""
        ? ""
        : Math.min(parseInt(newQuantity, 10), maxQuantity);
    setSellQuantity(quantity);

    // Calculate and set bronze coin value based on new quantity
    const newBronzeValue = quantity * (selectedItem?.itemValue || 0);
    setBronzeCoinValue(newBronzeValue);
  };

  const handleBronzeCoinChange = (e) => {
    let newValue = e.target.value;
    newValue = Math.max(0, newValue);

    const maxPossibleValue = selectedItem
      ? selectedItem.itemValue * selectedItem.quantity
      : 0;
    let bronzeValue =
      newValue === "" ? "" : Math.min(parseInt(newValue, 10), maxPossibleValue);
    setBronzeCoinValue(bronzeValue);

    // Calculate and set sell quantity based on new bronze coin value
    const newQuantity = Math.floor(
      bronzeValue / (selectedItem?.itemValue || 1)
    );
    setSellQuantity(newQuantity);
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="inventory-container">
          <div className="inventory-grid">
            {filteredInventory.map((item) => (
              <div
                key={item.id}
                className="inventory-item"
                onClick={() => handleItemClick(item)}
                style={{
                  backgroundColor:
                    item.quantity === 0
                      ? "rgba(255, 0, 0, 0.5)"
                      : "transparent",
                }}
              >
                <img src={item.imageUrl} alt={item.itemName} />
              </div>
            ))}
          </div>
          <div className="item-details">
            {selectedItem ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <h3>{selectedItem.itemName}</h3>
                  <img
                    src={selectedItem.imageUrl}
                    alt={selectedItem.itemName}
                    style={{ width: "50px", height: "50px" }}
                  />
                </div>
                <div>Quantity: {selectedItem.quantity.toLocaleString()}</div>
                <div>Value: {selectedItem.itemValue}</div>
                <div>Level: {selectedItem.itemLevel}</div>
                <div className="sell-section">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 1,
                      }}
                    ></Box>
                    <TextField
                      type="number"
                      value={sellQuantity}
                      onChange={handleQuantityChange}
                      label="Quantity"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: selectedItem.quantity,
                        },
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 2,
                      }}
                    >
                      <Box
                        component="img"
                        src="/assets/items/currencies/bronze-coin2.webp"
                        alt="Bronze Coin"
                        sx={{ width: 24, height: 24, marginRight: 1 }}
                      />
                      <span>Bronze Coin</span>
                    </Box>
                    <TextField
                      type="number"
                      value={bronzeCoinValue}
                      onChange={handleBronzeCoinChange}
                      label="Bronze Coin Value"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: selectedItem
                            ? selectedItem.itemValue * selectedItem.quantity
                            : 0,
                        },
                      }}
                    />
                  </Box>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      onClick={() => handleSellItem(selectedItem?.itemId)}
                      disabled={
                        sellQuantity === "" ||
                        sellQuantity <= 0 ||
                        bronzeCoinValue <= 0 ||
                        (selectedItem && selectedItem.itemId === "5")
                      }
                      sx={{ color: "red" }}
                    >
                      Sell
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <h3 style={{ textAlign: "center" }}>Select an item</h3>
            )}
          </div>
        </div>
      )}
      <Dialog
        open={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
      >
        <DialogTitle>Success</DialogTitle>
        <DialogContent>
          <DialogContentText>Item sold successfully.</DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
