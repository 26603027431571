let levels;

function loadUserLevels() {
  if (!levels) {
    levels = require("./userlevelsData.js");
    console.log("User levels loaded.");
  }
}

function getExpForNextLevel(currentLevel) {
  loadUserLevels();
  const levelInfo = levels.find((l) => l.level === currentLevel);
  return levelInfo ? levelInfo.expNeeded : null;
}

function levelUp(user) {
  loadUserLevels();
  if (user.level < levels.length) {
    user.level += 1;
    user.exp = 0; // Reset EXP after leveling up

    if (user.level - 1 < levels.length) {
      user.expNeeded = levels[user.level - 1].expNeeded;
    } else {
      // Set expNeeded to a very large number to simulate infinity
      user.expNeeded = Number.MAX_SAFE_INTEGER;
    }
  }
}

// Ensure levels are loaded when accessed directly
loadUserLevels();

module.exports = { getExpForNextLevel, levelUp };
