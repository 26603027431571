let skillLevels;

function loadSkillLevels() {
  if (!skillLevels) {
    skillLevels = require("./skillLevelsData.js");
    console.log("Skill levels loaded.");
  }
}

function getExpForNextSkillLevel(currentLevel, skillId) {
  loadSkillLevels();
  const skillLevelInfo = skillLevels[skillId];
  if (skillLevelInfo) {
    const levelInfo = skillLevelInfo.find((l) => l.level === currentLevel);
    return levelInfo ? levelInfo.expNeeded : null;
  }
  return null;
}

function levelUpSkill(skill) {
  loadSkillLevels();
  const skillLevelInfo = skillLevels[skill.skillId];
  if (skillLevelInfo) {
    const currentLevelIndex = skill.skillLevel - 1;
    if (
      currentLevelIndex + 1 < skillLevelInfo.length &&
      skill.skillExp >= skillLevelInfo[currentLevelIndex].expNeeded
    ) {
      skill.skillLevel += 1;
      skill.skillExp = 0;

      if (skill.skillLevel - 1 < skillLevelInfo.length) {
        skill.expNeeded = skillLevelInfo[skill.skillLevel - 1].expNeeded;
      } else {
        // Set expNeeded to a very large number to simulate infinity
        skill.expNeeded = Number.MAX_SAFE_INTEGER;
      }
    }
  }
}

// Ensure skillLevels is loaded when accessed directly
loadSkillLevels();

module.exports = { skillLevels, getExpForNextSkillLevel, levelUpSkill };
