const levels = [
  { level: 1, expNeeded: 250 },
  { level: 2, expNeeded: 500 },
  { level: 3, expNeeded: 888 },
  { level: 4, expNeeded: 1000 },
  { level: 5, expNeeded: 2500 },
  { level: 6, expNeeded: 4000 },
  { level: 7, expNeeded: 5500 },
  { level: 8, expNeeded: 7000 },
  { level: 9, expNeeded: 8525 },
  { level: 10, expNeeded: 10000 },
  { level: 11, expNeeded: 20000 },
  { level: 12, expNeeded: 35000 },
  { level: 13, expNeeded: 47500 },
  { level: 14, expNeeded: 55000 },
  { level: 15, expNeeded: 67500 },
  { level: 16, expNeeded: 75000 },
  { level: 17, expNeeded: 85500 },
  { level: 18, expNeeded: 90000 },
  { level: 19, expNeeded: 95500 },
  { level: 20, expNeeded: 100000 },
  { level: 21, expNeeded: 110000 },
  { level: 22, expNeeded: 115000 },
  { level: 23, expNeeded: 117500 },
  { level: 24, expNeeded: 125000 },
  { level: 25, expNeeded: 137500 },
  { level: 26, expNeeded: 145000 },
  { level: 27, expNeeded: 155500 },
  { level: 28, expNeeded: 166000 },
  { level: 29, expNeeded: 175500 },
  { level: 30, expNeeded: 100000 },
  { level: 31, expNeeded: 120000 },
  { level: 32, expNeeded: 145000 },
  { level: 33, expNeeded: 167000 },
  { level: 34, expNeeded: 180000 },
  { level: 35, expNeeded: 200000 },
  { level: 36, expNeeded: 250000 },
  { level: 37, expNeeded: 315000 },
  { level: 38, expNeeded: 365000 },
  { level: 39, expNeeded: 450000 },
  { level: 40, expNeeded: 600000 },
  { level: 41, expNeeded: 750000 },
  { level: 42, expNeeded: 900000 },
  { level: 43, expNeeded: 1000000 },
  { level: 44, expNeeded: 1250000 },
  { level: 45, expNeeded: 1500000 },
  { level: 46, expNeeded: 1750000 },
  { level: 47, expNeeded: 2100000 },
  { level: 48, expNeeded: 2375000 },
  { level: 49, expNeeded: 2500000 },
  { level: 50, expNeeded: 3000000 },
  { level: 51, expNeeded: 4000000 },
  { level: 52, expNeeded: 5000000 },
  { level: 53, expNeeded: 6000000 },
  { level: 54, expNeeded: 7250000 },
  { level: 55, expNeeded: 8500000 },
  { level: 56, expNeeded: 9750000 },
  { level: 57, expNeeded: 11100000 },
  { level: 58, expNeeded: 12375000 },
  { level: 59, expNeeded: 13500000 },
  { level: 60, expNeeded: 14500000 },
  { level: 61, expNeeded: 17000000 },
  { level: 62, expNeeded: 19000000 },
  { level: 63, expNeeded: 20000000 },
  { level: 64, expNeeded: 22500000 },
  { level: 65, expNeeded: 25000000 },
  { level: 66, expNeeded: 27500000 },
  { level: 67, expNeeded: 31100000 },
  { level: 68, expNeeded: 32375000 },
  { level: 69, expNeeded: 33500000 },
  { level: 70, expNeeded: Number.MAX_SAFE_INTEGER },
];

module.exports = levels;
