import React, { useState, useEffect } from "react";
import axios from "axios";
import SecureLS from "secure-ls";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import ShopItemCardsSlider from "./ShopItemCardsSlider";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShopTab = ({ onPurchase, userInventoryRef }) => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [bronzeCoinQuantity, setBronzeCoinQuantity] = useState(0);
  const [additionalCurrencyQuantity, setAdditionalCurrencyQuantity] =
    useState(0);
  const currencyItemIds = ["5", "5", "45", "52"];
  const currencyImageFilenames = [
    "bronze-coin2.webp", // Auto Gather tab
    "bronze-coin2.webp", // Items tab
    "fish-coin1.webp", // Fishing tab
    "forage-coin1.webp", // Foraging tab
  ];

  useEffect(() => {
    const userInventory = userInventoryRef.current.itemDetails;
    const bronzeCoin = userInventory.find((item) => item.itemId === "5");
    if (bronzeCoin) {
      setBronzeCoinQuantity(bronzeCoin.quantity.toFixed());
    }
    fetchAdditionalCurrencyQuantity();
  }, [userInventoryRef.current, value]);

  const fetchAdditionalCurrencyQuantity = () => {
    const userInventory = userInventoryRef.current.itemDetails;
    const additionalCurrency = userInventory.find(
      (item) => item.itemId === currencyItemIds[value]
    );
    if (additionalCurrency) {
      setAdditionalCurrencyQuantity(additionalCurrency.quantity.toFixed());
    } else {
      setAdditionalCurrencyQuantity(0);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const ls = new SecureLS({ isCompression: false });
    const userDetails = ls.get("userDetails");
    const userIdFromStorage = userDetails ? userDetails.userId : null;
    setUserId(userIdFromStorage);
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchProducts = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/v1/api/shop/products/${userId}`
          );
          setProducts(response.data);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
        setIsLoading(false);
      };

      fetchProducts();
    }
  }, [userId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredProducts = products.filter((product) =>
    product.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{
        padding: "0 20px",
        margin: "auto",
        // maxWidth: "350px",
        marginTop: "-10px",
        marginBottom: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          label="Search Products"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { borderRadius: "20px" },
          }}
        />
        <Box sx={{ marginLeft: "8px", display: "flex", alignItems: "center" }}>
          <span>
            {value === 0
              ? bronzeCoinQuantity.toLocaleString()
              : additionalCurrencyQuantity.toLocaleString()}
          </span>
          <img
            src={`/assets/items/currencies/${
              value === 0 ? "bronze-coin2.webp" : currencyImageFilenames[value]
            }`}
            style={{ height: "25px", marginRight: "4px" }}
            alt={value === 0 ? "Coin" : "Additional Currency"}
          />
        </Box>
      </Box>
      {/* <div style={{ marginTop: "7px" }}>
        {
          isLoading ? (
            <CircularProgress /> 
          ) : filteredProducts.length > 0 ? (
            <ShopItemCardsSlider
              products={filteredProducts}
              userId={userId}
              onPurchase={onPurchase}
              userInventoryRef={userInventoryRef}
            />
          ) : null 
        }
      </div> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Auto Gather" {...a11yProps(0)} />
            <Tab label="Items" {...a11yProps(1)} />
            <Tab label="Fishing" {...a11yProps(2)} />
            <Tab label="Foraging" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ShopItemCardsSlider
              products={filteredProducts.filter(
                (product) => product.shopTag === "autogather"
              )}
              userId={userId}
              onPurchase={onPurchase}
              userInventoryRef={userInventoryRef}
              setBronzeCoinQuantity={setBronzeCoinQuantity}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ShopItemCardsSlider
              products={filteredProducts.filter(
                (product) => product.shopTag === "items"
              )}
              userId={userId}
              onPurchase={onPurchase}
              userInventoryRef={userInventoryRef}
              setBronzeCoinQuantity={setBronzeCoinQuantity}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ShopItemCardsSlider
              products={filteredProducts.filter(
                (product) => product.shopTag === "fishing"
              )}
              userId={userId}
              onPurchase={onPurchase}
              userInventoryRef={userInventoryRef}
              setBronzeCoinQuantity={setBronzeCoinQuantity}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ShopItemCardsSlider
              products={filteredProducts.filter(
                (product) => product.shopTag === "foraging"
              )}
              userId={userId}
              onPurchase={onPurchase}
              userInventoryRef={userInventoryRef}
              setBronzeCoinQuantity={setBronzeCoinQuantity}
            />
          )}
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default ShopTab;
