import React from "react";
import ProcessItemCard from "./ProcessItemCard";
import { Box } from "@mui/material";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProcessItemCardsSlider = ({
  items,
  onProcess,
  gatherItems,
  userInventory,
  setUserInventory,
  processItems,
}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 0.35,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
  };

  return (
    <>
      {window.innerWidth <= 1024 ? (
        <Slider {...settings}>
          {items.map((item, idx) => (
            <Box key={idx}>
              <ProcessItemCard
                item={item}
                onProcess={onProcess}
                gatherItems={gatherItems}
                userInventory={userInventory}
                setUserInventory={setUserInventory}
                processItems={processItems}
              />
            </Box>
          ))}
        </Slider>
      ) : (
        <div style={{ overflowY: "scroll", maxHeight: "370px" }}>
          {items.map((item, idx) => (
            <Box key={idx}>
              <ProcessItemCard
                item={item}
                onProcess={onProcess}
                gatherItems={gatherItems}
                userInventory={userInventory}
                processItems={processItems}
              />
            </Box>
          ))}
        </div>
      )}
    </>
  );
};

export default ProcessItemCardsSlider;
