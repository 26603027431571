const defaultLocationLevels = [
  { level: 1, expNeeded: 1000, failPercentage: 0.4 },
  { level: 2, expNeeded: 2000, failPercentage: 0.38 },
  { level: 3, expNeeded: 3000, failPercentage: 0.36 },
  { level: 4, expNeeded: 4000, failPercentage: 0.34 },
  { level: 5, expNeeded: 5000, failPercentage: 0.32 },
  { level: 6, expNeeded: 6500, failPercentage: 0.3 },
  { level: 7, expNeeded: 8000, failPercentage: 0.28 },
  { level: 8, expNeeded: 10000, failPercentage: 0.26 },
  { level: 9, expNeeded: 12000, failPercentage: 0.24 },
  { level: 10, expNeeded: 13000, failPercentage: 0.22 },
  { level: 11, expNeeded: 14500, failPercentage: 0.2 },
  { level: 12, expNeeded: 15000, failPercentage: 0.19 },
  { level: 13, expNeeded: 18000, failPercentage: 0.18 },
  { level: 14, expNeeded: 19999, failPercentage: 0.17 },
  { level: 15, expNeeded: 30000, failPercentage: 0.16 },
  { level: 16, expNeeded: 60000, failPercentage: 0.15 },
  { level: 17, expNeeded: 95000, failPercentage: 0.145 },
  { level: 18, expNeeded: 115000, failPercentage: 0.14 },
  { level: 19, expNeeded: 140000, failPercentage: 0.135 },
  { level: 20, expNeeded: Number.MAX_SAFE_INTEGER, failPercentage: 0.13 },
];

const locationLevels = {
  0: defaultLocationLevels,
  1: defaultLocationLevels,
  2: defaultLocationLevels,
  3: defaultLocationLevels,
  4: defaultLocationLevels,
  5: defaultLocationLevels,
};

module.exports = locationLevels;
