import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import SecureLS from "secure-ls";
import { NotificationDialog } from "./NotificationDialog";

const NotificationContext = createContext();

export const useNotificationsContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({ children, userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotificationIndex, setCurrentNotificationIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/notification/all/${userId}`
      );
      const fetchedNotifications = response.data;

      // Filter notifications meant for the current user or for all users
      const relevantNotifications = fetchedNotifications.filter(notification =>
        !notification.recipientUser || notification.recipientUser === userId
      );

      setNotifications(relevantNotifications);
    } catch (error) {
      console.error("Failed to fetch notifications", error);
    }
  };

  // Fetch unread notifications for the user
  useEffect(() => {
    if (userId) {
      fetchNotifications();
    }
  }, [userId]);

  useEffect(() => {
    setDialogOpen(notifications.length > 0);
  }, [notifications]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    const nextIndex = currentNotificationIndex + 1;
    if (nextIndex < notifications.length) {
      setCurrentNotificationIndex(nextIndex);
      setDialogOpen(true);
    }
  };

  const addNotification = async (notification) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/notification/create`,
        notification
      );
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        response.data,
      ]);
    } catch (error) {
      console.error("Failed to create notification", error);
    }
  };

  const dismissNotification = async (notificationIds) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/notification/append`,
        {
          userId,
          notificationIds,
        }
      );
      setNotifications((prevNotifications) =>
        prevNotifications.filter(
          (notification) => !notificationIds.includes(notification._id)
        )
      );
    } catch (error) {
      console.error("Failed to dismiss notifications", error);
    }
  };

  const contextValue = {
    notifications,
    addNotification,
    dismissNotification,
    fetchNotifications,
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        dismissNotification,
        fetchNotifications,
      }}
    >
      {children}
      {notifications.length > 0 && (
        <NotificationDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          notifications={notifications}
          onDismiss={dismissNotification}
        />
      )}
    </NotificationContext.Provider>
  );
};
