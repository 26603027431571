import axios from "axios";
import SecureLS from "secure-ls";
import { useCallback } from "react";
import { levelUp } from "../../System/Levels/itemLevels";
import { getExpForNextLevel } from "../../System/Levels/userlevels";

let lastCraftTime = 0;

export const handleCraft = (
  userProfileRef,
  setUserState,
  fetchUserProfile,
  craftItems,
  userInventoryRef,
  setCraftBatch,
  addNotification,
  accumulatedSkillExp,
  setAccumulatedSkillExp,
  itemLevels
) =>
  useCallback(
    async (itemId, amountToCraft) => {
      try {
        const ls = new SecureLS({ isCompression: false });
        const userDetails = ls.get("userDetails");
        const userId = userDetails ? userDetails.userId : null;

        if (!userId) {
          console.error("User ID not found in local storage");
          return;
        }

        if (!userProfileRef) {
          const fetchedUserProfile = await fetchUserProfile(userId);
          if (fetchedUserProfile) {
            setUserState((prevState) => ({
              ...prevState,
              userProfileRef: fetchedUserProfile,
            }));
          } else {
            console.error("Failed to fetch user profile");
            return;
          }
        }

        const craftItem = craftItems.find((item) => item.itemId === itemId);
        if (!craftItem) {
          console.error("Craft item not found");
          return;
        }

        // Fetch expNeeded and expGain from itemLevels
        const itemLevelData = itemLevels[craftItem.itemId.toString()];
        const currentLevelInfo = itemLevelData.find(
          (levelInfo) => levelInfo.level === craftItem.itemLvl
        );

        if (!currentLevelInfo) {
          console.error(
            `Level info not found for itemId: ${itemId}, level: ${craftItem.itemLvl}`
          );
          return; // Exit if we cannot find the level info
        }

        let { expNeeded, expGain } = currentLevelInfo;

        if (typeof expGain !== "number" || typeof expNeeded !== "number") {
          console.error(
            `Invalid expNeeded or expGain for itemId: ${craftItem.itemId}, level: ${craftItem.itemLvl}`
          );
          return;
        }

        const craftingSkillExpGain = currentLevelInfo
          ? currentLevelInfo.expGain
          : null;

        if (craftingSkillExpGain === null || isNaN(craftingSkillExpGain)) {
          console.error(
            `Invalid expGain for itemId: ${itemId}, level: ${craftItem.itemLvl}`
          );
          return;
        }

        if (craftingSkillExpGain === null) {
          console.error(
            `Exp gain not found for itemId: ${itemId} at level: ${craftItem.itemLvl}`
          );
          return;
        }

        const userInventory = userInventoryRef.current.itemDetails;
        if (!userInventory) {
          console.error("User inventory itemDetails not found");
          return;
        }

        for (let i = 0; i < amountToCraft; i++) {
          // Simulate the crafting item processing delay.
          await new Promise((resolve) =>
            setTimeout(resolve, craftItem.processTime * 1000)
          );

          // Calculate the experience gained for crafting one item.
          const skillExpGain = craftingSkillExpGain;
          userProfileRef.exp += skillExpGain;

          // Check if the current experience exceeds the requirement for the next level.
          while (
            userProfileRef.exp >= getExpForNextLevel(userProfileRef.level)
          ) {
            // Calculate the excess experience beyond what is needed for the level-up.
            const excessExp =
              userProfileRef.exp - getExpForNextLevel(userProfileRef.level);

            // Level up the user.
            levelUp(userProfileRef);

            // Apply the excess experience towards the new level.
            userProfileRef.exp = excessExp;
          }

          // Update the accumulated skill experience.
          setAccumulatedSkillExp((prevExp) => prevExp + skillExpGain);

          // Update the application state with the new user profile information.
          setUserState((prevState) => ({
            ...prevState,
            userProfile: { ...userProfileRef },
          }));
        }

        // Check if the user has the necessary materials
        // const hasMaterials = craftItem.itemProcess.every((craft) => {
        //   const inventoryItem = userInventory.find(
        //     (invItem) => invItem.itemId.toString() === craft.itemId.toString()
        //   );
        //   return (
        //     inventoryItem &&
        //     parseInt(inventoryItem.quantity, 10) >=
        //       parseInt(craft.itemAmount, 10)
        //   );
        // });

        // if (!hasMaterials) {
        //   console.error("Not enough materials to Craft");
        //   return;
        // }

        const currentTime = Date.now();
        const timeDiff = currentTime - lastCraftTime;
        const craftDelay = 0;

        if (timeDiff < craftDelay) {
          await new Promise((resolve) =>
            setTimeout(resolve, craftDelay - timeDiff)
          );
        }

        // Craft the item (API call)
        const payload = {
          itemIds: [{ itemId, amount: amountToCraft, expGain, expNeeded }],
        };

        // Craft the item (API call)
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/items/${userId}/batchCraft`,
          payload
        );

        // After successful crafting, update experience and check for level up
        let newExp = craftItem.itemExp + expGain * amountToCraft;
        while (
          newExp >= expNeeded &&
          craftItem.itemLvl < itemLevelData.length
        ) {
          // Level up the item
          newExp -= expNeeded; // Remove exp for next level
          craftItem.itemLvl++; // Increment level

          // Update expNeeded and expGain for the next level
          const nextLevelInfo = itemLevelData[craftItem.itemLvl - 1];
          if (nextLevelInfo) {
            expNeeded = nextLevelInfo.expNeeded;
            craftItem.expGain = nextLevelInfo.expGain;
          }
        }
        craftItem.itemExp = newExp;

        lastCraftTime = Date.now();

        // // Update user's inventory after crafting
        // craftItem.itemProcess.forEach((craft) => {
        //   const inventoryItem = userInventory.find(
        //     (invItem) => invItem.itemId === craft.itemId
        //   );
        //   if (inventoryItem) {
        //     inventoryItem.quantity -= craft.itemAmount; // Subtract the used quantity
        //   }
        // });

        // // Update the userInventoryRef
        // userInventoryRef.current.itemDetails = userInventory;

        // Add the crafted item to the batch
        setCraftBatch((prevBatch) => ({
          ...prevBatch,
          [itemId]: (prevBatch[itemId] || 0) + 1,
        }));

        const userExpPercentage =
          (userProfileRef.exp / getExpForNextLevel(userProfileRef.level)) * 100;

        addNotification({
          id: Date.now(),
          name: craftItem.name,
          imageUrl: craftItem.imageUrl,
          message: `${amountToCraft}`,
          expPercentage: userExpPercentage.toFixed(2),
        });

        setAccumulatedSkillExp((prevExp) => prevExp + craftingSkillExpGain);

        // Handle item level up if needed
        const inventoryItem = userInventory.find(
          (invItem) => invItem.itemId === itemId
        );
        if (inventoryItem) {
          levelUp(inventoryItem);
        }
      } catch (error) {
        console.error("Error crafting item:", error);
      }
    },
    [
      userProfileRef,
      setUserState,
      fetchUserProfile,
      craftItems,
      userInventoryRef,
      setCraftBatch,
      addNotification,
      accumulatedSkillExp,
      setAccumulatedSkillExp,
      itemLevels,
    ]
  );
